.refund {
  .upload-text {
    font-size: 20px;
    line-height: 25px;
    font-family: $asap-font-regular;
    font-weight: bold;
    margin-bottom: 30px;
  }
  .label-text {
    font-size: 18px;
    line-height: 20px;
    font-family: $asap-font-regular;
    margin-bottom: 5px;
    color: #333;
  }
  .btn-section-refund {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;

    .darkbtn,
    .lightbtn {
      text-transform: capitalize;
    }
  }
  .dropdown {
    margin-bottom: 20px;
  }
}

@media (min-width: 1900px) {
  .refund {
    .upload-text {
      font-size: 1.4vw;
      line-height: 1.58vw;
      margin-bottom: 2vw;
    }
    .label-text {
      font-size: 1vw;
      line-height: 1.5vw;
      margin-bottom: 0.5vw;
    }
    .btn-section-refund {
      margin-top: 2vw;
    }
    .dropdown {
      margin-bottom: 1.04vw;
    }
  }
}
