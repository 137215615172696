.products {
  // background-color: rgb(250, 249, 249);

  .product-container {
    // padding-top: 25px;
    margin-top: 20px;
    padding-bottom: 60px;
  }

  .search-input {
    width: 224px !important;
    margin-left: 6px !important;
    height: 42px;
  }

  .main-heading40 {
    margin-left: 6px !important;
  }

  .darkbtn,
  .lightbtn {
    font-weight: 500 !important;
    text-transform: initial !important;
    box-shadow: none !important;
  }

  .bulk-btn-section {
    margin-right: 6px;
  }

  .dropdown .select-field {
    background: url(../../assets/images/sellerSide/dropdown.webp) 92%/8% no-repeat;
    height: 42px;
  }

  .MuiOutlinedInput-input {
    padding: 12.5px 14px 8.5px 0px;
    font-family: $asap-font-regular;
  }

  .unpublished {
    background-color: #000;
    z-index: 1;
    color: #ffffff;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    position: absolute;
    top: 15px;
    right: 14px;
    padding: 5px;
    border-radius: 2px;
    font-family: $asap-font-regular;
    font-weight: normal;
    font-style: normal;
    font-size: 12px;
    line-height: 14px;
  }

  .img-container {
    position: relative;
  }

  .img-opacity {
    opacity: 0.5;
  }

  .empty-img {
    border-radius: 8px;
    border: 1px solid #d8d4cf;
    padding: 100px 0;
  }

  .chart-text {
    width: 200px;
    margin: auto;
    padding: 0;
  }

  .main-heading40 {
    margin-bottom: 32px;
  }

  .search-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 11px;
  }

  .css-96uuyl {
    margin-left: 0px;
  }

  .btn-part {
    display: flex;
  }

  .drop-down {
    width: 180px;

    .inventory-drop-down {
      width: 100% !important;
    }
  }

  .copy-icon {
    display: none;
  }

  .product-text {
    width: 140px;
  }
}

@media only screen and (max-width: 600px) {
  .products {
    .product-container {
      // margin-left: 5%;
      // margin-right: 5%;
    }

    .bulk-btn-section {
      margin-top: 16px;
    }

    .search-row .darkbtn {
      margin-top: 0px !important;
    }

    .main-heading40,
    .search-input {
      margin-left: 0px;
    }

    .drop-down {
      width: 224px;
    }

    .main-heading40,
    .search-input {
      margin-left: 0px !important;
    }

    .product-text {
      width: 95px;
    }

    .css-96uuyl {
      margin-bottom: 10px;
    }

    .btn-part {
      display: block;
    }

    .search-row {
      align-items: flex-end;
      display: block;

      .darkbtn {
        margin-top: 16px;
      }
    }
  }

  .products {
    .productscard {
      .hover-icon {
        .icon-row {
          .iconhover {
            .edit-icon {
              display: block;
            }
          }
        }
      }
    }
  }

  .sellerorder .order-row {
    display: block !important;
  }

  .selectbox {
    margin-top: 20px;
  }

  .sellerorder .sellerordercontainer {
    padding-right: 2% !important;
    padding-left: 4%;
  }
}

@media (min-width: 601px) and (max-width: 960px) {
  .products {

    .product-text {
      width: 112px;
    }
  }
}

@media (min-width: 800px) and (max-width: 1024px){
  .products{
    .product-container{
      .img-container{
        width: 33.3333%;
      }
    }
  }
}

@media (min-width: 2000px) and (max-width: 6000px) {
  .products {
    .main-heading40 {
      font-size: 1.5vw;
      line-height: 1.7;
    }

    .unpublished {
      font-size: 0.8vw;
      line-height: 1.2vw;
    }

    .search-input {
      height: 2.2vw !important;
      width: 11vw !important;
    }

    .dropdown .select-field {
      height: 2.2vw;
      width: 11vw !important;
    }

    .darkbtn {
      border-radius: 0.5vw !important;
      padding: 0.4vw 0.8vw !important;
      font-size: 0.8vw !important;
    }

    .product-text {
      padding-right: 0px;
      font-size: 1vw;
      width: 9vw;
    }

    .copy-icon,
    .delete-icon,
    .edit-icon {
      cursor: pointer;
      height: 1.4vw;
      width: 1.4vw;
      color: #2f2b23;
    }

    .product-price {
      font-size: 1vw;
      line-height: 1.5vw;
    }

    // .selectbox .select-field {
    //     border-radius: 0.4vw !important;
    //     font-size: 0.8vw;
    //     padding: 0.4vw 0.4vw 0.4vw 0;
    //     height: 2.2vw;
    //     background-size: 0.7vw;
    //     width: 12vw !important;
    // }
  }

  ul.MuiMenu-list li.MuiMenuItem-root {
    font-size: 0.8vw !important;
  }
}

@media (min-width: 600px) and (max-width: 800px){
  .products{
    .product-container{
      .welcominnercard{
        margin-left: 16px;
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .products {
    .search-row {
      width: 100%;
      flex-direction: column;
      gap: 15px;

      .search-box {
        width: 100%;
        .search-input {
          margin-left: 0 !important;
          width: 100% !important;
          .MuiInputBase-root{
            width: 100%;
          }
        }
      }

      .btn-part {
        flex-direction: column;
        gap: 15px;
        .drop-down{
          width: 100%;
          .select-field{
            background-size: 20px;
            background-position-y: center;
            background-position: 98%;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 420px) {
  .products{
    .product-container{
      .img-container {
        width: 100%;
        .productscard{
          .product-text-section{
            .product-price{
              padding-left: 0;
            }
          }
        }
      }
    }
  }
}