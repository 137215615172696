.theme-base-content {
  margin-top: 2rem;
  margin-bottom: 2.5rem;

  .welcomecard {
    margin-bottom: 36px !important;
    padding: 5.278vw 3.889vw;
    min-height: 0;

    .MuiGrid-root {
      .MuiGrid-root {
        padding-top: 0 !important;

        .welcome-new-text {
          font-family: 'Inter-Medium';
          font-size: 4.513vw;
          line-height: 1.4;
          color: #1f1f1f;
          margin: 0;
          font-weight: 500;
        }

        .welcome-para {
          font-weight: 400 !important;
          font-family: 'Inter-Regular' !important;
          font-size: 1.11vw;
          line-height: 1.45vw;
          padding-right: 17vw;
        }
      }
    }
  }

  .main-section {
    h6 {
      margin-bottom: 1.6rem;
    }

    .drag-container {
      .draggable-update-card {
        .upload-description-card {
          cursor: auto !important;
        }
      }
    }

    .single-img-upload {
      h4 {
        font-size: 24px;
        margin-bottom: 1.5rem;
      }

      .drag-container {
        margin-bottom: 2.5rem;
      }

    }

    .dropdown {
      .MuiBox-root {
        width: 700px;

        .select-field {
          background-color: #fff;
          background-position-y: center;

          .MuiSelect-select {
            min-height: 0;
          }
        }
      }
    }

    .trending-detail {
      margin-top: 47px;

      .search-engine-section {
        .input-title {
          margin-top: 0;

          .meta-info {
            .meta-des {
              .dropdown {
                width: 700px;
                .MuiBox-root {
                  width: 100%;

                  .select-field {
                    background-color: #fff;
                    background-position-y: center;

                    .MuiSelect-select {
                      min-height: 0;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .search-engine-section {
      .input-title {
        margin-top: 0;
      }
    }

    .normal-card {

      .meta-title,
      .meta-des,
      .meta-keywords {
        display: flex;
        margin-bottom: 2.125rem;

        p {
          width: 143px;
          font-family: "Asap";
          font-style: normal;
          font-weight: 500;
          font-size: 15.757px;
          line-height: 18px;
          color: #44423d;
        }

        .input-width,
        .input-textarea {
          width: 700px;
        }
      }
    }
  }

  .return-btn {
    margin-left: 2rem;
    height: 3.25rem;
    min-width: 8.5rem;
    border-radius: 3.25rem !important;
    font-size: 1.125rem !important;
    font-family: 'Inter-Medium' !important;
    font-style: normal !important;
    font-weight: 500 !important;
  }


}

.buttons {
  width: 100%;
  margin-top: 70px;

  .buttons-position {
    display: flex;
    justify-content: flex-end;
    margin-right: 130px;

    .lightbtn {
      margin-right: 30px !important;
    }
  }
}

@media screen and (min-width: 3000px) and (max-width: 6000px) {
  .theme-base-content {
    .main-section {
      .return-btn {
        border-radius: 6rem !important;
        font-size: 1.8rem !important;
        margin-bottom: 4rem;
        height: 4rem;
        min-width: 14.5rem;
      }

      .trending-detail {
        margin-top: 0;
      }

      .buttons {
        .buttons-position {

          .lightbtn,
          .bluebtn {
            font-size: 1.016vw !important;
            margin-right: 0.781vw !important;
            padding: 15px 24px !important;
            border-radius: 18px !important;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1930px) and (max-width: 3000px) {
  .theme-base-content {
    .main-section {
      .return-btn {
        height: clamp(3.25rem, 3.5vw, 5rem);
        min-width: clamp(8.5rem, 11.5vw, 18rem);
        border-radius: 3.25rem !important;
        font-size: clamp(1.125rem, 1.2vw, 1.8rem) !important;
      }
    }

    .buttons {
      .buttons-position {

        .lightbtn,
        .bluebtn {
          font-size: 1.016vw !important;
          margin-right: 0.781vw !important;
          padding: 15px 24px !important;
          border-radius: 18px !important;
        }
      }
    }
  }

  .theme-base-content {
    .main-section {
      .search-engine-section {
        .meta-des {
          .main-textarea {
            width: 55vw;

            .input-textarea {
              width: 100%;
            }
          }

          .dropdown {
            width: 55vw;

            .MuiBox-root {
              width: 100%;
            }
          }
        }
      }

      .normal-card {
        .meta-des {
          .main-textarea {
            width: 55vw;

            .input-textarea {
              width: 100%;
            }
          }

          .dropdown {
            width: 55vw;

            .MuiBox-root {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1930px) {
  .theme-base-content {
    .main-section {
      h6{
        font-size: 24px;
        margin-bottom: 1.5rem;
      }
      .normal-card {
        h4{
          font-size: 32px;
          margin-bottom: 1.9rem;
        }
        .meta-title,
        .meta-des,
        .meta-keywords {
          p {
            font-size: clamp(1.125rem, 1.2vw, 1.8rem);
            width: clamp(170px, 13vw, 315px);
          }
          .input-width{
            width: 55vw;
          }
        }
      }
    }
  }


}

@media screen and (min-width: 800px) and (max-width: 1100px) {
  .theme-base-content {
    .main-section {
      .normal-card {
        .meta-des {
          .main-textarea {
            width: 700px;

            .input-textarea {
              width: 100%;
            }
          }

          .dropdown {
            width: 700px;

            .MuiBox-root {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .theme-base-content {
    margin: 0 1.5rem;
    margin-bottom: 8vw;

    .main-section {
      .return-btn {
        margin-left: 0;
        width: 100%;
      }

      .padding-left {
        padding: 0;
      }

      .add-product {
        .search-input {
          width: 100% !important;
        }
      }

      .upload-single-image-com {
        margin-top: 8vw;
      }

      .trending-detail {
        .search-engine-section {
          .input-title {
            .meta-info {
              .meta-des {
                .dropdown {
                  .MuiBox-root {
                    min-width: 100%;
                  }
                }
              }
            }
          }
        }
      }

      .dropdown .MuiBox-root {
        width: 100%;

        .select-field {
          background-position-x: calc(100% - 20px);
        }
      }

      .normal-card {

        .meta-title,
        .meta-des,
        .meta-keywords {
          flex-direction: column;
          row-gap: 1.5rem;

          p {
            width: fit-content;
          }

          .input-width,
          .input-textarea {
            width: 100%;
          }
        }
      }
    }

    .buttons {
      margin-top: 50px;

      .buttons-position {
        display: flex;
        justify-content: flex-end;
        margin-right: 0;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .theme-base-content {
    margin: 0 1.5rem;
    margin-bottom: 8vw;

    .welcominnercard {
      margin-left: 0;
      margin-right: 0;
      padding: 10.6vw 4.8vw 5.6vw;
    }

    .main-section {
      .return-btn {
        margin-left: 0;
      }

      .dropdown .MuiBox-root {
        width: 100%;

        .select-field {
          background-position-x: calc(100% - 20px);
        }
      }

      .normal-card {

        .meta-title,
        .meta-des,
        .meta-keywords {
          flex-direction: column;
          row-gap: 1.5rem;

          p {
            width: fit-content;
          }

          .input-width,
          .input-textarea {
            width: 100%;
          }
        }
      }

      .product-list {
        .product-gallery {
          .img-container {
            .MuiButtonBase-root {
              top: 0;
              right: 0;
            }
          }
        }
      }

      .padding-left {
        padding: 0;
      }

      .add-product {
        .search-input {
          width: 100% !important;
        }
      }

      .img-container {
        .productscard {
          .product-text-section {
            justify-content: space-around;
            flex-direction: column;
            row-gap: 10px;

            .hover-icon {
              column-gap: 0;
            }

            div {
              p {
                width: fit-content;
                padding: 0;
              }
            }

            .icon-row {
              display: block;
            }
          }
        }
      }
    }

    .buttons {
      margin-top: 50px;

      .buttons-position {
        display: flex;
        justify-content: flex-end;
        margin-right: 0;
      }
    }
  }

  .theme-base-content {
    .welcomecard {
      margin-left: 0;
      margin-right: 0;
      padding: 10.6vw 4.8vw 5.6vw;
      border-radius: 16px;

      .MuiGrid-root {
        .MuiGrid-root {
          .welcome-new-text {
            font-size: 8.513vw;
            line-height: 9.777vw;
            margin-bottom: 3vw;
          }

          .welcome-para {
            font-size: 4.11vw;
            line-height: 5.45vw;
            padding-right: 0vw;
          }
        }
      }
    }
  }
}