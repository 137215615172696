.welcomeadmindialogs {
  text-align: center;
  z-index: 900000;

  .shop-img {
    margin-bottom: 16px;
    // height: 95px;
    // width: 91px;
  }
  .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    min-width: 360px;
    width: 450px !important;
  }
  .css-uhb5lp {
    min-width: 360px;
    width: 450px !important;
  }
  .darkbtn {
    padding: 8px 22px !important;
    font-size: 16px !important;
    // text-transform: capitalize !important;
    background-color: #14bbad !important;
  }
  .welcome-heading {
    font-family: $asap-font-regular;
    font-style: normal;
    font-size: 18px;
    font-weight: 600;
    line-height: 21px;
    color: #2f2b23;
    margin-bottom: 16px;
  }

  .heading-para {
    font-family: $asap-font-regular;
    font-style: normal;
    font-size: 16px;
    font-weight: normal;
    color: #757061;
    margin-bottom: 24px;
  }

  .btn-section {
    text-align: end;
  }
}

@media only screen and (min-width: 1921px) {
  .welcomeadmindialogs {
    .MuiPaper-root{
      min-width: 800px ;
      width: 1600px !important;
    }
    .shop-img {
      margin-bottom: 0.833vw;
      height: 6vw;
      width: 7.2vw;
      margin-top: 1.55vw;
    }
    .welcome-heading {
      font-size: 1.5vw;
      line-height: 1.66vw;
      margin-bottom: 0.866vw;
    }

    .heading-para {
      font-size: 0.937vw;
      line-height: 1.25vw;
      margin-bottom: 1.25vw;
    }
    .btn-section {
      margin-top: 3.35vw;
      margin-bottom: 3.35vw;
    }
    .darkbtn {
      min-width: 6.5vw !important;
      min-height: 3.2vw !important;
      text-align: center !important;
      font-size: 0.937vw !important;
      line-height: 1.04vw !important;
      border-radius: 0.56vw !important;
    }
    .lightbtn {
      min-width: 6.5vw !important;
      min-height: 3.2vw !important;
      text-align: center !important;
      font-size: 0.937vw !important;
      line-height: 1.04vw !important;
      border-radius: 0.56vw !important;
    }
    .disabledBtn {
      min-width: 6.5vw !important;
      min-height: 3.2vw !important;
      text-align: center !important;
      font-size: 0.937vw !important;
      line-height: 1.04vw !important;
      border-radius: 0.56vw !important;
    }
  }
}
