.seller-dashboard {
  margin-top: 2rem;
  background: #fff;

  .lock-img {
    width: 1.2em;
    height: 1.2em;
  }

  .complete-section {
    .darkbtn {
      background-color: #ffffff !important;
      border: 1px solid #000000 !important;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
      border-radius: 9.92416px !important;
      color: #000000 !important;
      font-weight: 600 !important;
      font-family: $asap-font-regular;
      padding: 8px 20px 8px 20px !important;
      letter-spacing: 0.5px !important;
      margin-bottom: 1vw !important;
    }
  }

  .your-store-new {
    .box-title {
      font-size: 18px;
      line-height: 24px;
      margin-top: 7px !important;
    }
  }

  .heading-row {
    justify-content: space-between;
    display: flex;
    margin-bottom: 16px;
    align-items: center;

    .main-heading40 {
      font-weight: 500;
      line-height: 34px;
      font-size: 27.5px;
      color: #2f2b23;
      margin-bottom: 0;
      text-decoration: underline;
      cursor: pointer;
      opacity: 1;
    }
  }

  a:hover,
  a:active,
  a:focus {
    text-decoration: none;
  }

  .graph-section {
    padding-bottom: 102px;
  }

  .store-img {
    width: 66px;
    height: 66px;
    border-radius: 50%;
    object-fit: cover;
  }

  .store-heading {
    font-family: $asap-font-regular;
    font-style: 'semi-bold';
    font-size: 22px;
    line-height: 25px;
    font-weight: 500;
    color: #2f2b23;
    margin-bottom: 8px;
  }

  .price {
    font-family: $asap-font-regular;
    font-style: 'semi-bold';
    font-size: 24px;
    line-height: 27px;
    font-weight: 500;
    color: #2f2b23;
    margin-bottom: 8px;
  }

  .account-text {
    font-family: $asap-font-regular;
    font-style: normal;
    font-size: 16px;
    line-height: 18px;
    font-weight: normal;
    color: #2f2b23;
    margin: 0px;
    margin-bottom: 20px !important;
    margin-top: 16px !important;
  }

  .grid-box {
    margin: 0px;
    min-height: 110px;
    border: 1px solid #d8d4cf;
    margin-left: -1px;
    text-align: center;
  }

  .store-row {
    margin-bottom: 22px;
  }

  .summery-section {
    .summery-eading-new {
      margin-bottom: 22px;
    }

    .price {
      margin-bottom: 8px;
    }

    .store-row .MuiGrid-root {
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
  }

  .store-row .MuiGrid-root {
    // display: inline-flex;
    // align-items: center;
    // justify-content: center;
    margin-top: 0px !important;
    width: calc(100% + 0px) !important;
    margin-left: 0 !important;
    padding: 0px 0px !important;
  }

  .step-one-text {
    text-align: left;
    font-size: 1rem;
    line-height: 1.2rem;
    padding-left: 0.5rem;
    margin-bottom: 0.2rem;
    font-family: $asap-font-regular;
    font-weight: 400;
  }

  .heading-row {
    justify-content: space-between;
    display: flex;
    margin-bottom: 16px;
    align-items: center;

    .main-heading40 {
      font-weight: 500;
      line-height: 34px;
      font-size: 27.5px;
      color: #2f2b23;
      margin-bottom: 0;
      text-decoration: underline;
      cursor: pointer;
      opacity: 1;
    }
  }

  a:hover,
  a:active,
  a:focus {
    text-decoration: none;
  }

  .graph-section {
    padding-bottom: 102px;
  }

  .store-img {
    width: 66px;
    height: 66px;
    border-radius: 50%;
    object-fit: cover;
  }

  .store-heading {
    font-family: $asap-font-regular;
    font-style: 'semi-bold';
    font-size: 22px;
    line-height: 25px;
    font-weight: 500;
    color: #2f2b23;
    margin-bottom: 8px;
  }

  .price {
    font-family: $asap-font-regular;
    font-style: 'semi-bold';
    font-size: 24px;
    line-height: 27px;
    font-weight: 500;
    color: #2f2b23;
    margin-bottom: 8px;
  }

  .account-text {
    font-family: $asap-font-regular;
    font-style: normal;
    font-size: 16px;
    line-height: 18px;
    font-weight: normal;
    color: #2f2b23;
    margin: 0px;
    margin-bottom: 20px !important;
    margin-top: 16px !important;
  }

  .grid-box {
    margin: 0px;
    min-height: 110px;
    border: 1px solid #d8d4cf;
    margin-left: -1px;
    text-align: center;
  }

  .store-row {
    margin-bottom: 22px;
  }

  .summery-section {
    .store-heading {
      margin-bottom: 22px;
    }
  }

  .store-row .MuiGrid-root {
    // display: inline-flex;
    align-items: center;
    justify-content: center;
  }
}

.step-one-text {
  text-align: left;
  font-size: 1rem;
  line-height: 1.2rem;
  padding-left: 0.5rem;
  margin-bottom: 0.2rem;
  font-family: $asap-font-regular;
  font-weight: 400;
  margin-top: 0.2vw;
}

.completed-icon {
  background: #949d8c;
  border-radius: 50%;
  padding: 8px;
  height: 34.5px;
  width: 36px;
  margin-left: -8px;
  margin-bottom: 1vw;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
}

.box-title {
  font-family: $asap-font-regular;
  font-style: normal;
  font-size: 16px;
  line-height: 18px;
  font-weight: normal;
  color: #2f2b23;
  margin: 0px;
  margin-bottom: 10px !important;
  margin-top: 4px !important;
  // text-decoration: underline;
}

.textunderline {
  text-decoration: underline;
}

.grid-box:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.grid-box:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.grid-box-n {
  border: 1px solid #d8d4cf;
  padding: 15px;
  border-radius: 8px;
  min-height: 167px;

  .marginbott {
    font-weight: 600;
  }

  .text-view-repat {
    .sidepadding {
      font-weight: 600;
    }
  }
}

.text-view-repat {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;

  .text-view {
    column-gap: 10px;

    .box-title {
      margin: 0 !important;
    }
  }

  .store-heading {
    margin: 0 !important;
  }
}

.graph-n {
  min-height: auto;

  .apexcharts-bar-area {
    fill: #ec8c61;
  }
}

.graph-n-page-visit {
  min-height: auto;

  .apexcharts-bar-area {
    fill: #14bbad;
  }
}

.graph-bar {
  min-height: 100%;
}

.text-view {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .box-title {
    font-family: $asap-font-regular;
    font-style: normal;
    font-size: 19px;
    line-height: 22px;
    font-weight: normal;
    color: #2f2b23;
    margin: 0px;
    margin-bottom: 10px !important;
    margin-top: 4px !important;
    // text-decoration: underline;
  }

  .textunderline {
    text-decoration: underline;
  }

  .grid-box:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  .grid-box:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .grid-box-n {
    border: 1px solid #d8d4cf;
    padding: 15px;
    border-radius: 8px;
    // min-height: 167px;
    min-height: 15vw;
  }

  .graph-n {
    min-height: auto;

    .apexcharts-bar-area {
      fill: #ec8c61;
    }
  }

  .graph-n-page-visit {
    min-height: auto;

    .apexcharts-bar-area {
      fill: #14bbad;
    }
  }

  .graph-bar {
    min-height: 100%;
  }

  .text-view {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .box-title {
      font-family: $asap-font-regular;
      font-style: normal;
      font-size: 19px;
      line-height: 22px;
      font-weight: normal;
      color: #2f2b23;
      margin: 0px;
      margin-bottom: 10px !important;
      margin-top: 4px !important;
      // text-decoration: underline;
    }

    .textunderline {
      text-decoration: underline;
    }
  }

  .text-view-repat {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
    border-bottom: 1px solid rgb(216, 212, 207);

    .box-title {
      margin-left: 8px !important;
    }

    .store-heading {
      margin-bottom: 0px;
    }
  }

  .outofstock-text {
    border-bottom: none;
  }

  .sidepadding {
    padding-right: 9px;
  }

  .marginbott {
    margin-bottom: 21px;
  }

  .grid-box-bank {
    // background-color: #e8e5e3;
    background-color: #f8fafd;
    // border: 1px solid #d8d4cf;
    padding: 15px;
    border-radius: 8px;
    min-height: 15vw;

    .darkbtn {
      text-transform: inherit !important;
      font-weight: 500 !important;
      background-color: #a6a194 !important;
    }
  }

  .bank-heading {
    font-weight: normal;
    font-family: $asap-font-regular;
    font-style: italic;
    font-size: 27px;
    line-height: 31px;
    color: #2f2b23;
    margin-bottom: 8px;
  }

  .bankpara {
    font-weight: normal;
    font-family: $asap-font-regular;
    font-style: normal;
    font-size: 19.85px;
    color: #757061;
    margin-bottom: 25px;
  }

  .firstgraph-color,
  .secondgraph-color {
    margin-bottom: 15px !important;
  }

  .text-view-repat {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
    border-bottom: 1px solid rgb(216, 212, 207);

    .box-title {
      margin-left: 8px !important;
    }

    .store-heading {
      margin-bottom: 0px;
    }
  }

  .outofstock-text {
    border-bottom: none;
  }

  .sidepadding {
    padding-right: 9px;
  }

  .marginbott {
    margin-bottom: 21px;
  }

  .grid-box-bank {
    // background-color: #e8e5e3;
    background-color: #f8fafd;
    // border: 1px solid #d8d4cf;
    padding: 15px;
    border-radius: 8px;

    .darkbtn {
      text-transform: inherit !important;
      font-weight: 500 !important;
      background-color: #a6a194 !important;
    }
  }

  .text-view-repat {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // padding: 12px 0;
    padding: 18px 0;
    border-bottom: 1px solid rgb(216, 212, 207);
  }
}

.seller-dashboard {
  .grid-box-bank {
    background-color: #f8fafd;
    padding: 29px 19px;
    border-radius: 10px;

    .bank-heading {
      font-weight: normal;
      font-family: $asap-font-normal;
      font-style: italic;
      font-size: 27px;
      line-height: 31px;
      color: #2f2b23;
      margin-bottom: 8px;
    }

    .bankpara {
      font-size: 1.1vw;
      line-height: 1.5vw;
      margin-bottom: 0.6vw;
      color: #757061;
    }

    .darkbtn {
      text-transform: inherit !important;
      font-weight: 500 !important;
      background-color: #a6a194 !important;
    }
  }
}

@media (min-width: 280px) and (max-width: 600px) {
  .seller-dashboard {
    margin-top: 0.5rem;

    .complete-section .darkbtn {
      margin-bottom: 3vw !important;
      letter-spacing: 0 !important;
    }

    .chart-text {
      padding: 0 !important;
    }

    .grid-box:first-child,
    .grid-box:last-child {
      border-radius: 0 !important;
    }
  }
}

@media (min-width: 768px) and (max-width: 919px) {
  .seller-dashboard {

    .grid-box:first-child,
    .grid-box:last-child {
      border-radius: 0 !important;
    }
  }
}

@media (min-width: 920px) and (max-width: 1023px) {
  .seller-dashboard {
    .all-container-27inch {
      padding-left: 0px !important;
    }
  }
}

@media (min-width: 1500px) and (max-width: 6000px) {
  .seller-dashboard .grid-box:first-child {
    border-top-left-radius: 0.55vw;
    border-bottom-left-radius: 0.55vw;
  }

  .seller-dashboard .grid-box-bank .darkbtn {
    font-size: 1.2vw !important;
    line-height: 1.5vw !important;
  }
}

@media (min-width: 2000px) and (max-width: 6000px) {
  .seller-dashboard {
    .main-heading40 {
      font-size: 1.5vw;
      line-height: 1.7;
    }

    .step-one-text {
      margin-top: 0.2vw;
    }

    .grid-box-n,
    .grid-box-bank {
      padding: 0.8vw;
      min-height: 9vw;
    }

    .graph-n {
      // min-height: 22vw;
    }

    .store-img {
      width: 4vw;
      height: 4vw;
    }

    .store-heading {
      font-size: 1.1vw;
      line-height: 1.5vw;
      margin-bottom: 0.6vw;
    }

    .bankpara {
      font-size: 1.1vw;
      line-height: 1.5vw;
      margin-bottom: 0.6vw;
    }

    .bankdetailsimg {
      width: 100;
    }

    @media (min-width: 280px) and (max-width: 600px) {
      .seller-dashboard {
        margin-top: 0.5rem;

        .chart-text {
          padding: 0 !important;
        }

        .grid-box:first-child {
          border-radius: 0 !important;
          border-top-left-radius: 8px !important;
          border-top-right-radius: 8px !important;
        }

        .grid-box:last-child {
          border-radius: 0 !important;
          border-bottom-left-radius: 8px !important;
          border-bottom-right-radius: 8px !important;
        }
      }

      .box-title {
        font-size: 1vw;
        line-height: 1.2vw;
        margin-bottom: 1.2vw !important;
        margin-top: 0.5vw !important;
      }
    }

    @media (min-width: 920px) and (max-width: 1023px) {
      .seller-dashboard {
        .welcominnercard {
          margin-left: 20px;
        }

        .all-container-27inch {
          // padding-left:0px !important;
        }
      }

      .completed-icon {
        padding: 0.4vw;
        height: 2vw;
        width: 2.1vw;
      }

      .dashboardchart .k-card text {
        fill: #99abb4;
        font-size: 0.75vw !important;
      }
    }
  }
}

@media (max-width: 600px) {
  .seller-dashboard {
    .grid-box-bank {
      .bankpara {
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
}


@media (min-width: 4000px) and (max-width: 6000px) {
  .seller-dashboard {
    .dashboardchart .k-card text {
      font-size: 0.55vw !important;
    }
  }
}

@media (min-width: 1930px) {
  .completed-icon {
    margin: 0.518vw 0;
  }
}