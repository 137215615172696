.updatepassword {
  .uploadpara {
    font-family: $asap-font-regular;
    font-weight: normal;
    font-style: normal;
    font-size: 16px;
    line-height: 130%;
    margin: 0px 0px 24px 0px;
    color: #2f2b23;
  }
  .tracking-text {
    font-family: $asap-font-regular;
    font-style: normal;
    font-size: 16px;
    margin-top: 16px;
    font-weight: normal;
    color: #2f2b23;
    // margin-bottom: 4px;
  }
  .btn-section {
    text-align: end;
    margin-top: 24px;
  }
  .visibility-icon {
    position: absolute;
    right: 10%;
    padding-top: 13px;
  }
  input.input-field-outline {
    position: relative;
    padding-right: 45px;
  }
}

@media (min-width: 280px) and (max-width: 600px) {
  .updatepassword {
  }
}

@media (min-width: 2000px) and (max-width: 6000px) {
  .updatepassword {
    .sub-heading {
      font-size: 1vw;
      line-height: 1.2vw;
    }
    .visibility-icon {
      right: 8%;
      padding-top: 0.8vw;
    }
    .tracking-text,
    .uploadpara {
      font-size: 0.8vw;
      margin-top: 0.8vw;
      line-height: 1.2vw;
    }
    .lightbtn,
    .darkbtn {
      border-radius: 0.5vw !important;
      padding: 0.4vw 0.8vw !important;
      font-size: 0.8vw !important;
    }
  }
}
