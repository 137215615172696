.productimageskeleton {
  .maintitle {
    width: 35%;
    height: 5vw;
  }
  .sectiontitle {
    width: 70%;
  }
  .search {
    width: 30%;
    height: 3vw;
    border-radius: 0.5vw;
  }
  .btnsection {
    width: 35%;
    display: inherit;
    margin-bottom: 20px;
  }
  .btn {
    width: 48%;
    margin: 1%;
    height: 3vw;
    border-radius: 0.5vw;
  }
  .searchbtnsection {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
  }
  .boxsection {
    width: 100%;
  }
  .productimgbox {
    display: inline-block;
    width: 24%;
    margin: 0.5%;
  }
  .gboxes {
    height: 12vw;
    border-radius: 0.5vw;
  }
  .producttname {
    width: 70%;
  }
  .pronameicon {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 5px;
    margin-bottom: 2vw;
  }
  .copyicon {
    width: 2vw;
    height: 2vw;
  }
}
