.category-card {
  display: flex;
  background: #f8fafd;
  border-radius: 25px;
  width: 100%;
  height: 65px;
  align-items: center;
  padding: 0 43px;
  justify-content: space-between;
  margin-bottom: 0.764vw;

  p {
    font-family: 'Inter-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    color: #1f1f1f;
  }

  .icon-btn {
    display: flex;
    align-items: center;
    padding-right: 129px;
    column-gap: 18px;

    .add-btn,
    .circle-btn,
    .edit {
      width: 30px;
      height: 30px;
    }

    .add-btn {
      border-radius: 5px;

      img {
        width: 13px;
        height: 13px;
      }
    }

    .circle-btn {
      svg {
        width: 11px;
        height: auto;
      }

      .editIcon {
        color: #1f1f1f;
        width: 20px;
      }

      .trashIcon {
        width: 20px;
      }
    }

    .edit {
      svg {
        width: 14px;
        height: auto;
      }
    }
  }
}

@media screen and (min-width: 2000px) and (max-width: 6000px) {
  .category-card {
    height: 95px;

    .icon-btn {
      column-gap: 25px;

      .add-btn {
        border-radius: 15px;
      }

      .add-btn,
      .circle-btn,
      .edit {
        width: 55px;
        height: 55px;

        img {
          width: 25px;
          height: auto;
        }
      }
    }
  }
}

@media screen and (min-width: 1930px) and (max-width: 3000px) {
  .category-card {
    margin-bottom: 18px;
    height: 95px;
    .icon-btn {
      column-gap: 28px;

      .add-btn,
      .circle-btn,
      .edit {
        width: 55px;
        height: 55px;
      }

      .add-btn {
        border-radius: 15px;
      }

      .add-btn {
        img {
          width: 20px;
          height: 20px;
        }
      }

      .edit {
        img {
          width: 20px;
          height: auto;
        }
      }

      .circle-btn {
        img {
          width: 20px;
          height: auto;
        }
      }
    }
  }
}

@media screen and (min-width: 1930px) {
  .category-card .icon-btn{
    padding-right: 0;
  }
  .category-card p{
    font-size: 24px;
  }
  .categories-header {
    .main-section {
      .menu-list {
        .icon-btn {
          .btn-width {
            .bluebtn {
              font-size: 0.725vw !important;
            }
          }

          .circle-btn {
            svg {
              width: 1.2vw !important;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 800px) and (max-width: 1300px) {
  .category-card {
    .icon-btn {
      padding-right: 0;
    }
  }
}

@media screen and (min-width: 800px) and (max-width: 1200px) {
  .categories-header {
    .main-section {
      .categorybtn {
        .save-create {
          .AddTo {
            .bluebtn {
              width: 100%;
            }
          }
        }
      }
    }
  }

  .category-card {
    padding: 20px;
    margin-bottom: 11px;
    display: flex;
    flex-direction: column;
    height: auto;

    p {
      margin-bottom: 10px;
    }

    .icon-btn {
      padding-right: 0;
      gap: 18px;
      flex-direction: column;
    }

    .first-options,
    .second-options {
      display: flex;
      flex-wrap: wrap;
    }
  }
}

@media screen and (min-width: 600px) and (max-width: 799px) {
  .categories-header {
    .main-section {
      .categorybtn {
        .save-create {
          .AddTo {
            .bluebtn {
              width: 100%;
            }
          }
        }
      }
    }
  }

  .category-card {
    padding: 20px;
    margin-bottom: 11px;
    display: flex;
    flex-direction: column;
    height: auto;

    p {
      margin-bottom: 10px;
    }

    .icon-btn {
      padding-right: 0;
      gap: 18px;
      flex-direction: column;
    }

    .first-options,
    .second-options {
      display: flex;
      flex-wrap: wrap;
    }
  }
}

@media screen and (max-width: 600px) {
  .category-card {
    padding: 20px;
    margin-bottom: 11px;
    display: flex;
    flex-direction: column;
    height: auto;

    p {
      margin-bottom: 10px;
    }

    .icon-btn {
      padding-right: 0;
      gap: 18px;
      flex-direction: column;
    }

    .mobile {
      .second-options {
        .btn-width {
          .bluebtn {
            width: 154px;
          }
        }
      }
    }

    .first-options,
    .second-options {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      justify-content: center;
    }
  }
}

@media screen and (max-width: 400px) {
  .category-card {
    .mobile {
      width: 100%;

      .second-options {
        display: flex;
        flex-direction: column;
        width: 100%;

        .btn-width {
          .bluebtn {
            width: 100%;
            margin-right: 0;
          }
        }
      }
    }
  }
}