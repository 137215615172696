.thanksforregistering {
  .thanks-para {
    background-color: #fff !important;
    padding: 200px 0px;
    text-align: center;
    font-family: 'Poppins', sans-serif;
  }
  .para1 {
    font-size: 30px;
    color: #666;
    margin: 0px;
    margin-top: 90px;
  }
  .para2 {
    font-size: 30px;
    color: #666;
    margin: 0px;
    margin-bottom: 100px;
  }
}

@media (min-width: 320px) and (max-width: 600px) {
  .thanksforregistering {
    .para1 {
      font-size: 21px;
    }
    .para2 {
      font-size: 21px;
    }
  }
}

@media (min-width: 600px) and (max-width: 920px) {
  .thanksforregistering {
    .para1 {
      font-size: 25px;
    }
    .para2 {
      font-size: 25px;
    }
  }
}

@media (min-width: 920px) and (max-width: 1280px) {
  .thanksforregistering {
    .para1 {
      font-size: 28px;
    }
    .para2 {
      font-size: 28px;
    }
  }
}
@media (min-width: 1921px) and (max-width: 6000px) {
  .thanksforregistering {
    .para1 {
      font-size: 1.822916vw;
    }
    .para2 {
      font-size: 1.822916vw;
    }
  }
}
