.main-table {
  background-color: #f8fafd !important;
  box-shadow: none !important;
  table {
    thead {
      tr {
        th {
          border-bottom: none;
          font-family: 'Inter-Medium';
          font-style: normal;
          font-weight: 500;
          font-size: 15.3499px;
          color: #2f2b23;
          padding-top: 16px;
          padding-bottom: 12px;
        }
      }
    }
    tbody {
      tr {
        td {
          font-family: $asap-font-regular;
          font-style: normal;
          font-weight: 400;
          font-size: 17.5428px;
          color: #2f2b23;
          padding: 11px 16px;
        }
      }
    }
  }
  .img-card,
  .table-img {
    width: 55px;
    height: 55px;
    overflow: hidden;
    object-fit: cover;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .img-card {
    background: #b0b2b0;
    border-radius: 4.4197px;
  }
}
.radiobtn {
  .MuiFormGroup-root {
    label {
      padding-bottom: 7px;
      padding-left: 10px;
      span {
        color: #949d8c;
        padding: 0px;
      }
    }
    .MuiTypography-root {
      font-family: $asap-font-regular;
      font-style: normal;
      font-weight: 400;
      font-size: 17.5428px;
      color: #2f2b23;
      padding-left: 4px;
    }
  }
}

@media screen and (min-width: 3000px) and (max-width: 6000px) {
  .main-table {
    table {
      thead {
        tr {
          th {
            font-size: clamp(15px, 2vw, 26px);
          }
        }
      }
      tbody {
        tr {
          td {
            font-size: clamp(18px, 2vw, 28px);
          }
        }
      }
    }
    .img-card,
    .table-img {
      width: clamp(71px, 4vw, 95px);
      height: clamp(55px, 4vw, 79px);
    }
  }
  .radiobtn {
    .MuiFormGroup-root {
      .MuiTypography-root {
        font-size: clamp(18px, 2vw, 28px);
      }
    }
  }
}

@media screen and (min-width: 1930px) and (max-width: 3000px) {
  .main-table {
    table {
      thead {
        tr {
          th {
            font-size: clamp(15px, 2vw, 26px);
          }
        }
      }
      tbody {
        tr {
          td {
            font-size: clamp(18px, 2vw, 28px);
          }
        }
      }
    }
    .img-card,
    .table-img {
      width: clamp(71px, 4vw, 95px);
      height: clamp(55px, 4vw, 79px);
    }
  }
  .radiobtn {
    .MuiFormGroup-root {
      .MuiTypography-root {
        font-size: clamp(18px, 2vw, 28px);
      }
    }
  }
}
