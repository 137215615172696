.orderskeleton {
  table {
    width: 100%;
  }
  td {
    padding: 1vw;
  }
  .statustd,
  .orderandstatus,
  .acionbtntd {
    width: 15%;
  }
  .ordernumber {
    width: 22%;
  }
  .noofitem {
    width: 10%;
  }
  .sale {
    width: 15%;
  }
  .salen {
    height: 6vw;
  }
  .acionbtn {
    height: 2.5vw;
  }
  .acionbtntd {
    width: 8%;
  }
}
