.add-product {
  margin-top: 21px;
  p {
    font-family: $asap-font-regular;
    font-style: normal;
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 21px;
    color: #2f2b23;
    margin-bottom: 1rem;
    -webkit-font-weight: 28px;
  }
  .search-input {
    margin: 0;
    width: 31.5rem !important;
    .MuiInputBase-root {
      width: 100%;
    }
  }
}

@media screen and (max-width: 600px) {
  .add-product {
    .search-input {
      width: 100% !important;
    }
  }
}
