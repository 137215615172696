.draggable-card {
  background-color: #f8fafd;
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 25px;
  // cursor: grab;

  .drag-image-card {
    border-radius: 25px;
    overflow: hidden;
    width: 41.042vw; //necessary
    height: auto;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .drag-description-card {
    padding: 1.5rem;
    width: 41.042vw;

    h3 {
      font-size: 23px;
      line-height: 32px;
      margin-bottom: 12px;
      font-family: 'Inter-Medium';
      font-style: normal;
      font-weight: 500;
    }

    p {
      font-size: 15px;
      line-height: 24px;
      font-family: 'Inter-Medium';
      font-style: normal;
      font-weight: 500;
    }
  }
}

.draggable-arrow-card {
  background-color: #f8fafd;
  width: 121px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 25px;

  .all-btn {
    gap: 2.75rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .up-down-btn,
  .edit-del-btn {
    gap: 11px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (min-width: 3000px) and (max-width: 6000px) {
  .drag-container {
    column-gap: 40px;
    margin-bottom: 40px;

    .draggable-card {
      border-radius: 35px;

      .drag-image-card {
        border-radius: 35px;

        // p {
        //   font-size: 2rem;
        //   line-height: 3rem;
        // }
      }

      .drag-description-card {
        padding: 2.5rem;

        // h3 {
        //   font-size: 40px;
        //   line-height: 50px;
        //   margin-bottom: 20px;
        // }

        // p {
        //   font-size: 32px;
        //   line-height: 40px;
        //   margin-bottom: 59px;
        // }
      }
    }

    .upload-arrow-card {
      width: 121px;
      border-radius: 25px;
    }
  }
}

@media screen and (min-width: 1930px) {
  .draggable-card {
    border-radius: 25px;

    .drag-description-card {
      h3 {
        font-size: 38px;
        line-height: 45px;
        margin-bottom: 1.25rem;
      }

      p {
        font-size: 1.25rem;
        line-height: 2rem;
      }
    }
  }
}

@media screen and (min-width: 900px) and (max-width: 1200px) {
  .draggable-arrow-card {
    .all-btn {
      gap: 1rem;
    }
  }

  .draggable-card {
    .drag-image-card {
      height: 100%;

      img {
        object-fit: cover;
      }
    }
  }
}

@media screen and (min-width: 100px) and (max-width: 800px) {
  .draggable-card {
    .drag-description-card {
      width: 100%;
    }


  }
}

@media screen and (max-width: 800px) {
  .draggable-card {
    flex-direction: column;

    .drag-image-card {
      width: 100%;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .drag-description-card {
      h3 {
        font-size: 23px;
        line-height: 32px;
        margin-bottom: 12px;
        text-align: center;
      }

      p {
        font-size: 15px;
        line-height: 24px;
        text-align: center;
      }
    }
  }

  .draggable-arrow-card {
    width: 100%;
    flex-direction: row;
    padding: 1rem 0;

    .all-btn {
      flex-direction: row;
    }

    .up-down-btn,
    .edit-del-btn {
      flex-direction: row;
    }
  }
}

@media screen and (max-width: 600px) {
  .draggable-card {
    flex-direction: column;

    .drag-image-card {
      width: 100%;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .drag-description-card {
      h3 {
        font-size: 23px;
        line-height: 32px;
        margin-bottom: 12px;
        text-align: center;
      }

      p {
        font-size: 15px;
        line-height: 24px;
        text-align: center;
      }
    }
  }


  .draggable-arrow-card {
    width: 100%;
    flex-direction: row;
    padding: 1rem 0;

    .all-btn {
      flex-direction: row;
    }

    .up-down-btn,
    .edit-del-btn {
      flex-direction: row;
    }
  }
}