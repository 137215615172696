.main-badge-box {
    display: flex;
    gap: 25px;
    flex-wrap: wrap;
    margin-bottom: 2rem;
    margin-left: 3rem;

    .badges{
        position: relative;
        .shop-product-badges {
            width: 100%;
            height: 200px;
            position: relative;
            display: flex;
            flex-direction: column;
            gap: 10px;
            justify-content: center;
            border-radius: 10px;
            border: 1px solid;

            .badge-image {
                padding: 0 24px;
                width: 200px;
                height: 200px;
                margin: 0 auto;
                display: flex;
                align-items: center;
                img {
                    width: 50%;
                    height: auto;
                    margin: 0 auto;
                }
            }

            .text {
                text-align: center;
                padding: 5px 0;
                border-top: 1px solid;

                p {
                    font-size: 1rem;
                }
            }

            .edit-btn {
                position: absolute;
                top: 5px;
                right: 5px;
            }
        }
        span{
            position: absolute;
            top: 5px;
            left: 5px;
        }
    }
}

@media screen and (max-width: 600px) {
    .main-badge-box {
        .badges{
            width: 100%;
        }
    }
}