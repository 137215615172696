.addProductBadge {
    margin-top: 2rem;
    margin-bottom: 2.5rem;

    .welcomecard {
        margin-bottom: 36px !important;
        padding: 5.278vw 3.889vw;
        min-height: 0;

        .MuiGrid-root {
            .MuiGrid-root {
                padding-top: 0 !important;

                .welcome-new-text {
                    font-family: 'Inter-Medium';
                    font-size: 4.513vw;
                    line-height: 1.4;
                    color: #1f1f1f;
                    margin: 0;
                    font-weight: 500;
                }

                .welcome-para {
                    font-weight: 400 !important;
                    font-family: 'Inter-Regular' !important;
                    font-size: 1.11vw;
                    line-height: 1.45vw;
                    padding-right: 17vw;
                }
            }
        }
    }

    .main-section {
        .search-engine-section {
            .input-title {
                .meta-info {
                    .margin-top {
                        .drag-container {
                            .draggable-update-card {
                                .update-image-card {
                                    p {
                                        width: fit-content;
                                    }
                                }

                                .upload-description-card {
                                    p {
                                        font-size: 15px;
                                        line-height: 24px;
                                        font-family: "Inter-Regular";
                                        font-style: normal;
                                        font-weight: 500;
                                        color: #1f1f1f;
                                    }
                                }
                            }
                        }
                    }

                    .meta-keywords {
                        .status-dropdown {
                            width: 700px;
                        }
                    }
                }

                .qul-editor-testimonial {
                    width: 700px;
                }
            }
        }

        .upload-single-image-com {
            margin-top: 2vw;
        }

        .single-img-upload {
            h4 {
                font-size: 24px;
                margin-bottom: 1.5rem;
            }
        }

        .product-list {
            margin-right: 16px;

            h4 {
                font-size: 24px;
                margin-bottom: 1.5rem;
            }

            .product-gallery {
                .img-container {
                    position: relative;

                    .MuiButtonBase-root {
                        position: absolute;
                        top: 0;

                        .MuiSvgIcon-root {
                            color: #fff;
                        }
                    }
                }
            }
        }

        .single-upload {
            margin-top: 1.5rem;
        }

        .search-engine-section {
            .input-title {
                .meta-info {
                    .meta-des {
                        .drag-container {
                            .draggable-update-card {
                                .update-image-card {
                                    padding: 0 24px;
                                }
                            }
                        }
                    }

                    .meta-keywords {
                        .MuiBox-root {
                            width: 100%;
                            background: #fff;
                            margin-bottom: 1.5rem;
                        }

                        .drag-container {
                            width: 90%;

                            .upload-description-card {
                                p {
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .return-btn {
        margin-left: 2rem;
        height: 3.25rem;
        min-width: 8.5rem;
        border-radius: 3.25rem !important;
        font-size: 1.125rem !important;
        font-family: 'Inter-Medium' !important;
        font-style: normal !important;
        font-weight: 500 !important;
    }

    .trending-detail {
        margin-top: 47px;

        .search-engine-section {
            .input-title {
                .meta-info {
                    .meta-des {
                        .dropdown {
                            .MuiBox-root {
                                width: 100%;

                                .select-field {
                                    background-color: #fff;
                                    background-position-y: center;

                                    .MuiSelect-select {
                                        min-height: 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .padding-left {
        padding-left: 2rem;
        padding-right: 10px;
    }

    .add-product {
        p {
            font-family: $asap-font-regular;
            font-style: normal;
            font-weight: 600;
            font-size: 1.125rem;
            line-height: 21px;
            color: #2f2b23;
            margin-bottom: 1rem;
        }

        .search-input {
            margin: 0;
            width: 31.5rem !important;

            .MuiInputBase-root {
                width: 100%;
            }
        }
    }
}

.buttons {
    width: 100%;
    margin-top: 70px;

    .buttons-position {
        display: flex;
        justify-content: flex-end;
        margin-right: 130px;

        .lightbtn {
            margin-right: 30px !important;
        }
    }
}


@media screen and (min-width: 3000px) and (max-width: 6000px) {
    .addProductBadge {
        .main-section {
            .search-engine-section {
                .input-title {
                    .meta-info {
                        .meta-keywords {
                            .status-dropdown {
                                width: 100%;

                                .MuiBox-root {
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
            }

            .return-btn {
                border-radius: 6rem !important;
                font-size: 1.8rem !important;
                margin-bottom: 4rem;
                height: 4rem;
                min-width: 14.5rem;
            }

            .add-product {
                p {
                    font-size: 35px;
                    margin-bottom: 35px;
                }
            }

            .trending-detail {
                margin-top: 0;
            }

            .buttons {
                .buttons-position {

                    .lightbtn,
                    .bluebtn {
                        font-size: 1.016vw !important;
                        margin-right: 0.781vw !important;
                        padding: 15px 24px !important;
                        border-radius: 18px !important;
                    }
                }
            }
        }
    }

    .addProductBadge {
        .main-section {
            .search-engine-section {
                .input-title {
                    .meta-info {
                        .meta-keywords {
                            .drag-container {
                                width: 100%;
                            }

                            .MuiBox-root {
                                .MuiFormControl-root {
                                    .MuiInputBase-root {
                                        .MuiSelect-select {
                                            font-size: 24px;
                                            line-height: 28px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .addProductBadge .main-section .search-engine-section .input-title .meta-info {
        .meta-keywords .MuiBox-root {
            width: 55vw;
            // margin: 1.6rem 0;

            .MuiInputBase-root {
                font-size: 1.04vw;

                svg {
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
        }

        .meta-des .drag-container .draggable-update-card .update-image-card p {
            line-height: 2.5rem;
        }
    }
}

@media screen and (min-width: 1930px) and (max-width: 3000px) {
    .addProductBadge .main-section .search-engine-section .input-title .meta-info .meta-keywords .drag-container {
        width: 100%;
    }

    .addProductBadge {
        .main-section {
            .return-btn {
                height: clamp(3.25rem, 3.5vw, 5rem);
                min-width: clamp(8.5rem, 11.5vw, 18rem);
                border-radius: 3.25rem !important;
                font-size: clamp(1.125rem, 1.2vw, 1.8rem) !important;
            }

            .search-engine-section {
                .input-title {
                    .meta-info {
                        .meta-keywords {
                            .status-dropdown {
                                width: 55vw;

                                .MuiBox-root {
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
            }

            .add-product {
                p {
                    font-size: clamp(1.125rem, 1.2vw, 1.8rem);
                }

                .search-input {
                    width: clamp(502px, 34vw, 870px) !important;
                    height: clamp(38px, 3vw, 60px);
                }
            }

            .search-engine-section {
                .input-title {
                    .meta-info {
                        .meta-keywords {
                            .MuiBox-root {
                                .MuiFormControl-root {
                                    .MuiInputBase-root {
                                        .MuiSelect-select {
                                            font-size: 24px;
                                            line-height: 28px;
                                        }

                                        .MuiSvgIcon-root {
                                            top: 50%;
                                            transform: translateY(-50%);
                                        }
                                    }
                                }
                            }
                        }

                        .meta-des {
                            .drag-container {
                                .draggable-update-card {
                                    .update-image-card p {
                                        line-height: 2.5rem;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .buttons {
            .buttons-position {

                .lightbtn,
                .bluebtn {
                    font-size: 1.016vw !important;
                    margin-right: 0.781vw !important;
                    padding: 15px 24px !important;
                    border-radius: 18px !important;
                }
            }
        }
    }
}

@media screen and (min-width: 1930px) {
    .addProductBadge {
        .main-section {
            .search-engine-section {
                .input-title {
                    .qul-editor-testimonial {
                        width: 55vw;
                    }

                    .meta-info {
                        .meta-keywords {

                            .MuiBox-root {
                                width: 55vw;
                                min-width: 0;
                            }

                            .drag-container {
                                width: 55vw;

                                .draggable-update-card {
                                    .update-image-card {
                                        height: auto;
                                        padding: 20px 0;
                                    }
                                }
                            }
                        }

                        .margin-top {
                            .drag-container {
                                .draggable-update-card {
                                    .update-image-card {
                                        p {
                                            width: fit-content;
                                            font-size: 1.4rem;
                                            line-height: 2.2rem;
                                        }
                                    }

                                    .upload-description-card {
                                        h3 {
                                            font-size: 1.8rem;
                                            line-height: 1.9rem;
                                        }

                                        p {
                                            width: fit-content;
                                            line-height: 2rem;
                                            font-size: 1.4rem;
                                            margin-bottom: 0;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .drag-container .draggable-update-card .upload-description-card {
                h3 {
                    font-size: 1.8rem;
                    line-height: 1.9rem;
                }

                p {
                    width: fit-content;
                    line-height: 2rem;
                    font-size: 1.4rem;
                    margin-bottom: 0;
                }
            }
        }
    }
}

@media screen and (min-width: 1024px) and (max-width: 1200px) {
    .addProductBadge {
        .main-section {
            .search-engine-section {
                .input-title {
                    .meta-info {
                        .meta-keywords .drag-container {
                            width: 80%;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    .addProductBadge {
        .main-section {
            .search-engine-section {
                .input-title {
                    .meta-info {
                        .margin-top {
                            display: flex;
                            flex-direction: column;

                            .drag-container {
                                margin-top: 1rem;
                                width: 100%;

                                .draggable-update-card {
                                    .update-image-card {
                                        height: 12rem;

                                        p {
                                            width: fit-content;
                                        }
                                    }

                                    .upload-description-card {
                                        p {
                                            margin-bottom: 0;
                                        }
                                    }
                                }
                            }
                        }
                        .meta-keywords {
                            .status-dropdown {
                                margin-top: 1rem;
                                width: 100%;
                                .MuiBox-root {
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 800px) and (max-width: 921px){
.addProductBadge{
    .welcomecard{
        margin-left: 16px;
    }
    .main-section {
        .search-engine-section{
            margin-left: 16px;
        }
    }
}
}

@media screen and (min-width: 800px) and (max-width: 1024px) {
    .addProductBadge .main-section .search-engine-section .input-title .meta-info .meta-keywords .MuiBox-root {
        width: 50vw;
        min-width: 0;

        .MuiFormControl-root {
            width: 100%;
        }
    }

    .addProductBadge .main-section .search-engine-section .input-title .qul-editor-testimonial {
        width: 50vw;
    }
}

@media screen and (max-width: 800px) {

    .addProductBadge .main-section .search-engine-section .input-title .meta-info .meta-keywords .MuiBox-root {
        width: 100%;
        min-width: 0;

        .MuiFormControl-root {
            width: 100%;
        }
    }

    .addProductBadge .main-section .search-engine-section .input-title .meta-info .meta-keywords .drag-container {
        width: 100%;
    }

    .addProductBadge {
        margin: 0 1.5rem;
        margin-bottom: 8vw;

        .welcominnercard {
            margin-left: 0;
            margin-right: 0;
        }

        .main-section {
            .return-btn {
                margin-left: 0;
                width: 100%;
            }

            .padding-left {
                padding: 0;
            }

            .add-product {
                .search-input {
                    width: 100% !important;
                }
            }

            .upload-single-image-com {
                margin-top: 8vw;
            }

            .trending-detail {
                .search-engine-section {
                    .input-title {
                        .meta-info {
                            .meta-des {
                                .dropdown {
                                    .MuiBox-root {
                                        min-width: 100%;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .buttons {
            margin-top: 50px;

            .buttons-position {
                display: flex;
                justify-content: flex-end;
                margin-right: 0;
            }
        }
    }

    .addProductBadge .main-section .search-engine-section .input-title .qul-editor-testimonial {
        width: 100%;
    }
}

@media screen and (max-width: 600px) {



    .addProductBadge {
        margin: 0 1.5rem;
        margin-bottom: 8vw;

        .welcominnercard {
            margin-left: 0;
            margin-right: 0;
            padding: 10.6vw 4.8vw 5.6vw;
        }

        .main-section {
            .return-btn {
                margin-left: 0;
            }

            .product-list {
                .product-gallery {
                    .img-container {
                        .MuiButtonBase-root {
                            top: 0;
                            right: 0;
                        }
                    }
                }
            }

            .padding-left {
                padding: 0;
            }

            .add-product {
                .search-input {
                    width: 100% !important;
                }
            }

            .img-container {
                .productscard {
                    .product-text-section {
                        justify-content: space-around;
                        flex-direction: column;
                        row-gap: 10px;

                        .hover-icon {
                            column-gap: 0;
                        }

                        div {
                            p {
                                width: fit-content;
                                padding: 0;
                            }
                        }

                        .icon-row {
                            display: block;
                        }
                    }
                }
            }
        }

        .buttons {
            margin-top: 50px;

            .buttons-position {
                display: flex;
                justify-content: flex-end;
                margin-right: 0;
            }
        }
    }

    .addProductBadge {
        .welcomecard {
            margin-left: 0;
            margin-right: 0;
            padding: 10.6vw 4.8vw 5.6vw;
            border-radius: 16px;

            .MuiGrid-root {
                .MuiGrid-root {
                    .welcome-new-text {
                        font-size: 8.513vw;
                        line-height: 9.777vw;
                        margin-bottom: 3vw;
                    }

                    .welcome-para {
                        font-size: 4.11vw;
                        line-height: 5.45vw;
                        padding-right: 0vw;
                    }
                }
            }
        }
    }
}