.diffbgcolor {
  background-color: rgb(245, 245, 245);
}
.adminsidebar {
  // padding-top: 80px;
  .MuiSvgIcon-root {
    color: #000 !important;
  }
  .container-27-admin {
    padding-right: 0px;
  }
  .mobileviewset {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px;

    .logogoethical {
      width: 25%;
    }
  }
  .component-name {
    font-size: 13px;
    line-height: 15px;
  }
  .icon {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding-left: 21px;
    background-color: #ffffff;
  }

  .menu-icon {
    color: #2e2b24;
    font-size: 22px;
    height: 23px;
    width: 22px;
    margin-right: 13px;
    cursor: pointer;
  }

  .dvr-icon {
    background-repeat: no-repeat;
    display: inline-block;
    fill: currentColor;
    height: 24px;
    width: 24px;
    margin-right: 10px;
  }

  a:hover,
  a:active,
  a:focus {
    text-decoration: none;
  }

  .sidebar-menu-name {
    color: #2f2b23;
    font-size: 16px;
    font-family: $asap-font-regular;
    font-weight: normal;
    font-style: normal;
    margin-left: 0px;
    line-height: 130%;
  }

  .menu-name {
    margin-bottom: 0px !important;
    height: 42px;
  }

  .menu-name:hover {
    .icon-sidebar {
      color: #2f2b23 !important;
    }

    .sidebar-menu-name {
      color: #969c8d;
      font-size: 16px;
    }
  }

  .menu-name:hover .sidebar-menu-name {
    color: #969c8d;
    font-size: 16px;
  }

  .item2 {
    padding: 10px 10px 10px 10px;
    display: flex;
    border-radius: 8px;
    margin: 7px 0px;
    align-items: center;
    cursor: pointer;
  }

  .item2:focus {
    background-color: #e8e5e3;
    border-radius: 8px;
  }

  .item2:active {
    background-color: #e8e5e3;
    border-radius: 8px;
  }

  .item2:hover {
    background-color: #e8e5e3;
    border-radius: 8px;
  }

  .new-sidebar {
    height: 16px;
    width: 16px;
    margin-right: 16px;
  }
}

.MuiDrawer-root {
  .MuiListItemIcon-root {
    min-width: 40px;
  }

  img.new-sidebar {
    width: 18px;
  }

  .MuiListItemText-root span {
    color: #2f2b23;
    font-size: 16px;
  }
}

@media (min-width: 280px) and (max-width: 600px) {
  .adminsidebar {
    .icon {
      padding-left: 0;
    }

    .menu-icon {
      height: 30px;
      width: 35px;

      .MuiButtonBase-root {
        width: 100%;
        min-width: 100%;
        padding: 0;

        img {
          width: 100%;
        }
      }
    }

    .all-container-27inch.icon {
      padding: 0 16px !important;
    }
  }

  .mobileviewset {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 0 !important;
    margin-bottom: 14px;

    .logogoethical {
      width: 45% !important;
    }
  }
}

@media (min-width: 2000px) and (max-width: 6000px) {
  .adminsidebar {
    .sidebar-menu-name {
      font-size: 1vw;
      margin-left: 0vw;
      line-height: 1.2;
    }

    .mobileviewset {
      padding: 1vw 0;
    }

    .menu-icon,
    .dvr-icon {
      color: #2e2b24;
      font-size: 1.5vw;
      height: 2vw;
      width: 2vw;
      margin-right: 0vw;
    }

    .icon {
      //padding:0 3vw
    }

    .component-name {
      font-size: 1.2vw;
    }

    .new-sidebar {
      height: 1.2vw;
      width: 1.2vw;
      margin-right: 0.8vw;
    }

    .item2 {
      padding: 0.6vw 0.6vw 0.6vw 0vw;
      border-radius: 0.5vw;
      margin: 0.4vw 0.8vw;
    }
  }
}
