.normal-card {
  padding: 3.25rem 3rem 1.75rem;
  background: #f8fafd;
  border-radius: 24.6203px;
  margin-bottom: 3.563rem;
  margin-right: 16px;

  h4 {
    font-size: 24px;
    margin-bottom: 1.5rem;
  }

  .mobile-inputs,
  .tablet-inputs,
  .web-inputs {

    .meta-title,
    .meta-des,
    .meta-title {
      display: flex;
      margin-bottom: 2.125rem;

      p {
        width: 143px;
        font-family: $asap-font-regular;
        font-style: normal;
        font-weight: 500;
        font-size: 15.757px;
        line-height: 18px;
        color: #44423d;
      }

      .input-width {
        width: 700px;

        .input-field-outline {
          margin: 0;
        }
      }

      .input-textarea {
        width: 700px;
        margin: 0;
      }
    }
  }
}

.input-width {
  .input-field-outline {
    padding-bottom: 10px;
  }
}

.input-textarea {
  padding-bottom: 10px;
}

@media screen and (min-width: 600px) and (max-width: 1024px) {
  .normal-card {

    .mobile-inputs,
    .tablet-inputs,
    .web-inputs {

      .meta-title,
      .meta-des {

        .input-textarea,
        .input-width {
          width: 50vw;
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .normal-card {
    margin-right: 0;

    .mobile-inputs,
    .tablet-inputs,
    .web-inputs {

      .meta-title,
      .meta-des,
      .meta-title {
        display: flex;
        flex-direction: column;
        row-gap: 1.5rem;

        p {
          width: fit-content;
        }

        .input-width {
          width: 100%;
        }

        .input-textarea {
          width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .normal-card {
    margin-right: 0;

    .mobile-inputs,
    .tablet-inputs,
    .web-inputs {

      .meta-title,
      .meta-des,
      .meta-title {
        display: flex;
        flex-direction: column;
        row-gap: 1.5rem;

        p {
          width: fit-content;
        }

        .input-width {
          width: 100%;
        }

        .input-textarea {
          width: 100%;
        }
      }
    }
  }
}