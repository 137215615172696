.dashboardskeleton {
  .sectiontitle {
    width: 25%;
  }
  .boxsection {
    margin-bottom: 20px;
  }
  .boxes {
    display: inline-block;
    width: 24.5%;
    margin: 0.2%;
    min-height: 8vw;
  }
  .gboxes {
    display: inline-block;
    width: 49%;
    margin: 0.5%;
    min-height: 28vw;
    border-radius: 1vw;
  }
  .titleicon {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
  }
  .maintitle {
    width: 35%;
    height: 5vw;
  }
  .usericon {
    width: 7vw;
    height: 7vw;
  }
}
