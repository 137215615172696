.site-add-instagram-section {
  margin-top: 2rem;
  margin-bottom: 2.5rem;

  .welcominnercard {
    margin-bottom: 36px !important;
    padding: 5.278vw 3.889vw;
    min-height: 0;

    .MuiGrid-root {
      .MuiGrid-root {
        padding-top: 0 !important;

        .welcome-new-text {
          font-family: 'Inter-Medium';
          font-weight: 500;
        }

        .welcome-para {
          font-weight: 400 !important;
          font-family: 'Inter-Regular' !important;
        }
      }
    }
  }

  .main-section {
    margin-top: 6px;
    padding-left: 29px;

    .section-editor {
      display: flex;
      margin-bottom: 3.9375rem;
      align-items: end;
      column-gap: 4.688rem;

      .return-btn {
        height: 3.25rem;
        min-width: 8.5rem;
        border-radius: 3.25rem !important;
        font-size: 1.125rem !important;
        font-family: 'Inter-Medium' !important;
        font-style: normal !important;
        font-weight: 500 !important;
      }

      .input-part {
        p {
          font-family: $asap-font-regular;
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 21px;
          margin-bottom: 7px;
          color: #2f2b23;
        }

        .input-width {
          width: 31.5rem;

          .input-field-outline {
            height: 2.375rem;

            &::placeholder {
              color: #a6a0a0 !important;
            }
          }
        }
      }
    }

    .add-product {
      p {
        font-family: $asap-font-regular;
        font-style: normal;
        font-weight: 600;
        font-size: 1.125rem;
        line-height: 21px;
        color: #2f2b23;
        margin-bottom: 1rem;
      }

      .search-input {
        margin: 0;
        width: 31.5rem !important;

        .MuiInputBase-root {
          width: 100%;
        }
      }
    }
  }

  .buttons {
    width: 100%;
    margin-top: 70px;

    .buttons-position {
      display: flex;
      justify-content: flex-end;
      margin-right: 130px;

      .lightbtn {
        margin-right: 30px !important;
      }
    }
  }
}

.search-input {
  .icon-wrapper {
    padding: 0 10px;
  }

  .MuiInputBase-root {
    .MuiInputBase-input {
      padding-left: calc(1em + 24px);
    }
  }
}

@media screen and (min-width: 3000px) and (max-width: 6000px) {

  .site-add-instagram-section {
    padding-left: 60px;

    .main-section {
      .section-editor {
        .return-btn {
          border-radius: 6rem !important;
          font-size: 1.8rem !important;
          height: 4rem;
          min-width: 14.5rem;
        }

        .input-part {
          p {
            font-size: 2rem;
          }

          .input-width {
            width: 1000px;

            .input-field-outline {
              height: 70px;
            }
          }
        }
      }

      .add-product {
        p {
          font-size: 2rem;
        }

        .search-input {
          width: 900px !important;
          height: 85px;
        }
      }
    }

    .buttons {
      .buttons-position {

        .lightbtn,
        .bluebtn {
          font-size: 1.016vw !important;
          margin-right: 0.781vw !important;
          padding: 15px 24px !important;
          border-radius: 18px !important;
        }
      }
    }
  }
}

@media screen and (min-width: 1930px) and (max-width: 3000px) {

  .site-add-instagram-section {
    .main-section {
      .section-editor {
        .return-btn {
          height: clamp(3.25rem, 3.5vw, 5rem);
          min-width: clamp(8.5rem, 11.5vw, 18rem);
          border-radius: 3.25rem !important;
          font-size: clamp(1.125rem, 1.2vw, 1.8rem) !important;
        }

        .input-part {
          p {
            font-size: clamp(1.125rem, 1.2vw, 1.8rem);
          }

          .input-width {
            width: clamp(504px, 35vw, 1050px);

            .input-field-outline {
              height: clamp(38px, 3vw, 60px);
            }
          }
        }
      }

      .add-product {
        p {
          font-size: clamp(1.125rem, 1.2vw, 1.8rem);
        }

        .search-input {
          width: 900px !important;
          height: 85px;
        }
      }
    }

    .buttons {
      .buttons-position {

        .lightbtn,
        .bluebtn {
          font-size: 1.016vw !important;
          margin-right: 0.781vw !important;
          padding: 15px 24px !important;
          border-radius: 18px !important;
        }
      }
    }
  }
}

@media screen and (min-width: 1930px) {
  .site-add-instagram-section {
    .dropdown {
      width: 55vw;

      .MuiBox-root {
        width: 100% !important;
      }
    }
  }
}

@media screen and (max-width: 800px) {

  .site-add-instagram-section {
    margin: 0 1.5rem;
    margin-bottom: 8vw;

    .dropdown {
      width: 100%;
      .select-field {
        background-position: 95%;
      }
      .MuiBox-root {
        width: 100% !important;
      }

    }

    .welcominnercard {
      margin-left: 0;
      margin-right: 0;
      padding: 5.6vw 4.8vw 5.6vw;
    }

    .main-section {
      padding-left: 0;

      .add-product {
        .search-input {
          width: 100% !important;
        }
      }

      .section-editor {
        flex-direction: column;
        align-items: inherit;
        row-gap: 2rem;
        margin-left: 0;

        .input-part {
          .input-width {
            width: 100%;

            .input-field-outline {
              height: 2.375rem;
            }
          }
        }
      }
    }

    .buttons {
      width: 100%;
      margin-top: 40px;

      .buttons-position {
        margin-right: 0;
      }
    }
  }
}

@media screen and (max-width: 600px) {

  .site-add-instagram-section {
    margin: 0 1.5rem;
    margin-bottom: 8vw;

    .welcominnercard {
      margin-left: 0;
      margin-right: 0;
      padding: 9.347vw 4.889vw;
    }

    .main-section {
      padding-left: 0;

      .add-product {
        .search-input {
          width: 100% !important;
        }
      }

      .section-editor {
        flex-direction: column;
        align-items: inherit;
        row-gap: 2rem;
        margin-left: 0;

        .input-part {
          .input-width {
            width: 100%;

            .input-field-outline {
              height: 2.375rem;
            }
          }
        }
      }
    }

    .buttons {
      width: 100%;
      margin-top: 40px;

      .buttons-position {
        margin-right: 0;
      }
    }
  }
}