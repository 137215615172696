.product-gallery {
  .img-container {
    width: 20%;
    padding: 0;
    padding-right: 17px;
    margin-top: 1.6rem;
    .productscard {
      .product-text-section {
        .product-text {
          font-family: $asap-font-regular;
          font-style: normal;
          font-weight: 600;
          font-size: 1rem;
          color: #2f2b23;
        }
        .product-price,
        .copy-icon,
        .icon-row {
          display: none;
        }
      }
    }
  }
  .delete-icon {
    position: absolute;
    top: 4px;
    left: 4px;
    cursor: pointer;
  }
  .search{
    margin-bottom: 1.6vw;
  }
}

@media screen and (min-width: 3000px) and (max-width: 6000px) {
  .product-gallery {
    .img-container {
      padding-right: 30px;
      .productscard {
        .product-text-section {
          .product-text {
            font-size: 2.5rem;
          }
        }
      }
    }
    .delete-icon {
      top: 10px;
      left: 10px;
      width: 55px;
      height: 55px;
    }
  }
}

@media screen and (max-width: 600px) {
  .product-gallery {
    .img-container {
      width: 50%;
      padding: 5px 6px;
    }
    .delete-icon {
      top: 10px;
      left: 10px;
    }
  }
}
