.productimages {
  // background-color: rgb(250, 249, 249);

  .product-images-container {
    padding-top: 20px;
    padding-bottom: 60px;
  }
  .search-input {
    width: 232px !important;
    margin-left: 6px !important;
  }
  .seller-para,
  .main-heading40 {
    margin-left: 6px !important;
  }
  .css-1iz4iln {
    padding: 0px 16px 0 10px;
  }
  .darkbtn,
  .lightbtn {
    font-weight: 500 !important;
    text-transform: initial !important;
    box-shadow: none !important;
  }
  .darkbtn {
    padding: 7px 16px !important;
    min-width: 104px;
  }
  .css-1kcggdq-MuiInputBase-root .MuiInputBase-input {
    padding: 8px 8px 8px 0px;
    padding-left: calc(1em + 20px);
  }
  .css-96uuyl {
    margin-left: 0px;
  }

  .empty-img {
    border-radius: 8px;
    border: 1px solid #d8d4cf;
    padding: 100px 0;
  }

  .chart-text {
    width: 200px;
    margin: auto;
    padding: 0;
  }

  .checkbox-style {
    position: absolute;
    top: 12px;
    left: 12px;
    color: #fff;
    padding: 0 !important;
    svg {
      width: 26px;
      height: 26px;
    }
  }

  .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root:hover {
    // background-color: transparent;
    color: #fff;
  }

  .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked,
  .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
    color: #949d8c !important;
    background: #fff;
    border-radius: 50%;
    width: 1em;
    height: 1em;
    top: 0.9vw;
    left: 1.2vw;
  }

  .css-zun73v.Mui-checked,
  .css-zun73v.MuiCheckbox-indeterminate {
    color: #949d8c !important;
    background: #fff;
    border-radius: 50%;
    width: 1em;
    height: 1em;
  }

  .selected {
    height: 41px;
    text-align: center;
    border: 1px solid #d8d4cf;
    margin-right: 8px;
    width: 183px;
    border-radius: 8px;
    font-family: Asap;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 34px;
    color: #2f2b23;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .flexwidth {
    display: flex;
    margin-bottom: 25px;
    margin-left: 11px;
    // .darkbtn {
    //   background-color: #c4c9c0 !important;
    //   color: #fff !important;
    //   min-width: 75px;
    // }
    .lightbtn {
      min-width: 75px;
    }
  }

  .search-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 11px;
    margin-right: 5px;
  }

  .btn-part {
    display: flex;
  }

  .lightbtn {
    margin-right: 10px;
  }

  .delete-icon {
    position: absolute;
    top: 12px;
    left: 16px;
    cursor: pointer;
  }

  .product-price {
    display: none;
  }

  .productscard:hover .icon-row {
    display: none !important;
  }
  .img-container {
    padding: 5px 9px;
  }
}



@media only screen and (max-width: 600px) {
  .productimages {
    .search-row {
      display: block;
      margin-top: 4.6vw;
    }

    .img-container {
      width: 50%;
    }

    .product-images-container {
      padding-left: 10px;
      padding-right: 10px;
      margin-right: 0px;
      margin-left: 0;
    }

    .seller-para {
      padding-right: 0px;
    }

    .selected {
      width: 110px;
      margin-bottom: 10px;
    }

    .productscard .product-text {
      width: 25vw;
    }

    .btn-part {
      margin-top: 20px;
    }
  }
}

@media (min-width: 1180px) and (max-width: 1400px) {
  .productimages {
    .all-container-27inch {
      max-width: 96% !important;
    }
  }
}
@media (min-width: 2000px) and (max-width: 6000px) {
  .productimages {
    .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked,
    .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
      top: 0.8vw;
      left: 0.8vw;
    }

    .main-heading40 {
      font-size: 1.5vw;
      line-height: 1.7;
    }
    .chart-text {
      font-size: 1vw;
      line-height: 1.5vw;
      width: 15vw !important;
    }
    .search-input {
      width: 13.5vw !important;
    }
    .seller-para {
      font-size: 0.9vw;
      line-height: 1.2vw;
      margin-bottom: 1.6vw;
      padding-right: 12vw;
    }
    .selected {
      height: 2.2vw;
      width: 8vw;
      font-size: 0.8vw;
      line-height: 2.2vw;
    }
    .darkbtn,
    .lightbtn {
      border-radius: 0.5vw !important;
      padding: 0.4vw 0.8vw !important;
      font-size: 0.8vw !important;
    }

    .product-text {
      padding-right: 0px;
      font-size: 1vw;
      width: 9vw;
    }

    .copy-icon,
    .delete-icon,
    .edit-icon {
      cursor: pointer;
      height: 1.4vw;
      width: 1.4vw;
      color: #2f2b23;
    }
  }
}
