.storedetails {
  max-width: 454px;
  margin: auto;
  width: 50%;
  margin-bottom: 50px;
  padding-bottom: 112px;
  .drop-down {
    margin-bottom: 24px;
    border: 1px dashed #ccc;
    border-radius: 4px;
    height: 115px;
  }
  .file-label {
    text-align: center;
    // margin: 16px 0px 0px 0px;
  }
  .upload-img {
    height: 31px !important;
    width: 35px !important;
    margin-top: 15px;
  }
  .lightbtn {
    min-width: 96.5px !important;
  }
  .logo-text.marleft0 {
    margin-left: 0 !important;
  }
  .select-image-text {
    font-family: $asap-font-regular;
    font-weight: normal;
    font-style: normal;
    font-size: 16px;
    margin: 4px 55px 25px 81px;
    color: #2f2b23;
    text-align: center;
    padding: 0px 30px;
  }
  .btn-section {
    display: flex;
    justify-content: end;
  }

  .text-heading {
    text-align: center;
    font-size: 26px;
    line-height: 30px;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    font-family: $asap-font-regular;
    font-style: italic;
    margin-bottom: 12px;
    margin-top: 62px;
    // font-style: normal;
    font-weight: normal;
  }
  .file-label {
    img {
      height: 125px;
      width: 100%;
      border-radius: 10px;
      cursor: pointer;
      border-radius: 5px;
      object-fit: cover;
    }
  }
  .text-para {
    text-align: center;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    letter-spacing: 0px;
    color: #757061;
    opacity: 1;
    font-family: $asap-font-regular;
    margin-bottom: 24px;
  }

  .file-section {
    position: relative;
    height: 138px;
    width: 138px;
    margin-bottom: 20px;
  }

  .select-btn {
    background-color: transparent;
    border: none;

    img[src=''] {
      display: none;
    }
  }

  .store-img-row {
    border-radius: 50%;
    overflow: hidden;
    border: 1px dashed #a6a4a0;
    height: 138px;
    width: 138px;
    text-align: center;
    .upload-img {
      margin-top: 35px;
      margin-bottom: 4px;
      height: 35px !important;
    }
  }
  .input-textarea,
  .input-field-outline {
    border-radius: 4px;
    margin-bottom: 0px;
  }
  .store-img {
    height: 138px;
    width: 138px;
    border-radius: 50px;
    cursor: pointer;
    border: 1px solid #ccc;
    object-fit: cover;
  }

  .uplod-img {
    height: 32px;
    width: 32px;
    margin-top: 32px;
    margin-bottom: 13px;
  }

  .input-type-file-upload {
    display: none;
  }

  .logo-text {
    color: #2f2b23;
    font-size: 16px;
    letter-spacing: 0px;
    opacity: 1;
    font-family: $asap-font-regular;
    margin-left: 6px !important;
    margin: 0px;
    margin-bottom: 8px;
  }

  .upload-text {
    cursor: pointer;
    font-family: $asap-font-regular;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    -webkit-text-decoration-line: underline;
    text-decoration-line: underline;
    color: #2f2b23;
    .img {
      width: 100%;
      object-fit: cover;
      position: relative;
      display: block;
      height: 100%;
      min-height: 135px;
    }
  }
  .mrgbottom {
    margin-bottom: 20px;
    margin-bottom: 1rem;
    .tooltip-domain{
      display: flex;
      gap: 10px;
      align-items: center;
    }

  }
  .label-text {
    color: #2f2b23;
    font-size: 16px;
    letter-spacing: 0px;
    opacity: 1;
    font-family: $asap-font-regular;
    // margin-left: 6px;
    // margin-bottom: 4px;
  }
  .darkbtn,
  .lightbtn,
  .disabledBtn {
    font-size: 16px !important;
  }
  .btn-section {
    margin-top: 32px;
    justify-content: space-between;
    flex-direction: row;
    box-sizing: border-box;
    display: flex;
  }
}
@media only screen and (min-width: 200px) and (max-width: 800px) {
  .storedetails {

    .drop-down {
      height: auto;
    }
    .select-image-text {
      margin: 0px;
      margin-bottom: 1.5rem;
    }
  }
}
@media only screen and (min-width: 1921px) {
  .storedetails {
    width: 80%;
    max-width: 25.64vw;
    padding-top: 1vw;
    .mrgbottom {
      margin-bottom: 1.55vw;
    }
    .text-heading {
      font-size: 1.65vw;
      line-height: 1.66vw;
      margin-bottom: 0.866vw;
    }

    .text-para {
      font-size: 1.1vw;
      line-height: 1.25vw;
      margin-bottom: 1.25vw;
    }
    .label-text,
    .logo-text,
    .upload-text {
      font-size: 1vw;
      line-height: 1.5vw;
      margin-bottom: 0.5vw;
    }
    .btn-section {
      margin-top: 3.35vw;
      margin-bottom: 3.35vw;
    }
    .store-img-row {
      height: 8vw;
      width: 8vw;
      margin-bottom: 1.05vw;
    }
    .upload-text .img {
      height: 8vw;
    }
    .file-section {
      height: 8vw;
      width: 8vw;
    }
    .select-image-text {
      margin: 0.5vw auto;
      font-size: 1vw;
    }
    .drop-down {
      height: 6.8vw;
    }
    .uplod-img {
      height: 2vw;
      width: 2vw;
      margin-top: 2vw !important;
    }
    .store-img-row .upload-img {
      margin-top: 2vw;
      margin-bottom: 4px;
      height: 1.5vw !important;
      width: 1.5vw !important;
    }
    .upload-img {
      height: 1.5vw !important;
      width: 1.5vw !important;
      margin-top: 1vw;
    }
    .darkbtn {
      min-width: 6.5vw !important;
      min-height: 3.2vw !important;
      text-align: center !important;
      font-size: 0.937vw !important;
      line-height: 1.04vw !important;
      border-radius: 0.56vw !important;
    }
    .lightbtn {
      min-width: 6.5vw !important;
      min-height: 3.2vw !important;
      text-align: center !important;
      font-size: 0.937vw !important;
      line-height: 1.04vw !important;
      border-radius: 0.56vw !important;
    }
    .disabledBtn {
      min-width: 6.5vw !important;
      min-height: 3.2vw !important;
      text-align: center !important;
      font-size: 0.937vw !important;
      line-height: 1.04vw !important;
      border-radius: 0.56vw !important;
    }
  }
}

@media only screen and (min-width: 1930px){
  .storedetails{
    .btn-section .lightbtn{
      font-size: 0.937vw !important;
    }
  }
}