.actionbtn {
  display: flex;
  align-items: center;
  .edit {
    svg {
      color: #2f2b23;
    }
  }
  .add-btn {
    button {
      width: 1.4vw;
      height: 1.4vw;
      padding: 0;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
