.site-editor {
  margin-top: 2rem;
  margin-bottom: 2.5rem;

  .welcominnercard {
    margin-bottom: 36px !important;
    padding: 5.278vw 3.889vw;
    min-height: 0;

    .MuiGrid-root {
      .MuiGrid-root {
        padding-top: 0 !important;

        .welcome-new-text {
          font-family: 'Inter-Medium';
          font-weight: 500;
        }

        .welcome-para {
          font-weight: 400 !important;
          font-family: 'Inter-Regular' !important;
        }
      }
    }
  }

  .add-section {
    width: 100%;
    padding-left: 2.5rem;
    display: flex;

    .add-section-btn,
    .save-order-btn {
      height: 3.25rem;
      min-width: 8.5rem;
      border-radius: 3.25rem !important;
      font-size: 1.125rem !important;
      margin-bottom: 2.188rem;
      font-family: 'Inter-Medium';
      font-style: normal;
      font-weight: 500;
      padding: 16px 28px !important;
    }

    .save-order-btn {
      padding: 16px 32px !important;
    }
  }
}

.container-27inch{
  .drag-container{
    .draggable-card{
      cursor: grab;
    }
  }
}

.add-new-section {
  h4 {
    font-family: $asap-font-regular;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    color: #2f2b23;
    margin-bottom: 16px;
  }

  p {
    font-family: $asap-font-regular;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #2f2b23;
    margin-bottom: 16px;
  }

  img {
    width: 100%;
    height: auto;
  }

  .all-btn-link {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
  }
}

@media screen and (min-width: 3000px) and (max-width: 6000px) {
  .site-editor {
    .add-section {

      .add-section-btn,
      .save-order-btn {
        border-radius: 6rem !important;
        font-size: 2.125rem !important;
        margin-bottom: 4rem;
        height: 6rem;
        min-width: 14.5rem;
      }
    }
  }

  .add-new-section {
    h4 {
      font-size: 0.933vw;
      line-height: 1.088vw;
      margin-bottom: 0.829vw;
    }

    .all-btn-link {
      gap: 0.622vw;

      .darkbtn {
        border-radius: 0.415vw !important;
        padding: 0.415vw 0.829vw !important;
        font-size: 0.725vw !important;
      }
    }

    .lightbtn {
      border-radius: 0.415vw !important;
      padding: 0.415vw 0.829vw !important;
      font-size: 0.725vw !important;
      margin-bottom: 0.415vw !important;
    }
  }
}

@media screen and (min-width: 1930px) and (max-width: 3000px) {
  .site-editor {

    // padding-left: clamp(20px, 3vw, 60px);
    .add-section {

      .add-section-btn,
      .save-order-btn {
        height: clamp(3.25rem, 3.5vw, 5rem);
        min-width: clamp(8.5rem, 11.5vw, 18rem);
        border-radius: 3.25rem !important;
        font-size: clamp(1.125rem, 1.2vw, 1.8rem) !important;
        margin-bottom: 2.188rem;
      }
    }
  }

  .add-new-section {
    h4 {
      font-size: 0.933vw;
      line-height: 1.088vw;
      margin-bottom: 0.829vw;
    }

    .all-btn-link {
      gap: 0.622vw;

      .darkbtn {
        border-radius: 0.415vw !important;
        padding: 0.415vw 0.829vw !important;
        font-size: 0.725vw !important;
      }
    }

    .lightbtn {
      border-radius: 0.415vw !important;
      padding: 0.415vw 0.829vw !important;
      font-size: 0.725vw !important;
      margin-bottom: 0.415vw !important;
    }
  }

  // .seller-side-bg-col {
  //   flex-basis: 10% !important;
  //   max-width: 10% !important;
  // }
  // .dataside {
  //   flex-basis: 90% !important;
  //   max-width: 90% !important;
  // }
}

@media screen and (min-width: 800px) and (max-width: 920px) {
  .site-editor {
    .drag-container {
      margin-left: 16px;
    }
  }
}

@media screen and (max-width: 800px) {
  .site-editor {
    margin: 0 1.5rem;
    margin-bottom: 8vw;

    .welcominnercard {
      margin-left: 0;
      margin-right: 0;
    }

    .add-section {
      padding-left: 0;

      .add-section-btn,
      .save-order-btn {
        width: fit-content;
      }
    }

    .drag-container {
      flex-direction: column;
      margin-top: 4vw;
      row-gap: 4vw;
      margin-right: 0;
    }
  }
}

@media screen and (max-width: 600px) {
  .site-editor {
    margin: 0 1.5rem;
    margin-bottom: 8vw;

    .welcominnercard {
      margin-left: 0;
      margin-right: 0;
      padding: 10.6vw 4.8vw 5.6vw;
    }

    .add-section {
      padding-left: 0;
      flex-direction: column;

      .add-section-btn,
      .save-order-btn {
        width: 100%;
      }
    }

    .drag-container {
      flex-direction: column;
      margin-top: 4vw;
      row-gap: 4vw;
      margin-right: 0;
    }
  }

  .dataside {
    padding-left: 16px !important;
  }
}