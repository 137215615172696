.orderskeleton {
  table {
    width: 100%;
  }
  td {
    padding: 1vw;
  }
  .ordernumber,
  .statustd,
  .orderandstatus,
  .acionbtntd {
    width: 20%;
  }
  .noofitem,
  .sale {
    width: 10%;
  }
  .acionbtn {
    height: 3vw;
    border-radius: 1vw;
  }
}
