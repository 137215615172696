@import '../abstracts/variables';

.categories-header,
.sub-categories {
  margin-top: 2rem;
  margin-bottom: 2.5rem;

  .welcominnercard {
    .MuiGrid-root {
      .MuiGrid-root {
        .welcome-new-text {
          font-family: "Inter-Medium";
          font-weight: 500;
        }
      }
    }
  }

  .main-section {
    padding: 0 16px;

    .menu-list {
      .category-card {
        .icon-btn {
          .circle-btn {
            svg {
              width: 20px;
            }
          }
        }
      }
    }

    .categorybtn {
      display: flex;
      justify-content: space-between;

      .save-create {
        display: flex;
        column-gap: 16px;

        .create-menu,
        .save-order {
          height: 3.25rem;
          min-width: 8.5rem;
          padding: 0 1.25rem !important;
          border-radius: 3.25rem !important;
          font-size: 1.125rem !important;
          margin-bottom: 2.188rem;
          margin-right: 0 !important;
        }
      }
    }

    .add-product {
      margin-top: 21px;

      p {
        font-family: 'Asap-Regular';
        font-style: normal;
        font-weight: 600;
        font-size: 1.125rem;
        line-height: 21px;
        color: #2f2b23;
        margin-bottom: 1rem;
      }

      .search-input {
        margin: 0;
        width: 31.5rem !important;

        .MuiInputBase-root {
          width: 100%;
        }
      }
    }

    .buttons {
      width: 100%;
      margin-top: 70px;

      .buttons-position {
        display: flex;
        justify-content: flex-end;
        margin-right: 130px;

        .lightbtn {
          margin-right: 30px !important;
        }
      }
    }
  }
}

.mobile {
  display: none !important;
}

.main-picks-container {
  .product-list {
    .pop-up-gallery {
      .MuiAutocomplete-root {
        margin-bottom: 30px;
        width: 100%;
      }
    }
  }

  .drag-container {
    .draggable-update-card {
      .upload-description-card {
        cursor: auto;
      }

      .update-image-card {
        .upload-img {
          height: 100%;
        }
      }
    }
  }
}

.beauty-picks {
  z-index: 90000 !important;

  .MuiPaper-elevation {
    width: 100% !important;
    min-width: 1325px !important;
    border-radius: 20px !important;

    .link-toggle-btn {
      margin-bottom: 2rem;
      width: 100%;
      text-align: center;

      .logs-toggle {
        box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.2);
        border-radius: 25px;

        button {
          border: none;
          padding: 15px 20px;
          font-size: 1rem !important;
          font-family: 'Inter-Medium' !important;
          font-style: normal !important;
          font-weight: 500 !important;
          text-transform: capitalize !important;
        }

        .left-btn {
          border-radius: 25px;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }

        .right-btn {
          border-radius: 25px;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }

        .Mui-selected {
          color: rgba(255, 255, 255, 1);
          background-color: #1565c0;
        }
      }
    }
  }

  .main-picks-container {
    padding-top: 32px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 32px;
    overflow-y: auto;

    .add-product {
      margin-top: 0;
    }

    .product-list {
      margin-bottom: 0;
      margin-top: 0;

      .product-gallery {
        .img-container {
          margin-top: 1rem;

          .MuiSvgIcon-root {
            position: absolute;
            top: 10px;
            left: 10px;
            background: #2F2B23;
            border-radius: 50%;
            width: 30px;
            height: 30px;
            padding: 5px;
            color: #fff;
            cursor: pointer;
          }
        }
      }
    }
  }
}

.MuiAutocomplete-popper {
  z-index: 100000 !important;
}

.edit-position {
  z-index: 90000;

  .MuiPaper-elevation {
    width: 100% !important;
    max-width: 1325px !important;
    min-width: 1000px !important;
    border-radius: 20px !important;
  }


  .main-picks-container {
    padding-top: 32px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 70px;

    .add-product {
      margin-top: 0;
    }

    .edit-position-radio {
      display: flex;
      flex-direction: column;
      margin-bottom: 1rem;

      .position-radio-btn {
        display: flex;
        align-items: center;

        .MuiFormLabel-root {
          color: #2F2B23;
        }

        .radio-btn-container {
          .MuiFormControlLabel-root {
            .MuiButtonBase-root {
              color: #949d8c;
            }
          }
        }
      }

      .sub-position,
      .display-extra {
        label {
          margin-right: 15px;
        }

        display: flex;
        align-items: center;
      }
    }

    .meta-title {
      p {
        margin-bottom: 1rem;
        font-weight: 600;
        font-size: 1.125rem;
        line-height: 21px;
        font-family: $asap-font-regular;
      }

      margin-bottom: 1rem;
    }

    .single-img-upload {
      h6 {
        margin-bottom: 1rem;
      }

      .drag-container {
        .upload-arrow-card {
          min-width: 100px;
        }
      }
    }

    .product-list {
      margin-top: 0;

      .product-gallery {
        .img-container {
          margin-top: 1rem;

          .MuiSvgIcon-root {
            position: absolute;
            top: 10px;
            left: 10px;
            background: #2F2B23;
            border-radius: 50%;
            width: 30px;
            height: 30px;
            padding: 5px;
            color: #fff;
          }
        }
      }
    }
  }

}

.MuiAutocomplete-popper {
  z-index: 100000 !important;
}

.top-picks {
  z-index: 90000 !important;

  .MuiPaper-elevation {
    width: 100% !important;
    max-width: 1325px !important;
    min-width: 1000px !important;
    border-radius: 20px !important;
  }

  form {
    overflow-y: auto;
  }

  .main-picks-container {
    padding-top: 32px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 70px;

    .meta-info {

      // width: calc(1320px - 20%);
      .meta-title,
      .meta-des,
      .meta-keywords {
        display: flex;

        p {
          width: 140px;
          font-family: 'Asap-Regular';
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 18px;
          color: #44423d;
        }

        .input-width // ,span

          {
          width: 70%; //
        }

        .main-textarea {
          width: 70%; //change from div to className

          .input-textarea {
            width: 100%;
          }
        }


      }

      .meta-title {
        margin-bottom: 34px;
      }

      .meta-des {
        margin-bottom: 28px;
      }
    }

    .drag-container {
      margin-right: 0;
    }
  }

  .search-pop-up {
    .input-title {
      .meta-info {
        .single-upload {
          .drag-container {
            .draggable-update-card {
              .update-image-card {
                p {
                  flex: 0;
                }
              }

              .upload-description-card {
                h3 {
                  font-size: 28px;
                  line-height: 36px;
                }
              }
            }
          }
        }
      }
    }
  }
}

.edit-menu-box {
  display: flex;
  flex-direction: column;
  row-gap: 15px;

  .edit-menu-btn {
    .darkbtn {
      margin-right: 15px;
    }
  }
}

.search-pop-up {
  h4 {
    font-family: "Inter-Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 22.6507px;
    line-height: 32px;
    color: #1f1f1f;
    margin-bottom: 1.25rem;
  }

  .description {
    width: 50%;
    font-family: "Asap-Regular";
    font-style: normal;
    font-weight: 400;
    font-size: 15.757px;
    line-height: 24px;
    color: #44423d;
    margin: 0;
  }

  .input-title {
    margin-top: 3.125rem;

    .meta-info {

      .meta-title,
      .meta-des,
      .meta-keywords,
      .single-upload {
        display: flex;
        margin-bottom: 2.125rem;

        p {
          font-family: "Asap-Regular";
          font-style: normal;
          font-weight: 500;
          font-size: 15.757px;
          line-height: 18px;
          color: #44423d;
          flex: 1;
        }

        .input-width {
          flex: 5;
        }

        .textarea-box {
          display: contents;

          .input-textarea {
            flex: 5;
          }
        }
      }

      .single-upload {
        flex-direction: column;

        p {
          margin-bottom: 1.6rem;
        }

        .drag-container {
          margin-right: 0;
        }
      }
    }
  }
}

.categories-create-menu {
  .create-menu-title {
    font-size: 18px;
    line-height: 24px;
  }
}

@media screen and (min-width: 3000px) and (max-width: 6000px) {
  .return-btn {
    border-radius: 6rem !important;
    font-size: 1.8rem !important;
    margin-bottom: 4rem;
    height: 4rem;
    min-width: 14.5rem;
  }

  .categories-header {
    .main-section {
      .categorybtn {
        .save-create {
          .AddTo {
            .bluebtn {
              border-radius: 6rem !important;
              font-size: 1.8rem !important;
              margin-bottom: 4rem;
              height: 4rem;
              min-width: 14.5rem;
            }
          }

          .save-order {
            border-radius: 6rem !important;
            font-size: 1.8rem !important;
            margin-bottom: 4rem;
            height: 4rem;
            min-width: 14.5rem;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1930px) and (max-width: 3000px) {

  .categories-header,
  .sub-categories {
    .main-section {
      .categorybtn {
        .save-create {

          .create-menu,
          .save-order,
          .create-menu,
          .save-order {
            height: clamp(3.25rem, 3.5vw, 5rem);
            min-width: clamp(8.5rem, 11.5vw, 18rem);
            border-radius: 3.25rem !important;
            font-size: clamp(1.125rem, 1.2vw, 1.8rem) !important;
          }
        }
      }

      .buttons {
        .buttons-position {

          .lightbtn,
          .bluebtn {
            font-size: 1.016vw !important;
            margin-right: 0.781vw !important;
            padding: 15px 24px !important;
            border-radius: 18px !important;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1930px) {
  .search-pop-up {
    h4 {
      font-size: 30px;
      line-height: 36px;
    }

    .description {
      width: 100%;
      font-size: 20px;
      line-height: 28px;
    }

    .input-title {
      .meta-info {

        .meta-title,
        .meta-des,
        .meta-keywords,
        .single-upload {
          display: flex;
          margin-bottom: 2.125rem;

          p {
            font-size: 20px;
            line-height: 28px;
          }

          .input-width {
            flex: 5;
          }

          .textarea-box {
            display: contents;

            .input-textarea {
              flex: 5;
            }
          }
        }

      }
    }
  }

  .categories-create-menu {
    .create-menu-title {
      font-size: 28px;
      line-height: 32px;
    }

    .edit-menu-box {
      .edit-menu-btn {

        .bluebtn,
        .lightbtn {
          font-size: 24px !important;
        }
      }
    }
  }

  .btn-section {

    .lightbtn,
    .bluebtn {
      font-size: 18px !important;
    }
  }

  .edit-position {
    .main-picks-container {
      .add-product {
        p {
          font-size: 1.6rem;
          line-height: 28px;
        }
      }

      .edit-position-radio {
        .position-radio-btn {
          .MuiFormLabel-root {
            font-size: 1.4rem;
            line-height: 28px;
          }

          .radio-btn-container {
            .MuiFormControlLabel-root {
              .MuiTypography-root {
                font-size: 1.4rem;
                line-height: 28px;
              }
            }
          }
        }
      }

      .meta-title {
        p {
          font-size: 1.6rem;
          line-height: 28px;
        }
      }

      .single-img-upload {
        h6 {
          font-size: 1.6rem;
          line-height: 28px;
        }

        .drag-container {
          .draggable-update-card {
            .upload-description-card {
              h3 {
                font-size: 1.6rem;
                line-height: 28px;
              }
            }
          }
        }
      }

      .product-list {
        margin-top: 0;

        .product-gallery {
          .img-container {
            margin-top: 1rem;

            .MuiSvgIcon-root {
              position: absolute;
              top: 10px;
              left: 10px;
              background: #2F2B23;
              border-radius: 50%;
              width: 30px;
              height: 30px;
              padding: 5px;
              color: #fff;
            }
          }
        }
      }
    }

    .lightbtn,
    .bluebtn {
      font-size: 18px !important;
    }
  }

  .top-picks {
    .main-picks-container {
      .meta-info {

        .meta-title,
        .meta-des {
          p {
            font-size: 20px;
            line-height: 28px;
          }
        }
      }

      .drag-container {
        .draggable-update-card {
          .upload-description-card {
            h3 {
              font-size: 30px;
              line-height: 36px;
            }
          }
        }
      }
    }

    .lightbtn,
    .bluebtn {
      font-size: 18px !important;
    }

  }

  .beauty-picks .main-picks-container .product-list .product-gallery .img-container {
    width: 33.333%;
  }

  .beauty-picks {
    .main-picks-container {
      .add-product {
        p {
          font-size: 1.6rem;
          line-height: 28px;
        }
      }

      .product-list {
        .pop-up-gallery {
          .img-container {
            .label-file {
              .productscard {
                .product-text-section {
                  .product-text {
                    font-size: 1.4rem;
                  }
                }
              }
            }
          }
        }
      }
    }

    .lightbtn,
    .bluebtn {
      font-size: 18px !important;
    }
  }
}

@media screen and (min-width: 1280px) and (max-width: 2000px) {

  .top-picks,
  .edit-position {
    .MuiPaper-elevation {
      min-width: clamp(400px, 79vw, 1000px) !important;
      max-width: 0 !important;
    }
  }
}

@media screen and (min-width: 800px) and (max-width: 1600px) {

  .top-picks,
  .edit-position,
  .beauty-picks {
    .MuiPaper-elevation {
      min-width: clamp(400px, 79vw, 1000px) !important;
      max-width: 0 !important;
    }
  }

  .beauty-picks {
    .MuiPaper-elevation {
      .filter-table {
        .img-container {
          width: 33.333%;
        }
      }
    }
  }
}

@media screen and (min-width: 800px) and (max-width: 1200px) {
  .desktop {
    display: none !important;
  }

  .mobile {
    display: flex !important;
  }
}

@media screen and (min-width: 600px) and (max-width: 920px) {
  .categories-header {
    .welcominnercard {
      margin-left: 0;
      margin-right: 0;
    }
  }
}

@media screen and (max-width: 800px) {

  .top-picks .main-picks-container .meta-info .meta-des .main-textarea {
    width: 100%;
  }

  .desktop {
    display: none !important;
  }

  .mobile {
    display: flex !important;
  }

  .categories-header,
  .sub-categories {
    margin: 0 1.5rem;
    margin-bottom: 8vw;

    .welcomecard {
      margin-left: 0;
      margin-right: 0;
    }

    .main-section {
      padding: 0;

      .categorybtn {
        flex-direction: column;

        .save-create {
          flex-direction: column;

          .create-menu,
          .save-order {
            margin-left: 0;
          }

          .btn-width {
            width: 100%;

            .create-menu,
            .save-order {
              width: 100%;
            }
          }
        }
      }

      .add-product {
        .search-input {
          width: 100% !important;
        }
      }

      .buttons {
        width: 100%;
        margin-top: 40px;

        .buttons-position {
          margin-right: 0;
        }
      }
    }
  }

  .beauty-picks {
    .MuiPaper-elevation {
      width: 100% !important;
      min-width: clamp(300px, 94vw, 400px) !important;
    }

    .main-picks-container {

      .product-list {
        margin-top: 47px;

        .product-gallery {
          .img-container {
            width: clamp(200px, 16rem, 250px);
          }

        }
      }
    }

    .bottom-btn {
      margin-bottom: 51px;
    }
  }

  .top-picks,
  .edit-position {
    .MuiPaper-elevation {
      width: 100% !important;
      min-width: clamp(300px, 94vw, 400px) !important;
    }

    .main-picks-container {
      padding-top: 32px;
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 70px;

      .meta-info {

        .meta-title,
        .meta-des {
          display: flex;
          flex-direction: column;

          .textarea-box {
            width: 100%;
          }

          p {
            width: 100%;
            margin-bottom: 1rem;
          }

          .input-width,
          span {
            width: 100%;
          }
        }

        .meta-title {
          margin-bottom: 34px;
        }

        .meta-des {
          margin-bottom: 28px;
        }
      }
    }

    .bottom-btn {
      margin-bottom: 51px;
    }
  }

  .search-pop-up {
    .description {
      width: 100%;
    }

    .input-title {
      margin-top: 3.125rem;

      .meta-info {

        .meta-title,
        .meta-des,
        .meta-keywords,
        .single-upload {
          flex-direction: column;

          p {
            flex: 1;
          }

          .input-width {
            flex: 1;
          }

          .textarea-box {
            .input-textarea {
              flex: 1;
            }
          }
        }
      }
    }
  }



  .top-picks {
    .search-pop-up {
      .input-title {
        .meta-info {

          .meta-title,
          .meta-des,
          .meta-keywords {
            p {
              margin-bottom: 1rem;
            }
          }

          .single-upload {
            .drag-container {
              .draggable-update-card {
                .upload-description-card {
                  h3 {
                    font-size: 24px;
                    line-height: 30px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 600px) {
  .sub-categories .main-section .categorybtn .save-create .create-menu {
    width: 100%;
  }


}

@media screen and (max-width: 600px) {

  .edit-position .main-picks-container .edit-position-radio .position-radio-btn {
    flex-direction: column;
    align-items: start;
  }

  .categories-header,
  .sub-categories {
    margin: 0 1.5rem;
    margin-bottom: 8vw;

    .welcomecard {
      margin-left: 0;
      margin-right: 0;
    }

    .main-section {
      padding: 0;

      .categorybtn {
        flex-direction: column;

        .save-create {
          flex-direction: column;

          .AddTo {
            .bluebtn {
              width: 100%;
            }
          }

          .create-menu,
          .save-order {
            margin-left: 0;
          }

          .btn-width {
            width: 100%;

            .create-menu,
            .save-order {
              width: 100%;
            }
          }
        }
      }

      .add-product {
        .search-input {
          width: 100% !important;
        }
      }

      .buttons {
        width: 100%;
        margin-top: 40px;

        .buttons-position {
          margin-right: 0;
        }
      }
    }
  }

  .beauty-picks {
    .MuiPaper-elevation {
      width: 100% !important;
      min-width: clamp(300px, 94vw, 400px) !important;
    }

    .main-picks-container {
      .product-list {
        .filter-table {
          .img-container {
            width: 100%;
          }
        }
      }

      .product-list {
        margin-top: 47px;
      }
    }

    .bottom-btn {
      margin-bottom: 20px;
    }
  }

  .top-picks,
  .edit-position {
    .MuiPaper-elevation {
      width: 100% !important;
      min-width: clamp(300px, 94vw, 400px) !important;
    }

    .main-picks-container {
      padding-top: 32px;
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 70px;

      .meta-info {
        width: calc(clamp(300px, 94vw, 400px) - 10%);

        .meta-title,
        .meta-des {
          display: flex;
          flex-direction: column;

          p {
            width: 100%;
            margin-bottom: 1rem;
          }

          .input-width,
          span {
            width: 100%;
          }

        }

        .meta-title {
          margin-bottom: 34px;
        }

        .meta-des {
          margin-bottom: 28px;
        }
      }
    }

    .bottom-btn {
      margin-bottom: 51px;
    }
  }

  .search-pop-up {
    .description {
      width: 100%;
    }

    .input-title {
      margin-top: 3.125rem;

      .meta-info {

        .meta-title,
        .meta-des,
        .meta-keywords,
        .single-upload {
          flex-direction: column;

          p {
            flex: 1;
          }

          .input-width {
            flex: 1;
          }

          .textarea-box {
            .input-textarea {
              flex: 1;
            }
          }
        }
      }
    }
  }

  .categories-header {
    .welcominnercard {
      margin-left: 0;
      margin-right: 0;
    }
  }
}