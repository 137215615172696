.delete-pop-up {
  z-index: 1300000 !important;
  .delete-container {
    padding: 24px;
    h2 {
      margin-bottom: 18px;
      font-size: 25px;
      font-family: $asap-font-regular;
    }
    p {
      font-size: 18px;
      font-family: $asap-font-regular;
    }
  }
}

@media screen and (min-width: 1930px) and (max-width: 3000px) {
  .delete-pop-up {
    .delete-container {
      padding: 1.244vw;
      h2 {
        font-size: 1.244vw;
      }
      p {
        font-size: 0.933vw;
      }
    }
    .bluebtn,
    .lightbtn {
      font-size: 0.725vw !important;
      padding: 0.415vw 0.829vw !important;
    }
  }
}

@media screen and (min-width: 3000px) and (max-width: 6000px) {
  .delete-pop-up {
    .delete-container {
      padding: 1.244vw;
      h2 {
        font-size: 1.244vw;
      }
      p {
        font-size: 0.933vw;
      }
    }
    .bluebtn,
    .lightbtn {
      font-size: 0.725vw !important;
      padding: 0.415vw 0.829vw !important;
    }
  }
}
