.dataside {
  // background: rgb(250, 249, 249);
  background-color: #ffffff;
  // padding-right: 16px;
  padding-left: 0 !important; //required coz its keep raising that point on slack
  padding-left: 8px !important;
}

.top-picker {
  background-color: #d3cec0;
  text-align: center;
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  font-family: Asap;
  font-style: normal;
  padding: 10px;
  text-transform: uppercase;
  min-height: 54px;
}

.seller-side-bg-col {
  background: #f3f6fb;
  height: 100%;
}

.sellersidebar {
  // background: rgb(250, 249, 249);
  background: #f3f6fb;
  z-index: 9999;
  height: 100%;
  padding-bottom: 25px;
  height: 100%;
  overflow-y: auto;
  // flex: 1 0 auto;
  position: fixed;
  top: 0px;
  outline: 0px;
  left: 0;
  transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  overflow-y: scroll;
  mask-image: linear-gradient(to top, transparent, black), linear-gradient(to left, transparent 17px, black 17px);
  mask-size: 112% 20000px;
  mask-position: left bottom;
  -webkit-mask-image: linear-gradient(to top, transparent, black),
    linear-gradient(to left, transparent 17px, black 17px);
  -webkit-mask-size: 112% 20000px;
  -webkit-mask-position: left bottom;
  transition: mask-position 0.3s, -webkit-mask-position 0.3s;
  box-shadow: none;
  // min-width: 7.5vw;
  min-width: 8vw;

  .logogoethical {
    width: 72%;
    padding: 0px 20px;
    margin-top: 16px;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    object-fit: cover;
  }

  a:hover,
  a:active,
  a:focus {
    text-decoration: none;
  }

  .sidebar-menu-name {
    color: #444746;
    font-size: 0.867rem;
    font-family: $asap-font-regular;
    font-weight: 500;
    font-style: normal;
    line-height: 1.127rem;
  }

  .menu-name {
    margin-bottom: 0px !important;
    height: 42px;
  }

  .menu-name:hover {
    .icon-sidebar {
      color: #2f2b23 !important;
    }

    .sidebar-menu-name {
      color: #969c8d;
      font-size: 16px;
    }
  }

  .menu-name:hover .sidebar-menu-name {
    color: #969c8d;
    font-size: 16px;
  }

  .item2 {
    display: flex;
    padding: 1.2vw 0.67vw 0;
    align-items: center;
    cursor: pointer;
    flex-direction: column;
  }

  .sidebar-new-img {
    margin-bottom: 0.6vw;
    // padding: 0.5rem 1.2rem 0.2rem;
    padding: 0.48vw 1.2vw;
    display: flex;
    align-items: center;
    justify-self: center;
    min-width: 3.8888vw;
    min-height: 2.29vw;
  }

  .item2:active {
    .sidebar-new-img {
      border-radius: 2rem;
      background: #c2e7fe;
    }

    .sidebar-menu-name {
      color: #001c35;
    }
  }

  .item2:focus {
    .sidebar-new-img {
      border-radius: 2rem;
      background: #c2e7fe;
    }

    .sidebar-menu-name {
      color: #001c35;
    }
  }

  .item2:hover {
    .sidebar-new-img {
      border-radius: 2rem;
      background: #c2e7fe;
    }

    .sidebar-menu-name {
      color: #001c35;
    }
  }

  .new-sidebar {
    // height: 16px;
    // width: 16px;
    // margin-right: 16px;
    // width: 1.467rem;
    // height: 1.467rem;
    width: 1.527vw;
    height: 1.527vw;
  }
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: none;
  // border-radius: 20px;
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #919eab;
  // border-radius: 10px;
  overflow: hidden;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #919eab;
  overflow: hidden;
}

.sellersidebar:hover {
  -webkit-mask-position: left top;
  background: #f3f6fb;
}

.MuiDrawer-root {
  .MuiListItemIcon-root {
    min-width: 40px;
  }

  img.new-sidebar {
    width: 21px;
  }

  .MuiListItemText-root span {
    color: #2f2b23;
    font-size: 16px;
  }
}

@media (min-width: 280px) and (max-width: 600px) {
  .mobileviewset {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px;

    .MuiSvgIcon-root {
      color: #000 !important;
    }

    .logogoethical {
      width: 45% !important;
      display: none;
    }
  }
}

@media (min-width: 601px) and (max-width: 919px) {
  .mobileviewset {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px;

    .MuiSvgIcon-root {
      color: #000 !important;
    }

    .logogoethical {
      width: 25% !important;
      margin-bottom: 10px;
    }
  }

  .dataside {
    background-color: #ffffff;
    padding-left: 30px !important;
    width: 100% !important;
    max-width: 100% !important;
    flex-basis: 100% !important;
  }
}

@media (min-width: 921px) and (max-width: 1150px) {
  .sellersidebar {
    min-width: 10.5vw;
  }
}

@media (min-width: 1930px) and (max-width: 6000px) {
  .dataside {
    padding-left: 1.8vw !important;
  }
}

@media (min-width: 2000px) and (max-width: 6000px) {
  .sellersidebar {
    .sidebar-menu-name {
      font-size: 1.1vw;
      // margin-left: 0.8vw;
      line-height: 1.6;
    }

    .main-accordion {
      .MuiPaper-root {
        .MuiButtonBase-root {
          .MuiAccordionSummary-content {
            .MuiTypography-root {
              font-size: 1.2vw !important;
            }
          }
        }
      }
    }

    .new-sidebar {
      height: 1.2vw;
      width: 1.2vw;
      // margin-right: 0.8vw;
    }

    .item2 {
      padding: 0.6vw 0.2vw 0.6vw 0vw;
      margin: 0.4vw 0.8vw;
    }
  }

  .top-picker {
    font-size: 0.88vw;
    line-height: 1.2vw;
    padding: 0.5vw;
    min-height: 2.5vw;
  }
}

@media screen and (min-width: 200px) and (max-width: 919px) {
  .MuiDrawer-root {
    .MuiPaper-root {
      .MuiBox-root {
        .MuiList-root {
          a {
            .MuiListItem-root {
              .MuiButtonBase-root {
                padding-top: 8px;
                padding-bottom: 8px;
                padding-right: 16px;
              }
            }
          }
        }
      }
    }
  }
}
