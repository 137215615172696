// -----------------------------------------------------------------------------
// This file contains all @font-face declarations, if any.
// -----------------------------------------------------------------------------

@font-face {
    font-family: 'Asap-Regular';
    src: local('Asap-Regular'), url('../../assets/font/Asap-Regular.ttf.woff') format('woff');
    // font-weight: 100;
     //deeksha
     font-weight: normal;
     font-style: normal;
    font-display: swap;
  }