.addsingleproduct {
  margin-top: 20px;

  table .input-width {
    width: 74px;
  }

  .MuiGrid-root {
    width: 100%;
    margin-left: 0px;
  }

  .centertext12 {
    align-items: center;
  }

  .css-sayu3r>.MuiGrid-item {
    padding-top: 0px !important;
    margin-top: 44px !important;
    padding-left: 0px !important;
  }

  table .selectbox .select-field {
    padding: 8px 0px 8px 0px !important;
    background-size: 11px !important;
    background-position: 96% !important;
    width: 120px !important;
    font-size: 14px !important;
  }

  .css-1e6alsu-MuiButtonBase-root-MuiChip-root,
  .css-v7mgfm {
    background-color: #7f6a86;
    color: #fff;
    border-radius: 4px;
    margin-top: 5px;
  }

  .css-1e6alsu-MuiButtonBase-root-MuiChip-root .MuiChip-deleteIcon {
    color: #fff;
  }

  .publish-card {
    margin-bottom: 24px;
    justify-content: space-between;
    background-color: rgb(232, 229, 227);
    border-radius: 8px;
    align-items: center;
    flex-direction: row;
    box-sizing: border-box;
    display: flex;
    padding: 10px 0px 10px 18px;
    margin-top: 28px;
  }

  .delete {
    text-decoration: underline;
    cursor: pointer;
  }

  .chip {
    background: #7f6a86;
    border-radius: 4px;
  }

  .multiSelectContainer {
    background-color: #ffffff;
  }

  form {
    padding-bottom: 46px;
  }

  .select-field {
    height: 42px;
    border-radius: 8px;
    border: 1px solid #ccc;
    background-color: #ffffff;
    color: #2f2b23;
    font-size: 16px;
    line-height: 18px;
    background-repeat: no-repeat;
    background-size: 14px;
    background-position: 92%;

    .MuiOutlinedInput-notchedOutline {
      border: none !important;
    }
  }

  .singleproduct-container {
    padding-top: 25px;
    padding-bottom: 60px;
    margin-left: 9%;
    margin-right: 9%;
  }

  .container-table {
    border: 1px solid #d8d4cf;
    border-radius: 8px;
    margin-bottom: 24px;
    width: 100%;
  }

  table {
    width: 100%;
    border-collapse: collapse;
  }

  .table-data {
    border-bottom: 1px solid #d8d4cf;
  }

  .discount-section {
    display: flex;
  }

  .table-heading th {
    font-family: $asap-font-regular;
    font-style: normal;
    font-size: 14px;
    font-weight: normal;
    color: #2f2b23;
    padding: 16px;
    text-align: left;
  }

  td {
    padding: 14px;
    border-top: 1px solid #d8d4cf;
  }

  .shipping3 {
    font-family: $asap-font-regular;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 130%;
    color: #757061;
    margin: 0px 0px 20px 0px;
  }

  .welcominnercard {
    margin-bottom: 5vw;
  }

  .personal-container {
    // border: 1px solid #d8d4cf;
    // border-radius: 8px;
    padding: 24px 16px;
    margin-top: 24px;
    background: #f8fafd;

    // margin-bottom: 24px;
    .MuiGrid-container {
      .MuiGrid-root {
        .MuiChip-root {
          margin-top: 1rem;
        }
      }
    }
  }
  .dropdownwidth {
    // width: 50%;
    display: flex;

    .shipping-dropdown {
      width: 50%;
    }

    .leftspace {
      padding-right: 10px;
    }
  }

  .order-number {
    font-family: $asap-font-regular;
    font-style: normal;
    font-size: 16px;
    line-height: 130%;
    font-weight: normal;
    color: #2f2b23;
    margin-bottom: 4px;
    padding-right: 24px;
  }

  .pixel-text {
    font-family: $asap-font-regular;
    font-style: normal;
    font-size: 16px;
    font-weight: normal;
    color: #757061;
    margin-right: 37px;
  }

  .img-row {
    height: 8vw;
    // height: 99.76px;
    min-height: 100px;
    min-width: 100px;
  }

  .productscard:hover .icon-row {
    display: none;
  }

  .img-container {
    width: 33.33%;
  }

  input.ReactTags__tagInputField:focus-visible {
    outline: none;
  }

  .css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked {
    color: #949d8c;
  }

  .css-1a5icme.Mui-checked {
    color: #949d8c;
  }

  .multiSelectContainer li:hover,
  .highlightOption {
    background: #7f6a86;
  }

  .productscard .product-text {
    width: 110px;
  }

  .product-price {
    display: none;
  }

  .close-icon {
    position: absolute;
    right: 5%;
    top: 3%;
    color: #ccc;
    cursor: pointer;
  }

  .delete-button {
    border: none;
    cursor: pointer;
    background: #d3cec0;
    border-radius: 50%;
    /* padding: 6px; */
    padding-top: 4px;
  }

  .drop-down {
    margin-bottom: 24px;
    border: 1px dashed #ccc;
    border-radius: 8px;
    // height: 160px;
    // max-width: 433px;
    // min-width: 433px;
  }

  .file-label {
    text-align: center;
    margin: 16px 0px 0px 0px;
  }

  .upload-img {
    height: 23px;
    width: 23px;
    margin-top: 20px;
  }

  .select-image-text {
    font-family: $asap-font-regular;
    font-weight: normal;
    font-style: normal;
    font-size: 16px;
    margin: 16px 55px 25px 81px;
    color: #2f2b23;
    text-align: center;
    padding: 0 5vw;
  }

  .inputbtn .lightbtn {
    color: #a6a4a0 !important;
    background: #e8e5de !important;
    box-shadow: none !important;
    font-weight: 500 !important;
    margin-top: 0px !important;
  }

  .categories-drop-down .lightbtn {
    color: #a6a4a0 !important;
    background: #e8e5de !important;
    box-shadow: none !important;
    font-weight: 500 !important;
    margin-top: 10px;
  }

  .inputbtn .singleproductbtndrk {
    background-color: #949d8c !important;
    color: #fff !important;
  }

  .categories-drop-down .singleproductbtndrk {
    background-color: #949d8c !important;
    color: #fff !important;
  }

  .lightbtn,
  .darkbtn {
    box-shadow: none !important;
    font-weight: 500 !important;
    text-transform: inherit !important;
  }

  .darkbtn {
    background-color: #c4c9c0 !important;
  }

  .test .lightbtn {
    color: #a6a4a0 !important;
    background: #e8e5de !important;
  }

  .test .singleproductbtndrk {
    background-color: #949d8c !important;
    color: #fff !important;
  }

  .singleproductbtndrk {
    background-color: #949d8c !important;
    color: #fff !important;
  }

  .selectbox {
    .select-field {
      width: 100% !important;
      background-position: 96%;
      height: 42px;
    }
  }

  .gridcenter {
    // align-items: center;
    margin-bottom: 24px;
  }

  .centerradiobutton {
    align-items: center;
  }

  .MuiRadio-root {
    padding: 9px 2px;
  }

  .subscription-radio {
    margin-left: 24px;
  }

  .categories-drop-down {
    display: inline-block;
    width: 45%;
    margin-right: 10px;
    // margin-top: 10px;

    .selectbox .select-field {
      width: 100% !important;
    }
  }

  .add-new {
    font-family: $asap-font-regular;
    font-style: normal;
    font-size: 14px;
    font-weight: normal;
    color: #2f2b23;
    margin-top: 5px;
  }

  .inputbtn,
  .radiobtn-section {
    display: flex;
    align-items: flex-start;
  }

  .css-1h7anqn {
    display: flex;
    flex-flow: inherit;
  }

  .css-dmmspl-MuiFormGroup-root {
    display: -webkit-box !important;
    display: contents !important;
  }

  .variation-text {
    font-family: $asap-font-regular;
    font-style: normal;
    font-size: 16px;
    font-weight: normal;
    color: #2f2b23;
    margin-bottom: 24px;
    // padding-right: 19vw;
    padding-right: 31vw;
  }

  .preview-btn {
    .lightbtn {
      background-color: #e8e5de !important;
      color: #a6a4a0 !important;
    }
  }

  .btn-section {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
  }

  .cursorpointer {
    cursor: pointer;
  }

  input.ReactTags__tagInputField {
    border-radius: 8px;
    border: 1px solid #ccc;
    width: 100%;
    padding: 0 8px;
    font-size: 16px;
    letter-spacing: 0px;
    font-weight: normal;
    height: 42px;
    opacity: 1;
    font-family: $asap-font-regular;
    color: #2f2b23;
    background-color: #fff;
  }

  .input.ReactTags__tagInputField:focus-visible {
    outline: none;
  }

  .MultiSelect {
    margin-right: 12px;
    width: 85%;
  }

  input#search_input {
    font-size: 16px;
    line-height: 20px;
  }

  div#multiselectContainerReact li.option {
    font-size: 14px;
    line-height: 18px;
  }

  .ReactTags__selected {
    margin-top: 8px;
    word-break: break-word;

    // span {
    //   height: 26px;
    //   padding: 2px 4px;
    //   background-color: #7f6a86;
    //   color: #ffffff;
    //   border: none;
    //   margin-right: 8px;
    //   border-radius: 4px;
    //   margin-top: 0px;
    //   max-height: 30px;
    //   position: relative;
    //   display: initial;
    //   text-overflow: ellipsis;
    // white-space: nowrap;
    //  overflow: hidden;
    //  width: 160px;
    // }
    span {
      height: 26px;
      padding: 2px 4px;
      background-color: #7f6a86;
      color: #ffffff;
      border: none;
      margin-right: 8px;
      border-radius: 4px;
      margin-top: 8px;
      width: auto;
      max-width: fit-content;
      display: inline-table;
      position: relative;
      padding-right: 28px;
    }

    button.ReactTags__remove {
      cursor: pointer;
      background: transparent;
      border: none;
      font-size: 23px;
      /* line-height: 26px; */
      color: #fff;
      position: absolute;
      right: 0;
      top: 3px;
    }
  }
}

.MuiDialogContent-root {
  .productscard .img-row {
    height: 8vw;
  }
}

@media (min-width: 600px) and (max-width: 800px) {
  .addsingleproduct .select-image-text {
    padding: 0 2vw;
    margin-left: auto;
    margin-right: auto;
  }

  .addsingleproduct .categories-drop-down {
    width: 100%;
  }
}

@media (min-width: 260px) and (max-width: 600px) {
  .addsingleproduct {
    .container-table {
      overflow: auto;
    }

    .MultiSelect {
      width: 65%;
    }

    .productscard .product-text {
      width: 95px;
    }

    .img-container {
      width: 50%;
      vertical-align: top;
    }

    .selectbox {
      margin-top: 0 !important;
    }

    .css-sayu3r>.MuiGrid-item {
      padding-left: 0px !important;
      margin-top: 10px !important;
    }

    .input-textarea {
      margin-bottom: 0;
    }

    .MuiGrid-root {
      margin-top: 4px;
    }

    .gridcenter {
      margin-bottom: 15px;
    }

    .select-image-text {
      margin: 16px 0px 25px 0px;
    }

    table .selectbox {
      margin-top: 0px;
    }

    .input-field-outline {
      // min-width: 120px;
      min-width: 100%;
      height: 38px;
    }

    .dropdownwidth {
      width: 100%;

      .dropdown {
        margin-bottom: 20px;
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1280px) {
  .addsingleproduct {
    .select-image-text {
      padding: 0;
    }

    .productscard .product-text {
      width: 100px;
    }
  }
}

@media (min-width: 919px) and (max-width: 1280px) {
  .addsingleproduct {
    .select-image-text {
      padding: 0 !important;
    }
  }
}

@media (min-width: 2000px) and (max-width: 6000px) {
  .addsingleproduct {
    .main-heading40 {
      font-size: 1.5vw;
      line-height: 1.7;
    }

    table .selectbox .select-field {
      background-size: 0.8vw !important;
      background-position: 95% !important;
      width: 8vw !important;
      font-size: 0.8vw !important;
    }

    table .input-width {
      width: 5vw;
    }

    .drop-down {
      border: 0.1vw dashed #ccc;
      border-radius: 0.5vw;
    }

    .selectbox .select-field {
      height: 2.2vw;
      line-height: 1.5vw;
    }

    .MuiChip-deletable {
      font-size: 0.8vw;
      line-height: 1.2vw;
      height: 1.6vw;
    }

    .sub-heading {
      font-size: 1.2vw;
      line-height: 1.5;
    }

    .add-new {
      font-size: 0.8vw;
      margin-top: 0.5vw;
    }

    .ReactTags__selected {
      margin-top: 0.4vw;

      span {
        height: 2.6vw !important;
        max-height: 2.6vw !important;
        font-size: 1vw;
        padding: 0.1vw 0.5vw 0vw;
      }
    }

    .categories-drop-down .lightbtn {
      top: 0.6vw;
      margin-top: 0 !important;
    }

    .lightbtn,
    .darkbtn {
      border-radius: 0.5vw !important;
      padding: 0.4vw 0.8vw !important;
      font-size: 0.8vw !important;
    }

    .order-number,
    .pixel-text {
      font-size: 0.8vw;
      line-height: 1.2vw;
    }

    .img-row {
      height: 8vw;
    }

    .file-label {
      margin: 0.8vw 0px 0px 0px;
    }

    .upload-img {
      height: 1.8vw;
      width: 1.8vw;
      margin-top: 1.2vw;
    }

    .select-image-text {
      font-size: 0.8vw;
      margin: 0.8vw auto 1.2vw auto;
    }

    input.ReactTags__tagInputField {
      border-radius: 0.5vw;
      padding: 0 0.5vw;
      font-size: 1vw;
      height: 2.5vw;
    }

    .searchWrapper {
      border-radius: 0.5vw;
      padding: 0.4vw;
      min-height: 2.4vw;

      .chip {
        padding: 0.5vw;
        margin-right: 0.4vw;
        border-radius: 0.4vw;
        font-size: 0.8vw;
        line-height: 1vw;

        .closeIcon {
          height: 1vw;
          width: 1vw;
          float: right;
          margin-left: 0.4vw;
          cursor: pointer;
        }
      }
    }

    input#search_input {
      font-size: 1vw;
      line-height: 1.2vw;
    }

    div#multiselectContainerReact li.option {
      font-size: 0.8vw;
      line-height: 1.2vw;
      padding: 0.8vw;
    }

    .onoffswitch {
      .MuiFormControlLabel-root {
        zoom: 2.5;
      }
    }

    .variation-text {
      font-size: 0.8vw;
      margin-bottom: 1vw;
      padding-right: 0;
    }

    .radiobtn-section {
      .MuiTypography-root {
        font-size: 0.8vw;
        line-height: 1.2vw;
      }
    }

    .table-heading th {
      font-size: 0.8vw;
      line-height: 1.2vw;
    }
  }

  .addsingleproduct .ReactTags__selected .tag-wrapper {
    height: auto !important;
    padding: 4px 8px;
    padding-right: 24px;

    .ReactTags__remove {
      top: 50%;
      transform: translateY(-50%);
    }
  }
}