.product-list {
  margin-top: 47px;
  margin-bottom: 5rem;
  .img-container {
    width: 20%;
    padding: 0;
    padding-right: 12px;
    .productscard {
      .product-text-section {
        .product-text {
          font-family: $asap-font-regular;
          font-style: normal;
          font-weight: 600;
          font-size: 1rem;
          color: #2f2b23;
        }
        .product-price,
        .copy-icon,
        .icon-row {
          display: none;
        }
      }
    }
  }
  .delete-icon {
    position: absolute;
    top: 12px;
    left: 16px;
    cursor: pointer;
  }
}

@media screen and (min-width: 1930px) and (max-width: 3000px) {
  .product-list {
    .img-container {
      .productscard {
        .product-text-section {
          .product-text {
            font-size: 1.016vw;
          }
        }
      }
    }
    .delete-icon {
      position: absolute;
      top: 12px;
      left: 16px;
      cursor: pointer;
      width: clamp(25px, 2vw, 45px);
      height: clamp(25px, 2vw, 45px);
    }
  }
}

@media screen and (max-width: 800px) {
  .product-list {
    .img-container {
      width: 33.333%;
      padding: 5px 6px;
    }
  }
}

@media screen and (max-width: 600px) {
  .product-list {
    .img-container {
      width: 50%;
      padding: 5px 6px;
    }
  }
}
