.table-section {
  margin-top: 20px;
  background: #ffff;
  padding: 10px 18px;

  .input-field-outline {
    width: 150px !important;
  }
  .lightbtn {
    text-transform: inherit !important;
    font-weight: 500 !important;
  }
  .add-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;
  }

  .pagination {
    margin-top: 0px;
  }

  .heading-name {
    color: #060707;
    white-space: initial;
    padding-bottom: 10px;
    font-weight: 100 !important;
    font: -webkit-mini-control;
    opacity: 0.999;
    white-space: normal;
  }

  .product-img {
    height: 50px;
    width: 145px;
    margin: 10px 0px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .fileuploadsection .attach-btn {
    margin: 0;
    padding: 5px 8px 8px;
    min-width: 100px;
    font-size: 17px;
  }
  .dropdown .select-field {
    background: url(../../assets/images/sellerSide/dropdown.webp) 96%/5% no-repeat;
    height: 38px;
  }
  .MuiOutlinedInput-input {
    padding: 12.5px 14px 8.5px 0px;
  }
  .input-field-outline {
    height: 38px;
  }
  .fileuploadsection .plusicon {
    font-size: 20px;
    margin-left: 0;
  }

  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }

  td,
  th {
    border-bottom: 1px solid #dddddd;
    text-align: left;
    padding: 11px 8px;
  }

  .darkbtn {
    background-color: #6a6e6a !important;
    display: block;
    margin-bottom: 10px;
    background-color: #6a6e6a !important;
    display: block;
    margin-bottom: 10px;
    font-weight: 200 !important;
    font-size: 14px !important;
    border-radius: 6px !important;
    padding: 5px 19px !important;
  }
}
