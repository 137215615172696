.business-product-details {
  margin-top: 5.972vw;
  .left-arrow-new {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.833vw;
    .arrow-back {
      margin-left: 4.166vw;
      width: 2.15vw;
      height: 2.51vw;
      cursor: pointer;
    }
  }

  .main-heading {
    font-size: 26px;
    line-height: 30px;
    font-family: $asap-font-regular;
    font-style: italic;
    font-weight: 400;
    margin-bottom: 0;
    opacity: 1;
    margin-left: -5.5vw;
  }
  .recommended-para {
    font-size: 18px;
    line-height: 24px;
    font-family: $asap-font-regular;
    color: #757061;
    text-align: center;
    margin-bottom: 2.708vw;
  }
  .product-img-section {
    width: 70%;
    margin: 0 auto;
  }
  .img-section {
    text-align: center;
  }
  .img-container {
    display: inline-block;
    height: auto;
    padding: 5px 10px;
    position: relative;
    width: 25%;
    margin-bottom: 4vw;
    vertical-align: top;
  }
  .productscard .product-img {
    width: 100%;
    height: 6.2vw;
    min-width: 120px;
    object-fit: cover;
    background-color: #d9d8f5;
  }
  .product-heading {
    font-size: 16px;
    line-height: 18px;
    font-family: $asap-font-regular;
    font-weight: 500;
    text-align: center;
    margin: 0;
    margin-bottom: 0.5vw;
    opacity: 1;
  }
  .product-para {
    font-size: 14px;
    line-height: 16px;
    font-family: $asap-font-regular;
    font-weight: 400;
    text-align: center;
    margin: 0;
    width: 80%;
    margin: 0 auto;
  }
  .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked,
  .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
    color: #306ff6 !important;
    // background: #fff;
    border-radius: 50%;
    width: 1em;
    left: 15px;
    top: 10px;
    height: 1em;
  }
  .checkbox-style {
    position: absolute;
    top: 10px;
    left: 12px;
    // color: #fff;
    color: #306ff6 !important;
    padding: 0 !important;
  }
  .lightbtn {
    min-width: 96.5px !important;
  }
  .btn-section {
    margin-top: 35px;
    justify-content: space-between;
    flex-direction: row;
    box-sizing: border-box;
    display: flex;
  }
}

@media screen and (min-width: 1930px) and (max-width: 3000px) {
  .business-product-details .product-img-section {
    width: 65%;
  }
  .business-product-details .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked,
  .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
    left: 20px;
    top: 15px;
  }
}


@media only screen and (max-width: 600px) {
  .business-product-details {
    margin-top: 2vw;
    .product-img-section {
      width: 90%;
      margin: 0 auto;
    }
    .img-container {
      width: 50%;
      margin-bottom: 7vw;
    }
    .product-para {
      width: 100%;
    }
    .productscard .product-img {
      height: 16vw;
    }
    .main-heading {
      font-size: 5.81944vw;
      line-height: 6.083vw;
      margin-left: 0;
    }
    .recommended-para {
      font-size: 4.25vw;
      line-height: 5.59vw;
      margin-bottom: 5.708vw;
      margin-top: 2vw;
    }
    .left-arrow-new {
      align-items: inherit;
      flex-direction: column;
      .arrow-back {
        margin-left: 1.166vw;
        margin-bottom: 5vw;
        width: 31px;
        height: 31px;
      }
    }
  }
  .img-container {
    width: 50%;
    margin-bottom: 7vw;
  }
  .product-para {
    width: 100%;
  }
  .productscard .product-img {
    height: 16vw;
  }
  .main-heading {
    font-size: 5.81944vw;
    line-height: 6.083vw;
    margin-left: 0;
  }
  .recommended-para {
    font-size: 4.25vw;
    line-height: 5.59vw;
    margin-bottom: 5.708vw;
    margin-top: 2vw;
  }
  .left-arrow-new {
    align-items: inherit;
    flex-direction: column;
    .MuiSvgIcon-root {
      margin-left: 1.166vw;
      margin-bottom: 5vw;
    }
  }
}

@media (min-width: 601px) and (max-width: 900px) {
  .business-product-details {
    .left-arrow-new {
      margin-bottom: 1.833vw;
    }

    .recommended-para {
      font-size: 2.25vw;
      line-height: 2.59vw;
      margin-bottom: 3.708vw;
    }
    .main-heading {
      font-size: 2.81944vw;
      line-height: 3.5vw;
    }
    .productscard .product-img {
      height: 7.2vw;
    }
    .img-container{
      width: 33.33333%;
    }
  }
}
