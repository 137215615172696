.search-engine-section {
  padding: 3.25rem 3rem 1.75rem;
  background: #f8fafd;
  border-radius: 24.6203px;
  margin-bottom: 3.563rem;
  margin-right: 16px;

  h4 {
    font-family: 'Inter-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 22.6507px;
    line-height: 32px;
    color: #1f1f1f;
    margin-bottom: 1.25rem;
  }

  .description {
    width: 396px;
    font-family: $asap-font-regular;
    font-style: normal;
    font-weight: 400;
    font-size: 15.757px;
    line-height: 24px;
    color: #44423d;
    margin: 0;
  }

  .input-title {
    margin-top: 3.125rem;

    .meta-info {

      .meta-title,
      .meta-des,
      .meta-keywords {
        display: flex;
        margin-bottom: 2.125rem;

        p {
          width: 143px;
          font-family: $asap-font-regular;
          font-style: normal;
          font-weight: 500;
          font-size: 15.757px;
          line-height: 18px;
          color: #44423d;
        }

        .dropdown {
          .MuiBox-root {
            width: 700px;

            .select-field {
              background-color: #fff;
              background-position-y: center;

              .MuiSelect-select {
                min-height: 0;
              }
            }
          }
        }
      }

      .meta-keywords {
        margin-bottom: 0;
      }

      .input-width,
      .main-textarea {
        width: 700px;
      }

      .main-textarea {
        .input-textarea {
          width: 100%;
        }
      }

      .input-field-outline,
      .input-textarea {
        border: 1px solid #d8d4cf !important;
        margin-bottom: 0;
        margin-top: 0;

        &::placeholder {
          color: #a6a4a0 !important;
        }
      }

      .input-field-outline {
        padding-bottom: 15px;
      }

      .single-img-upload {
        margin-top: 1.6vw;
      }
    }
  }
}

@media screen and (min-width: 3000px) and (max-width: 6000px) {
  .search-engine-section {
    h4 {
      font-size: 45px;
    }

    .description {
      font-size: 2rem;
      line-height: 2.8rem;
      width: 600px;
    }

    .input-title {
      .meta-info {

        .meta-des,
        .meta-keywords,
        .meta-title {
          p {
            font-size: 2rem;
            line-height: 2.8rem;
            width: 350px;
          }

          .input-width,
          textarea {
            width: 55vw;
          }

          .input-width {
            .input-field-outline {
              height: 80px;
            }
          }

          textarea {
            height: 250px;
          }

          .dropdown .MuiBox-root {
            width: 55vw;
          }


        }
      }
    }
  }

  .search-engine-section .input-title .meta-info .main-textarea {
    width: 55vw;
  }
}

@media screen and (min-width: 1930px) and (max-width: 3000px) {
  .search-engine-section {
    h4 {
      font-size: clamp(22px, 2.5vw, 35px);
    }

    .description {
      font-size: clamp(1.125rem, 1.2vw, 1.8rem);
      line-height: clamp(1.5rem, 1.5vw, 2.3rem);
      width: clamp(394px, 21vw, 534px);
    }

    .input-title {
      .meta-info {

        .meta-des,
        .meta-keywords,
        .meta-title {
          p {
            font-size: clamp(1.125rem, 1.2vw, 1.8rem);
            width: clamp(170px, 13vw, 315px);
          }

          .input-width,
          textarea {
            // width: calc(80vw - 35%);
            width: 55vw;
          }

          .input-width {
            .input-field-outline {
              margin-top: 0px;
              height: clamp(54px, 3vw, 70px);
            }
          }

          textarea {
            margin-top: 0px;
            height: clamp(100px, 9vw, 220px);
          }
        }
      }
    }
  }

  .search-engine-section .input-title .meta-info .main-textarea {
    width: 55vw;
  }

  .search-engine-section {
    .input-title {
      .meta-info {

        .meta-des,
        .meta-keywords,
        .meta-title {
          p {
            line-height: 2.8rem;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1930px) {
  .search-engine-section {
    padding-left: 4rem;
    padding-right: 4rem;

    .input-title {
      .meta-info {

        .meta-des,
        .meta-keywords,
        .meta-title {

          .input-width,
          textarea {
            width: 55vw;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1024px) and (max-width: 1100px) {
  .search-engine-section {
    .input-title {
      .meta-info {
        .meta-title {
          .dropdown {
            width: 700px;

            .MuiBox-root {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 600px) and (max-width: 1024px) {

  .search-engine-section {
    .input-title {
      .meta-info {
        .meta-title {
          .dropdown {
            width: 50vw;

            .MuiBox-root {
              width: 100%;
            }
          }
        }

        .input-width,
        .main-textarea {
          width: 50vw;
        }


        .meta-des {
          .dropdown {
            .MuiBox-root {
              width: 50vw;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .search-engine-section {
    margin-right: 0;

    .description {
      width: 100%;
    }

    .input-title {
      .meta-info {

        .meta-title,
        .meta-des,
        .meta-keywords {
          display: flex;
          flex-direction: column;
          row-gap: 1.5rem;

          p {
            width: fit-content;
          }
        }

        .input-width,
        .main-textarea {
          width: 100%;
        }

        .meta-des {
          .dropdown {
            .MuiBox-root {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .search-engine-section {
    margin-right: 0;

    .description {
      width: 100%;
      margin-bottom: 1rem;
    }

    .input-title {
      .meta-info {

        .meta-title,
        .meta-des,
        .meta-keywords {
          display: flex;
          flex-direction: column;
          row-gap: 1.5rem;

          p {
            width: fit-content;
          }
        }

        .input-width,
        .input-textarea {
          width: 100%;
        }
      }
    }
  }

  .search-engine-section .input-title .meta-info .meta-des .dropdown .MuiBox-root {
    width: 100%;
  }
}