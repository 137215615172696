.productscard {
  &:hover {
    .icon-row {
      display: block;
    }
  }

  .img-row {
    width: 100%;
    height: 10.91vw;
    min-height: 150px;
    min-width: 150px;
    margin-bottom: 9px;
    border-radius: 8px;
    object-fit: cover;
  }

  .product-text {
    margin: 0px;
    padding-right: 30px;
    font-family: $asap-font-regular;
    font-weight: 500;
    font-style: normal;
    font-size: 14px;
    color: #2f2b23;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 160px;
  }

  .copy-icon,
  .delete-icon,
  .edit-icon {
    cursor: pointer;
    height: 24px;
    width: 24px;
    color: #2f2b23;
  }

  .delete-icon {
    margin-right: 8px;
  }

  .product-text-section {
    display: flex;
    justify-content: space-between;
  }

  .product-price {
    font-family: $asap-font-regular;
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    line-height: 130%;
    padding-left: 6px;
    color: #2f2b23;
  }

  .discount-price {
    text-decoration: line-through;
    opacity: 0.5;
  }

  .icon-row {
    display: none;
  }

  .iconhover {
    display: flex;
  }
}

.img-container {
  display: inline-block;
  height: auto;
  padding: 5px 6px;
  position: relative;
  width: 25%;

  .product-text-section {
    .hover-icon {
      display: flex;
      column-gap: 15px;
    }
  }
}

@media (min-width: 280px) and (max-width: 600px) {
  .img-container {
    width: 50%;
  }
  .productscard .edit-icon{
    display: none;
  }
  .productscard {
    .img-row {
      width: 100%;
      height: 38vw;
      min-width: 100%;
      max-width: 100%;
    }
  }
}

@media (min-width: 601px) and (max-width: 919px) {
  .img-container {
    width: 33.333%;
  }
  .productscard {
    .img-row {
      width: 100%;
      height: 16vw;
      min-width: 100%;
      max-width: 100%;
    }
  }

}

@media (min-width: 250px) and (max-width: 650px){
  .img-container {
    width: 50%;
  }
}

@media (min-width: 650px) and (max-width: 919px) {
  .img-container {
    width: 33.333%;
  }
}

@media (min-width: 920px) and (max-width: 1280px) {
  .productscard {
    .product-text {
      width: 130px;
      padding-right: 10px;
    }
  }
}

@media (min-width: 2000px) and (max-width: 6000px) {
  .productscard {

    .copy-icon,
    .delete-icon,
    .edit-icon {
      cursor: pointer;
      height: 1.4vw;
      width: 1.4vw;
      color: #2f2b23;
    }

    .product-text {
      font-size: 38px;
    }
  }
}