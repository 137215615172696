.orderinvoice {
  padding-top: 130px;
  padding-bottom: 130px;
  .btn-section {
    display: flex;
  }
  .darkbtn {
    color: #fff !important;
    background-color: #428bca !important;
    border: 1px solid transparent !important;
    padding: 10px 12px !important;
    font-size: 14px !important;
    line-height: 1.428571429vw !important;
    border-radius: 4px !important;
    margin-right: 20px !important;
  }
  .sub-heading {
    font-size: 22px;
    line-height: 24px;
  }
  .salesinvoice {
    padding: 0px 20px;
  }
  .sales-name-section {
    display: flex;
    justify-content: space-between;
    margin: 30px 0px 20px 0px;
    //   margin-top: 30px;
    padding-bottom: 20px;
    border-bottom: 2px solid rgb(204, 204, 204);
  }
  .sales-name {
    font-size: 35px;
    line-height: 40px;
    color: #333;
    font-weight: bold;
  }
  .seller-info {
    text-align: end;
  }
  p {
    margin: 0;
    font-size: 18px;
    line-height: 28px;
  }
  .info {
    font-size: 16px;
    line-height: 18px;
    margin-bottom: 15px;
  }
  .table-section {
    margin-top: 30px;
    width: 100%;
    border: 1px solid #dddd;
    padding: 0px;
    background: #faf9f9;
  }
  .order-section {
    border: 2px solid rgba(221, 221, 221, 0.867);
    width: 100%;
    background-color: rgba(221, 221, 221, 0.867);
    flex-direction: row;
    box-sizing: border-box;
    display: flex;
  }
  .order-text {
    margin: 0px;
    padding: 10px;
    font-size: 28px;
    color: #333;
    line-height: 32px;
  }
  table {
    width: 100%;
  }
  table th {
    text-align: left;
    padding: 10px 15px 10px 20px;
    border-bottom: 1px solid #dddd;
  }
  table td {
    text-align: left;
    padding: 10px 15px 10px 20px;
  }
  .borderline {
    border-bottom: 1px solid #dddd;
  }
}
@media (min-width: 280px) and (max-width: 600px) {
  .orderinvoice {
    .sales-name {
      font-size: 24px;
    }
    .seller-info {
      text-align: left;
    }
    p {
      margin: 0;
      font-size: 15px;
      line-height: 28px;
    }
    .order-text {
      font-size: 22px;
      line-height: 26px;
    }
    table th,
    table td {
      font-size: 16px;
    }
    .table-section {
      overflow: auto;
    }
    .darkbtn {
      line-height: 18px !important;
    }
  }
}

@media (min-width: 2000px) and (max-width: 6000px) {
  .orderinvoice {
    padding-top: 6.5vw;
    .darkbtn {
      border-radius: 0.5vw !important;
      padding: 0.4vw 0.8vw !important;
      font-size: 0.8vw !important;
      font-weight: normal !important;
    }
    .sales-name {
      font-size: 1.4vw;
      line-height: 1.8vw;
    }
    .sub-heading {
      font-size: 0.9vw;
      line-height: 1.2vw;
    }
    p {
      font-size: 0.8vw;
      line-height: 1.2vw;
    }
    .info {
      font-size: 0.8vw;
      line-height: 1.2vw;
      margin-bottom: 0.6vw;
    }
    .order-text {
      margin: 0px;
      padding: 0.6vw;
      font-size: 1.2vw;
      color: #333;
      line-height: 1.4vw;
    }
    table th,
    table td {
      padding: 0.6vw 0.8vw;
      font-size: 1vw;
    }
  }
}
