@import '../abstracts/variables';

.sellerfooter {
  background: #ffffff;
  height: 156px;
  padding-top: 40px;

  .footer-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: auto;
    width: 80%;
  }

  .logo-img {
    height: 51px;
    width: 206px;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
  }

  .link-section {
    display: flex;
  }

  .privacy-link {
    cursor: pointer;
    padding-left: 14px;
    margin-left: 32px;
    color: #2f2b23;
    font-size: 16px;
    font-weight: 300;
    font-family: 'asap';
  }

  .icon-section {
    display: flex;
  }

  .icon-img {
    width: 22px;
    height: 22px;
    margin-left: 28px;
    cursor: pointer;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
  }
}

@media (min-width: 280px) and (max-width: 919px) {
  .sellerfooter {
    height: auto;
    padding-top: 40px;
    width: 95%;
    margin: auto;
    margin-bottom: 25px;

    .footer-section {
      flex-direction: column !important;
      width: 100%;
    }

    .icon-section {
      display: flex;
      margin-top: 20px !important;
      margin: auto;
    }

    .link-section {
      display: flex;
      margin-top: 20px;
    }
    .privacy-link {
      margin-left: 12px;
      padding-left: 0px;
    }
  }
}

@media (min-width: 1921px) and (max-width: 6000px) {
  .sellerfooter {
    .footer-section {
      width: 68%;
      margin: 0 auto;
    }
    .logo-img {
      height: 3vw;
      width: 12vw;
    }
    .privacy-link {
      font-size: 1.04vw;
      padding-left: 0.781vw;
    }
    .icon-img {
      width: 1.4vw;
      height: 1.4vw;
      margin-left: 1.5vw;
    }
  }
}
