.notfound {
  padding-top: 180px;
  width: 80%;
  margin: auto;
  text-align: center;
  padding-bottom: 250px;

  p {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .btn-section {
    display: flex;
    justify-content: center;
  }
  .blackbtn {
    margin-top: 35px !important;
    padding: 2px 25px !important;
  }
}
