.nointernet {
  padding-top: 180px;
  width: 600px;
  margin: auto;
  text-align: center;
  padding-bottom: 250px;

  p {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .lightbtn {
    margin-top: 65px !important;
    padding: 10px 25px !important;
  }
}
