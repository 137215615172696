.sellerorderdetails {
  // background-color: rgb(250, 249, 249);
  padding-bottom: 60px;
  margin-top: 20px;
  // .sellerorderdetails-container{
  //     padding-right: 12%;
  // }

  .main-heading40 {
    margin-top: 52px;
  }

  .css-ja5taz-MuiTooltip-tooltip {
    background-color: #67757c !important;
  }

  .last-btn-section {
    gap: 15px;

    .lightbtn {
      margin-right: 0 !important;
    }
  }

  .lightbtn {
    font-weight: 500 !important;
    text-transform: inherit !important;
  }

  .order-para {
    font-family: $asap-font-regular;
    font-style: normal;
    font-size: 16px;
    font-weight: normal;
    color: #757061;
    margin-bottom: 18px;
    padding-right: 9vw;
  }

  .deliveredbtn {
    border-radius: 4px;
    color: #ffffff;
    padding: 4px 15px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    text-transform: capitalize;
  }

  .data-container {
    // border: 1px solid #d8d4cf;
    border-radius: 8px;
    padding: 24px 16px;
    margin-bottom: 16px;
    background: #f8fafd;
  }

  .ordernumber {
    font-family: $asap-font-regular;
    font-style: normal;
    font-size: 16px;
    font-weight: normal;
    color: #2f2b23;
    margin-bottom: 16px;
    margin-top: 16px;
    padding-right: 26px;
  }

  .orderid {
    font-family: $asap-font-regular;
    font-style: normal;
    font-size: 16px;
    font-weight: normal;
    color: #2f2b23;
    margin-bottom: 16px;
    margin-top: 16px;
    -ms-word-break: break-all;
    word-break: break-all;
    word-break: break-word;

    span {
      font-size: 18px;
      line-height: 20px;
    }
  }

  table {
    width: 100%;
    border-collapse: collapse;
  }

  .table-container {
    // border: 1px solid #d8d4cf;
    border-radius: 8px;
    margin-bottom: 24px;
    background: #f8fafd;
    padding: 10px 0px;

    .tabel-order-btn {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 10px;

      div {
        width: 100%;

        .lightbtn,
        .darkbtn {
          width: 100%;
        }
      }
    }
  }

  .table-heading th {
    font-family: $asap-font-regular;
    font-style: normal;
    font-size: 14px;
    font-weight: normal;
    color: #2f2b23;
    padding: 10px 16px;
    text-align: left;
  }

  .seller-order-table {
    .table-data {
      td {
        white-space: nowrap;
        word-break: inherit;
        div{
          word-break: inherit;
          white-space: nowrap;
        }
        button{
          word-break: inherit;
          white-space: nowrap;
        }
      }
    }
  }

  .table-data2,
  .table-price {
    padding-top: 0 !important;
  }

  .borderadd {
    border-bottom: 1px solid #d8d4cf;
  }

  .table-data {
    border-bottom: 1px solid #d8d4cf;
  }

  .table-data td {
    font-family: $asap-font-regular;
    font-style: normal;
    font-size: 16px;
    font-weight: normal;
    color: #2f2b23;
    padding: 16px;
    -ms-word-break: break-all;
    word-break: break-all;
    word-break: break-word;
  }

  .table-data2,
  .table-price {
    font-family: $asap-font-regular;
    font-style: normal;
    font-size: 16px;
    font-weight: normal;
    color: #2f2b23;
    line-height: 130%;
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .table-price {
    text-align: end;
    padding-right: 30px;
    font-weight: 600;
    word-break: break-all;
  }

  // tr.table-heading {
  //     border-bottom: 1px solid #CCC;
  // }
  .textend {
    text-align: end;
  }

  .second-table {
    padding: 16px;
    margin-top: 16px;
  }

  .second-table-bg {
    background-color: #ffffff;
    border-radius: 8px;
  }

  .last-btn-section {
    display: flex;
    justify-content: flex-end;
  }
}

.sellerorderdetails {
  .ordernumber {
    display: flex;
    gap: 5px;
    align-items: center;
  }
}

@media screen and (max-width: 600px) {
  .sellerorderdetails {
    .last-btn-section {
      flex-direction: column;
      width: 100%;

      .last-btn-section-container {
        width: 100%;

        .lightbtn,
        .darkbtn {
          width: 100% !important;
        }
      }
    }
  }
}

@media (min-width: 600px) and (max-width: 920px) {
  .sellerorderdetails {
    .welcominnercard {
      margin-left: 16px;
    }
  }
}

@media (min-width: 280px) and (max-width: 600px) {
  .sellerorderdetails {
    .sellerorderdetails-container {
      padding-right: 0px;
    }

    .table-container {
      table {
        width: 500px;
        overflow-x: auto;
      }

      .second-table {
        width: 500px;
        overflow-x: auto;
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 912px) {
  .sellerorderdetails {
    .sellerorderdetails-container {
      padding-right: 0px;
    }
  }
}

@media (min-width: 2000px) and (max-width: 6000px) {
  .sellerorderdetails {
    .sellerorderdetails-container {
      padding-right: 0px;
    }

    .main-heading40 {
      font-size: 1.5vw;
      line-height: 1.7;
    }

    .order-para {
      font-size: 0.8vw;
      line-height: 1.2;
    }

    .sub-heading {
      font-size: 1vw;
      line-height: 1.2;
    }

    .ordernumber,
    .orderid,
    .orderid span {
      font-size: 0.9vw;
      line-height: 1.2;
    }

    .data-container {
      border-radius: 0.4vw;
      padding: 1vw;
      margin-bottom: 1.5vw;
    }

    .cancelbtn {
      border-radius: 0.2vw !important;
      padding: 0.5vw 0.8vw !important;
      font-size: 0.8vw !important;
    }

    .table-heading th,
    .table-data td {
      font-size: 0.8vw;
      padding: 0.8vw;
    }

    .table-data2,
    .table-price {
      font-size: 0.9vw;
      line-height: 1.2;
    }

    .lightbtn,
    .darkbtn {
      border-radius: 0.5vw !important;
      padding: 0.4vw 0.8vw !important;
      font-size: 0.8vw !important;
    }
  }
}