// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------

.darkbtn {
  background-color: #949d8c !important;
  border-radius: 8px !important;
  text-transform: inherit !important;

  padding: 8px 16px !important;
  font-family: 'Asap-Regular' !important;
  font-weight: 500 !important;
  font-style: normal !important;
  font-size: 14px !important;
  color: #ffffff !important;
  border: none !important;
  box-shadow: none !important;
}

.disabledBtn {
  background-color: #c4c9c0 !important;
  border-radius: 8px !important;
  text-transform: inherit !important;
  padding: 8px 16px !important;
  font-family: 'Asap-Regular' !important;
  font-weight: 500 !important;
  font-style: normal !important;
  font-size: 14px !important;
  color: #ffffff !important;
  border: none !important;
  box-shadow: none !important;
}

.subscription-disabled-btn {
  border-radius: 25px !important;
  cursor: auto !important;
  align-items: center;
  gap: 5px;
}

// .btn-section{
//   display: flex;
//   justify-content: center;
// }
.lightbtn {
  font-weight: 600 !important;
  font-family: 'Asap-Regular' !important;
  font-style: normal !important;
  font-size: 14px !important;
  border: none !important;
  padding: 8px 16px !important;
  border-radius: 10px !important;
  background-color: #d3cec0 !important;
  color: #2f2b23 !important;
  border: none !important;
  margin-right: 8px !important;
  text-transform: inherit !important;
  box-shadow: none !important;
}

.bluebtn {
  font-weight: 200 !important;
  font-family: 'Asap-Regular' !important;
  font-style: normal !important;
  font-size: 14px !important;
  border: none !important;
  padding: 8px 16px !important;
  border-radius: 11px !important;
  background-color: #2559c9 !important;
  color: #fff !important;
  border: none !important;
  margin-right: 8px !important;
  text-transform: inherit !important;
  box-shadow: none !important;
}

.singleproductbtndrk {
  background-color: #949d8c !important;
  color: #fff !important;
}

.sinngle-disable-btn {
  background-color: #c4c9c0 !important;
  color: #fff !important;
}

.blackbtn {
  background: #363240 !important;
  border-radius: 40px !important;
  opacity: 1 !important;
  min-width: 153px !important;
  min-height: 55px !important;
  // padding: 1vw 1.6vw !important;
  // width: 7.986vw !important;
  // height: 2.708vw !important;
  text-align: center !important;
  font-size: 18px !important;
  line-height: 20px !important;
  font-family: 'Asap-Regular' !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  letter-spacing: 0px !important;
  color: #ffffff !important;
  opacity: 1 !important;
  border: none !important;
  cursor: pointer !important;
  margin-bottom: 30px !important;
  box-shadow: none !important;
}

.Orangebtn {
  background: #d75d4c !important;
  border-radius: 32px !important;
  opacity: 1 !important;
  min-width: 153px !important;
  min-height: 55px !important;
  width: 241px !important;
  height: 64px !important;
  text-align: center !important;
  font-size: 18px !important;
  line-height: 25px !important;
  font-family: 'Inter-Medium' !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-variant: normal !important;
  letter-spacing: 0px !important;
  color: #ffffff !important;
  border: none !important;
  cursor: pointer !important;
  box-shadow: none !important;
}

.unp-ofs {
  padding: 4px 8px !important;
  /* width: 72px !important; */
  /* height: 18px !important; */
  font-size: 12px !important;
  border-radius: 4px !important;
  font-weight: 400 !important;
  line-height: 14px !important;
}

.unpublishbtn {
  background: #2f2b23 !important;
}

.outofstock {
  background: #c04d4d !important;
  z-index: 1;
  text-transform: capitalize !important;
  color: #ffffff !important;
  letter-spacing: 0px !important;
  box-shadow: none !important;
  text-transform: capitalize !important;
  border-radius: 0px !important;
  width: -moz-fit-content;
  width: fit-content;
  position: absolute;
  top: 15px;
  right: 14px;
  padding: 5px;
  border-radius: 4.30424px !important;
  font-family: 'Asap-Regular';
  font-weight: normal;
  font-style: normal;
  font-size: 12px;
  line-height: 14px;
}

.orderbtn3 {
  border-radius: 4px !important;
  font-weight: 400 !important;
  line-height: 16px !important;
  padding: 8px 6px !important;
  /* width: 72px !important; */
  /* height: 18px !important; */
  font-size: 14px !important;
}

.pendingbtn {
  background: #70a5ca !important;
  width: fit-content;
  padding: 2px 10px;
  color: #fff !important;
  border-radius: 4px;
  font-family: 'Asap-Regular';
}

.deliveredbtn {
  background: #7aa56f !important;
  width: fit-content;
  padding: 2px 10px;
  color: #fff !important;
  border-radius: 4px;
  font-family: 'Asap-Regular';
}

.cancelbtn {
  background: #c04d4d !important;
  width: fit-content;
  padding: 2px 10px;
  color: #fff !important;
  border-radius: 4px;
  font-family: 'Asap-Regular';
}

.partialbtn {
  background: #a6a4a0 !important;
  width: fit-content;
  padding: 2px 10px;
  color: #fff !important;
  border-radius: 4px;
  font-family: 'Asap-Regular';
}

@media only screen and (min-width: 1921px) {
  .outofstock {
    font-size: 0.8vw !important;
    line-height: 1.2vw !important;
    border-radius: 0.3vw !important;
    padding: 0.2vw 0.4vw !important;
  }

  .blackbtn {
    min-width: 8.5vw !important;
    min-height: 3.2vw !important;
    text-align: center !important;
    font-size: 0.937vw !important;
    line-height: 1.04vw !important;
    border-radius: 2.5vw !important;
  }

  //     .darkbtn{
  //         min-width: 6.5vw !important;
  //         min-height: 3.2vw !important;
  //         text-align: center !important;
  //         font-size: 0.937vw !important;
  //         line-height: 1.04vw !important;
  //         border-radius: 0.56vw !important;
  //     }
  //     .lightbtn{
  //         min-width: 6.5vw !important;
  //         min-height: 3.2vw !important;
  //         text-align: center !important;
  //         font-size: 0.937vw !important;
  //         line-height: 1.04vw !important;
  //         border-radius: 0.56vw !important;
  // }
  // }
}