.side-bar-padd{

  padding-left: 32px !important;
  padding-right: 16px;

  .MuiButtonBase-root{
    padding-left: 0px;
  }

}

.main-accordion {
  .MuiPaper-root {
    width: fit-content;
    border: none;
    outline: none;
    border-radius: 0 !important;
    box-shadow: none !important;
    background-color: transparent !important;
    background: transparent !important;

    .MuiButtonBase-root {
      min-height: 0 !important;
      padding: 0 !important;
      width: 100%;
      justify-content: flex-start;

      .MuiAccordionSummary-content {
        margin: 0 !important;
        display: flex;
        flex-direction: column;
        align-items: center;

        .MuiTypography-root {
          color: #444746 !important;
          font-size: 0.867rem !important;
          font-family: $asap-font-regular !important;
          font-weight: 500 !important;
          font-style: normal !important;
          line-height: 1.127rem !important;
        }
      }

      .MuiAccordionSummary-expandIconWrapper {
        display: none !important;
      }
    }
  }

  .MuiCollapse-root {
    .MuiAccordionDetails-root {
      padding: 0 !important;
      padding-top: 1.2vw !important;

      ul {
        list-style: none;
        margin: 0 !important;
        text-align: center;
        padding: 0 !important;

        li {
          // width: 80px;
          // width: 6vw;
          width: 6.2vw;
          padding: 10px 0;
          border-bottom: 1px solid #444746;

          a {
            color: #444746;
            text-transform: capitalize;
            // font-size: 14px;
            font-size: 0.85vw;
            word-wrap: break-word;
          }
        }
      }
    }
  }

  .sub-accordion {
    .MuiPaper-root {
      .MuiButtonBase-root {
        .MuiAccordionSummary-content {
          .sub-accordion-text {
            .MuiTypography-root {
              font-size: 14px !important;
              line-height: 22px !important;
            }
          }
        }
      }

      .MuiCollapse-root {
        .MuiCollapse-wrapper {
          .MuiCollapse-wrapperInner {
            .MuiAccordion-region {
              .MuiAccordionDetails-root {
                padding-top: 0 !important;

                ul {
                  width: 100%;

                  .item2 {
                    width: 100%;

                    a {
                      background: #dae4f1;
                      width: 100%;
                      padding: 5px 10px;
                      border-radius: 5px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.main-accordion .MuiCollapse-root .MuiAccordionDetails-root ul li:last-child {
  border: none;
}

@media screen and (min-width: 2000px) and (max-width: 3000px) {
  .main-accordion {
    .MuiCollapse-root {
      .MuiAccordionDetails-root {
        ul {
          li {
            width: 6vw;
            margin: 0.4vw 0vw;
            padding: 0.7vw 0;
            margin-right: 0 !important;

            a {
              font-size: 0.8vw;
            }
          }
        }
      }
    }

    .sub-accordion {
      .MuiPaper-root {
        .MuiButtonBase-root {
          .MuiAccordionSummary-content {
            .sub-accordion-text {
              .MuiTypography-root {
                font-size: 0.8vw !important;
                line-height: 1.2vw !important;

              }
            }
          }

          .MuiCollapse-root {
            .MuiCollapse-wrapper {
              .MuiCollapse-wrapperInner {
                .MuiAccordion-region {
                  .MuiAccordionDetails-root {
                    ul {
                      .item2 {
                        width: 7.5vw;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 3000px) and (max-width: 6000px) {
  .main-accordion {
    .MuiCollapse-root {
      .MuiAccordionDetails-root {
        ul {
          li {
            width: 7.5vw;

            a {
              font-size: 1vw;
            }
          }
        }
      }
    }

    .sub-accordion {
      .MuiPaper-root {
        .MuiButtonBase-root {
          .MuiAccordionSummary-content {
            .sub-accordion-text {
              .MuiTypography-root {
                font-size: 1vw !important;
                line-height: 1.127vw !important;
              }
            }
          }
        }

        .MuiCollapse-root {
          .MuiCollapse-wrapper {
            .MuiCollapse-wrapperInner {
              .MuiAccordion-region {
                .MuiAccordionDetails-root {
                  ul {
                    .item2 {
                      width: 7.5vw;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

}

@media screen and (min-width: 1100px) and (max-width: 1280px) {
  .main-accordion {
    .MuiCollapse-root {
      .MuiAccordionDetails-root {
        ul {
          li {
            width: 5.7vw;

            a {
              font-size: 1vw;
            }
          }
        }
      }
    }

    .sub-accordion {
      .MuiPaper-root {
        .MuiButtonBase-root {
          .MuiAccordionSummary-content {
            .sub-accordion-text {
              .MuiTypography-root {
                font-size: 1vw !important;
                line-height: 1.127vw !important;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 921px) and (max-width: 1100px) {
  .main-accordion .MuiCollapse-root .MuiAccordionDetails-root ul li a {
    font-size: 1.2vw;
}
}

@media screen and (max-width: 919px) {
  .main-accordion {
    width: 100%;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 16px;
    padding-right: 16px;

    .MuiPaper-root {
      width: 100%;
      padding-top: 8px;
      padding-bottom: 8px;
      padding-left: 16px;
      padding-right: 0px;

      .MuiButtonBase-root {
        .MuiAccordionSummary-content {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: start;

          .MuiTypography-root {
            color: #444746 !important;
            font-size: 0.867rem !important;
            font-family: $asap-font-regular !important;
            font-weight: 500 !important;
            font-style: normal !important;
            line-height: 1.127rem !important;
          }
        }

        .MuiAccordionSummary-expandIconWrapper {
          display: none !important;
        }
      }
    }

    .MuiCollapse-root {

      .MuiAccordionDetails-root {
        padding: 0 !important;
        padding-top: 1.2vw !important;

        ul {
          list-style: none;
          margin: 0 !important;
          text-align: center;
          padding: 0 !important;

          li {
            width: 100%;

            .MuiButtonBase-root {
              a {
                display: flex;
                align-items: center;
              }
            }

            button {
              color: #444746;
              text-transform: capitalize;
              width: 80px;
            }
          }
        }
      }

      .MuiCollapse-wrapper {
        .MuiCollapse-wrapperInner {
          .MuiAccordion-region {
            .MuiAccordionDetails-root {
              ul {
                .MuiListItem-root {
                  .sub-accordion {
                    .MuiPaper-root {
                      padding-left: 0;

                      .MuiButtonBase-root {
                        .MuiAccordionSummary-content {
                          .sub-accordion-text {
                            p {
                              font-size: 16px !important;
                              font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
                              font-weight: 400 !important;
                            }
                          }
                        }
                      }

                      .MuiCollapse-root {
                        margin-left: 10px;

                        .MuiCollapse-wrapper {
                          .MuiCollapse-wrapperInner {
                            .MuiAccordion-region {
                              .MuiAccordionDetails-root {
                                ul {
                                  .MuiButtonBase-root {
                                    a {
                                      background: #dae4f1;
                                      width: 100%;
                                      padding: 5px 10px;
                                      border-radius: 5px;
                                    }

                                    &:last-child {
                                      border: none;
                                      padding-bottom: 0 !important;
                                    }

                                    padding: 10px 0 !important;
                                    border-bottom: 1px solid;
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .main-accordion {
    width: 100%;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 16px;
    padding-right: 16px;

    .MuiPaper-root {
      width: 100%;
      padding-top: 8px;
      padding-bottom: 8px;
      padding-left: 16px;
      padding-right: 16px;

      .MuiButtonBase-root {
        .MuiAccordionSummary-content {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: start;

          .MuiTypography-root {
            color: #444746 !important;
            font-size: 0.867rem !important;
            font-family: $asap-font-regular !important;
            font-weight: 500 !important;
            font-style: normal !important;
            line-height: 1.127rem !important;
          }
        }

        .MuiAccordionSummary-expandIconWrapper {
          display: none !important;
        }
      }
    }

    .MuiCollapse-root {

      .MuiAccordionDetails-root {
        padding: 0 !important;
        padding-top: 1.2vw !important;

        ul {
          list-style: none;
          margin: 0 !important;
          text-align: center;
          padding: 0 !important;

          li {
            width: 100%;

            .MuiButtonBase-root {
              a {
                display: flex;
                align-items: center;
              }
            }

            button {
              color: #444746;
              text-transform: capitalize;
              width: 80px;
            }
          }
        }
      }

      .MuiCollapse-wrapper {
        .MuiCollapse-wrapperInner {
          .MuiAccordion-region {
            .MuiAccordionDetails-root {
              ul {
                .MuiListItem-root {
                  .sub-accordion {
                    .MuiPaper-root {
                      padding-left: 0;

                      .MuiButtonBase-root {
                        .MuiAccordionSummary-content {
                          .sub-accordion-text {
                            p {
                              font-size: 16px !important;
                              font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
                              font-weight: 400 !important;
                            }
                          }
                        }
                      }

                      .MuiCollapse-root {
                        margin-left: 10px;

                        .MuiCollapse-wrapper {
                          .MuiCollapse-wrapperInner {
                            .MuiAccordion-region {
                              .MuiAccordionDetails-root {
                                ul {
                                  .MuiButtonBase-root {
                                    a {
                                      background: #dae4f1;
                                      width: 100%;
                                      padding: 5px 10px;
                                      border-radius: 5px;
                                    }

                                    &:last-child {
                                      border: none;
                                      padding-bottom: 0 !important;
                                    }

                                    padding: 10px 0 !important;
                                    border-bottom: 1px solid;
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

