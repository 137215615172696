.news-letter,
.signup-letter {
  margin-top: 2rem;
  margin-bottom: 2.5rem;
  .welcominnercard {
    margin-bottom: 36px !important;
    padding: 5.278vw 3.889vw;
    min-height: 0;
    .MuiGrid-root {
      .MuiGrid-root {
        padding-top: 0 !important;
        .welcome-new-text {
          font-family: 'Inter-Medium';
          font-weight: 500;
        }
        .welcome-para {
          font-weight: 400 !important;
          font-family: 'Inter-Regular' !important;
        }
      }
    }
  }
  .welcomecard {
    margin-bottom: 36px !important;
    padding: 5.278vw 3.889vw;
    min-height: 0;
    .MuiGrid-root {
      .MuiGrid-root {
        padding-top: 0 !important;
        .welcome-new-text {
          font-family: 'Inter-Medium';
          font-size: 4.513vw;
          line-height: 1.4;
          color: #1f1f1f;
          margin: 0;
          font-weight: 500;
        }
        .welcome-para {
          font-weight: 400 !important;
          font-family: 'Inter-Regular' !important;
          font-size: 1.11vw;
          line-height: 1.45vw;
          padding-right: 17vw;
        }
      }
    }
  }
  .return-btn {
    height: 3.25rem;
    min-width: 8.5rem;
    border-radius: 3.25rem !important;
    font-size: 1.125rem !important ;
    margin-bottom: 2.188rem;
    margin-left: 2.5rem;
    font-family: 'Inter-Medium' !important;
    font-style: normal !important;
    font-weight: 500 !important;
  }
  .buttons {
    width: 100%;
    margin-top: 70px;
    .buttons-position {
      display: flex;
      justify-content: flex-end;
      margin-right: 130px;
      .lightbtn {
        margin-right: 30px !important;
      }
    }
  }
}

@media screen and (min-width: 3000px) and (max-width: 6000px) {
  .seller-side-bg-col {
    flex-basis: 12% !important;
    max-width: 12% !important;
  }
  .dataside {
    flex-basis: 88% !important;
    max-width: 88% !important;
  }
  .news-letter,
  .signup-letter,
  .footer-input,
  .menu-submenu-section {
    .return-btn {
      border-radius: 6rem !important;
      font-size: 1.8rem !important;
      margin-bottom: 4rem;
      height: 4rem;
      min-width: 14.5rem;
    }
    h4 {
      font-size: 48px;
      margin-bottom: 4rem;
    }
    .description {
      font-size: 35px;
      margin-bottom: 4rem;
      width: 950px;
      line-height: 55px;
    }
    .input-title {
      .input-info {
        .input-section,
        .call-action,
        .button-label {
          p {
            width: 320px;
            font-size: 35px;
            line-height: 28px;
          }
        }
      }
    }
    .buttons {
      .buttons-position {
        .lightbtn,
        .bluebtn {
          font-size: 1.016vw !important;
          margin-right: 0.781vw !important;
          padding: 15px 24px !important;
          border-radius: 18px !important;
        }
      }
    }
  }
}

@media screen and (min-width: 1930px) and (max-width: 3000px) {
  .news-letter,
  .signup-letter {
    // padding-left: clamp(20px, 3vw, 60px);
    .return-btn {
      height: clamp(3.25rem, 3.5vw, 5rem);
      min-width: clamp(8.5rem, 11.5vw, 18rem);
      border-radius: 3.25rem !important;
      font-size: clamp(1.125rem, 1.2vw, 1.8rem) !important;
    }
    .buttons {
      .buttons-position {
        .lightbtn,
        .bluebtn {
          font-size: 1.016vw !important;
          margin-right: 0.781vw !important;
        }
      }
    }
  }
}

@media screen and (min-width: 1930px) {
  .signup-letter{
    .main-textarea{
      width: 55vw;
      .input-textarea{
        width: 100% !important;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .news-letter,
  .signup-letter {
    margin: 0 1.5rem;
    margin-bottom: 8vw;
    .welcominnercard {
      margin-left: 0;
      margin-right: 0;
    }
    .return-btn {
      margin-left: 0;
      width: 100%;
    }
    .buttons {
      width: 100%;
      margin-top: 40px;
      .buttons-position {
        margin-right: 0;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .news-letter,
  .signup-letter {
    margin: 0 1.5rem;
    margin-bottom: 8vw;
    .welcominnercard {
      margin-left: 0;
      margin-right: 0;
      padding: 10.6vw 4.8vw 5.6vw;
    }
    .welcomecard {
      margin-left: 0;
      margin-right: 0;
      padding: 10.6vw 4.8vw 5.6vw;
      border-radius: 16px;
      .MuiGrid-root {
        .MuiGrid-root {
          .welcome-new-text {
            font-size: 8.513vw;
            line-height: 9.777vw;
            margin-bottom: 3vw;
          }
          .welcome-para {
            font-size: 4.11vw;
            line-height: 5.45vw;
            padding-right: 0vw;
          }
        }
      }
    }
    .return-btn {
      margin-left: 0;
      width: 100%;
    }
    .buttons {
      width: 100%;
      margin-top: 40px;
      .buttons-position {
        margin-right: 0;
      }
    }
  }
}
