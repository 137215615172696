.FeaturedSection {
  .img-alignment {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 25%;
  }
  .not-found {
    text-align: center;
    padding: 80px 0;
    font-size: 24px;
    color: #757061;
    font-family: Asap;
    font-weight: 400;
  }
  .not-gound-td {
    border-right: 1px solid #ddd;
    border-left: 1px solid #ddd;
  }
  .search-input {
    background: transparent;
    border-bottom: 2px solid #d8d4cf !important;
    border: none;
    border-radius: 0px !important;
    padding-left: 0px !important;
    text-align: left;

    .css-i4bv87-MuiSvgIcon-root,
    .css-96uuyl {
      display: none;
    }

    .MuiInputBase-input {
      padding-left: 0px !important;
    }

    .MuiSvgIcon-root {
      display: none !important;
    }
  }

  .subsection {
    justify-content: space-between;
    flex-direction: row;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    h3 {
      font-family: 'Poppins', sans-serif;
      font-size: 21px;
      font-weight: 300;
      margin: 0;
    }

    h1 {
      line-height: 30px;
      font-size: 23px;
      margin: 0;
      font-family: $asap-font-regular;
      font-weight: 300;
      margin-bottom: 15px;
    }
  }

  .fileuploadsection .attach-btn {
    padding: 8px 23px;
    font-size: 15px;
    line-height: 22px;

    .plusicon {
      margin-left: 41%;
    }
  }

  .dropdownsection {
    // width: 25%;
    padding-bottom: 10px;
  }

  .dropdown .select-field {
    background: url(../../assets/images/sellerSide/dropdown.webp) 96%/5% no-repeat;
    height: 38px;
  }

  .MuiOutlinedInput-input {
    padding: 12.5px 14px 8.5px 0px;
  }

  .label-text {
    color: rgba(0, 0, 0, 0.87);
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    line-height: 20px;
    font-weight: 100;
    opacity: 0.99;
  }

  .product-img {
    height: 85px;
    width: 160px;
    margin: 10px 0;

    img {
      width: 100%;
      height: 100%;
    }
  }

  textarea.input-textarea {
    width: 250px;
    display: block;
  }

  .input-field-outline {
    width: 250px;
  }

  .dropdown {
    width: 250px;
    margin-top: 5px;
  }

  .searchbarsection {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
  }

  .pagination {
    margin-top: 0px;
  }

  table {
    border-collapse: collapse;
    width: 100%;
  }

  td,
  th {
    border-bottom: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
    width: 27%;
  }

  .product-heading {
    color: #060707;
    white-space: initial;
    padding-bottom: 10px;
    font-weight: 100 !important;
    font: -webkit-mini-control;
    opacity: 0.999;
    white-space: normal;
  }

  .motiimg {
    height: 80px;
  }

  .iconvisibility {
    height: 29px;
    width: 30px;
    border-radius: 4px 0px 0px 4px;
    color: #333;
    text-align: center;
    background-color: #e7e7e7;
    border: 1px solid #ccc;
    margin-top: 5px;
    margin-bottom: 4px;
    cursor: pointer;
    padding: 3px;
  }

  .iconvisibility:hover {
    border: 1px solid #444;
  }

  .icondelete:hover {
    border: 1px solid #444;
  }

  .icondelete {
    height: 29px;
    width: 30px;
    border-radius: 0px 4px 4px 0px;
    color: #fff;
    text-align: center;
    background-color: red;
    border: 1px solid #ddd;
    margin-top: 5px;
    margin-bottom: 4px;
    cursor: pointer;

    margin-left: -4px;
  }

  .product-title {
    font-size: 14px;
    color: #444;

    h1 {
      font-size: 24px;
      font-family: 'Poppins', sans-serif;
      font-weight: 500;
      margin: 20px 0 0;
      color: #444;
    }
  }

  .MuiPaginationItem-sizeSmall {
    color: rgba(0, 0, 0, 0.54);
  }

  .pagination {
    color: rgba(0, 0, 0, 0.54);

    p {
      color: rgba(0, 0, 0, 0.54);
    }
  }

  .product-name {
    color: #666;
    width: 100%;
    white-space: initial;
    font-weight: 300;
    opacity: 0.9;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 200;
    font: -webkit-control;

    .seller-name {
      color: #337ab7;
    }
  }

  .pendingbtn {
    background: #367fa9 !important;
    color: #fff;
    border-radius: 3px !important;
    font-size: 14px !important;
    line-height: 16px !important;
    text-transform: capitalize;
    padding: 6px 12px !important;
    margin-right: 20px;
    height: 38px;
    font-weight: normal;
    letter-spacing: 0px;
  }

  .addbtn {
    display: flex;
  }

  .table-container {
    background-color: #ffff;
    margin: 10px 0px 20px 0px;
    height: auto;
    border-top: 3px solid #3c8dbc;
    padding: 0px 20px 10px 20px;
  }
}

@media (min-width: 280px) and (max-width: 600px) {
  .FeaturedSection {
    padding: 15px 0;
    .label-text {
      font-size: 16px;
    }
    .product-img {
      height: 85px;
      width: 130px;
    }
    .searchbarsection {
      display: block;
    }

    .img-alignment {
      display: block;
      padding-right: 0px;
    }

    .pagination {
      margin-bottom: 12px;
    }
  }

  .table-container {
    overflow: auto;
  }
}

@media (min-width: 2000px) and (max-width: 6000px) {
  .FeaturedSection {
    padding: 1vw;

    .table-container td,
    .table-container th {
      font-size: 0.8vw;
      line-height: 1.2vw;
    }

    .motiimg {
      height: 5vw;
    }

    .tablesection {
      margin-top: 2.5vw;
    }

    .product-title {
      font-size: 0.8vw;
      margin: 0.5vw 0;
    }

    .subsection h3,
    .product-title h1 {
      line-height: 1.3vw;
      font-size: 1vw;
    }

    .label-text {
      font-size: 1vw;
      line-height: 1.5vw;
    }

    .orderbtn3 {
      border-radius: 0.5vw !important;
      padding: 0.4vw 0.8vw !important;
      font-size: 0.8vw !important;
      line-height: 1.4vw !important;
    }

    .fileuploadsection .attach-btn {
      min-width: 13vw;
      text-align: left;
      font-size: 1vw;
      line-height: 1.4vw;

      label {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .plusicon {
        padding: 1px 0.5vw 8px;
        font-size: 1vw;
      }
    }

    .input-field-outline,
    textarea.input-textarea {
      width: 30%;
    }

    .pendingbtn,
    .add-to-btn {
      border-radius: 0.5vw !important;
      padding: 0.4vw 0.8vw !important;
      font-size: 0.8vw !important;
      line-height: 1.4vw !important;
      height: auto;
    }

    .dropdown {
      width: 30%;
      padding-bottom: 0.6vw;

      label {
        line-height: 1.2vw;
        font-size: 0.8vw;
      }
    }

    .MuiSvgIcon-root {
      top: 0.4vw;
    }
  }
}
