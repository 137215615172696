.myprofile {
  // background-color: rgb(250, 249, 249);
  margin-top: 20px;
  padding-bottom: 60px;

  .myprofilecontainer {
    padding-right: 0%;
    padding-top: 0px !important;
  }

  .all-container-27inch {
    max-width: 98% !important;
  }

  .nobanner_image {
    text-align: center;
    display: block;
    padding-top: 2.5vw;
  }

  .css-19kzrtu {
    padding: 16px 0px;
  }

  .upload-img-new {
    align-items: center;
    border: 1px dashed #ccc;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    overflow: hidden;
    width: 75px;
    height: 75px;
  }

  // .file-section-logo {
  //   height: 138px;
  //   width: 138px;
  // }
  .file-section {
    position: relative;
    width: 412.5px;
  }

  .upload-banner-new {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    object-fit: cover;
  }

  .upload-close-icon {
    color: #ccc;
    cursor: pointer;
    position: absolute;
    /* right: -13px; */
    right: 5px;
    // top: 50px;
    top: 5px;
    z-index: 999;
  }

  //new code
  .textdark {
    text-transform: uppercase;
    font-weight: bold;
    margin-top: 10px;
  }

  .icon-info {
    // width: 1.77vw;
    // height: 1.77vw;
    color: #df0a0a;
    background-color: #f3c5c2c2;
    padding: 5px;
    border-radius: 4px;
    display: flex;
    align-items: center;

    .MuiSvgIcon-root {
      width: 2.2vw;
      height: 1.4vw;
      // padding-top: 6px;
    }
  }

  .personal-container {
    .width-container {
      .order-number {
        .info-new {
          width: fit-content;
        }
      }

    }

  }

  .account-subscription {
    .width-container {
      .month-sub {
        text-transform: capitalize;
        display: flex;
        gap: 15px;
        align-items: center;
      }
    }
  }

  .info-new {
    padding: 4px 12px;
    width: fit-content;
  }

  .lastbtn-section {
    text-align: center;
  }

  .info-icon-row {
    position: relative;
    display: inline-block;
    margin-top: 10px;
  }

  .info-icon-row .info-box {
    display: none;
    height: auto;
    width: auto;
    min-width: 290px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #54616c;
    opacity: 1;
    text-align: left;
    font-weight: normal;
    font-style: normal;
    font-variant: normal;
    font-family: $asap-font-regular;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0px;
    color: #000000;
    border-radius: 6px;
    opacity: 1;
    padding: 0.93vw 1.718vw;
    top: -5px;
    left: 104px;
    position: absolute;
    z-index: 1;
  }

  .info-icon-row:hover .info-box {
    display: block;
  }

  .info-icon-row .info-box::after {
    content: '';
    position: absolute;
    top: 6%;
    right: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent #54616c transparent transparent;
  }

  ul {
    list-style-type: none;
    padding-left: 0px !important;
    margin-top: 0px !important;
  }

  li {
    margin-bottom: 5px;
  }

  .line {
    border: 1px solid #ccc;
  }

  .verify-btn {
    background-color: #d3cec0;
    padding: 10px 20px;
    border-radius: 20px;
    font-family: $asap-font-regular;
    font-style: normal;
    font-size: 14px;
    line-height: 130%;
    font-weight: 600;
    color: #ffffff;
    border: none;
    cursor: pointer;
    margin-top: 10px;
  }

  .uploadtext {
    text-decoration: underline;
    cursor: pointer;
  }

  .banner-img1 {
    font-family: $asap-font-regular;
    font-weight: normal;
    font-style: normal;
    font-size: 16px;
    margin: 16px 55px 25px 81px;
    color: #2f2b23;
    text-align: center;
    padding: 0px 30px;
  }

  .upload-img {
    height: 35px !important;
    width: 35px !important;
    margin-top: 20px;
  }

  // .personal-container {
  //   border: 1px solid #d8d4cf;
  //   border-radius: 8px;
  //   padding: 24px 16px;
  //   margin-bottom: 16px;
  // }

  .text-section {
    margin-bottom: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .order-data {
    font-family: $asap-font-regular;
    font-style: normal;
    font-size: 16px;
    line-height: 130%;
    font-weight: normal;
    color: #2f2b23;
    margin-bottom: 16px;
    margin-top: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .order-number {
    font-family: $asap-font-regular;
    font-style: normal;
    font-size: 16px;
    font-weight: normal;
    color: #2f2b23;
    margin-bottom: 16px;
    margin-top: 16px;
    padding-right: 26px;
  }

  .gridcenter {
    border-bottom: 1px solid rgb(216, 212, 207);

    &:last-child {
      border: none;
    }
  }

  .shop-para {
    font-family: $asap-font-regular;
    font-style: normal;
    font-size: 16px;
    font-weight: normal;
    color: #2f2b23;
    // padding-right: 31%;
    width: 390px;
  }

  .btn-section {
    display: flex;
  }

  .pixel-text {
    font-family: $asap-font-regular;
    font-style: normal;
    font-size: 16px;
    font-weight: normal;
    color: #757061;
  }

  .logo-text {
    font-family: $asap-font-regular;
    font-style: normal;
    font-size: 16px;
    font-weight: normal;
    color: #2f2b23;
  }

  .mrgbottom {
    padding-bottom: 16px;
    padding-top: 16px;
  }

  .store-img-row {
    border-radius: 50%;
    overflow: hidden;
    border: 1px dashed #ccc;
    height: 75px;
    width: 75px;
    display: flex;
    align-items: center;
    justify-content: center;

    .input-type-file-upload {
      display: none;
    }

    .file-section {
      position: relative;
      height: 138px;
      width: 138px;
    }

    .upload-text {
      text-decoration: underline;
    }
  }

  .cover-img {
    position: relative;
    border: 1px dashed #ccc;
    margin: 16px 0px;
    border-radius: 8px;
    width: 412.5px;
    min-height: 95.5px;

    .bg-img {
      width: 100%;
      height: 100%;
      border-radius: 8px;
      object-fit: cover;
    }
  }

  .datepicker {
    margin: 20px 0 10px;
  }

  .css-1gsv261 {
    border-bottom: none;
  }

  .MuiButtonBase-root.MuiTab-root.Mui-selected {
    color: #5e6657;
    font-family: $asap-font-regular;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 130%;
    opacity: 1;
    padding: 12px 24px 12px 0px !important;
    text-transform: inherit;
  }

  .MuiButtonBase-root.MuiTab-root {
    color: #5e6657;
    font-family: $asap-font-regular;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 130%;
    opacity: 0.5;
    text-transform: inherit;
  }

  .css-1aquho2-MuiTabs-indicator {
    background-color: transparent;
  }

  .css-ttwr4n {
    background-color: transparent;
    // display: none;
  }

  .MuiTabs-indicator::after {
    content: '';
    height: 1px;
    color: #5e6657;
    width: 100%;
  }

  .update-date {
    text-align: end;
  }

  .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border-width: 0px;
  }

  .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input,
  .css-1uvydh2 {
    padding: 8.5px 14px;
    border: 1px solid #ccc;
    width: 100%;
    background-color: transparent;
    margin-top: 4px;
    padding-left: 24px;
    font-size: 16px;
    letter-spacing: 0px;
    font-style: normal;
    font-weight: normal;
    opacity: 1;
    font-family: $asap-font-regular;
    color: #2f2b23;
    background-color: #fff;
    border-radius: 10px;
    width: 270px;
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: transparent !important;
  }

  .css-1uvydh2:focus-visible {
    outline: 0;
    // border: transparent;
    // border: 0;
  }

  .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input:focus-visible {
    border: transparent;
    border: 0;
  }

  .MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.Mui-error.MuiInputBase-formControl.MuiInputBase-adornedEnd.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root:focus-visible {
    border: transparent;
    border: 0;
    outline: none;
  }

  .MuiFormControl-root.MuiTextField-root.css-1u3bzj6-MuiFormControl-root-MuiTextField-root:focus-visible {
    outline: none;
    border: 0;
  }

  .MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-adornedEnd.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root:focus-visible {
    outline: none;
    border: 0;
  }

  .css-1d3z3hw-MuiOutlinedInput-notchedOutline:focus-visible {
    outline: none;
    border: 0;
  }

  // background-color: rgb(250, 249, 249);
  // padding-top: 25px;
  // padding-bottom: 60px;

  // margin-left: 9%;
  // margin-right: 27%;
  // .myprofilecontainer {
  //   padding-right: 12%;
  //   padding-top: 72px !important;
  // }

  .personal-container {
    // border: 1px solid #d8d4cf;
    border-radius: 8px;
    padding: 24px 16px;
    margin-bottom: 16px;
    background-color: #f8fafd;
  }

  .width-container {
    width: 70%;
  }

  .text-section {
    margin-bottom: 8px;
    display: flex;
    justify-content: space-between;
  }

  .order-data {
    font-family: $asap-font-regular;
    font-style: normal;
    font-size: 16px;
    line-height: 130%;
    font-weight: normal;
    color: #2f2b23;
    margin-bottom: 16px;
    margin-top: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .order-number {
    font-family: $asap-font-regular;
    font-style: normal;
    font-size: 16px;
    font-weight: normal;
    color: #2f2b23;
    margin-bottom: 16px;
    margin-top: 16px;
    padding-right: 26px;
  }

  .gridcenter {
    border-bottom: 1px solid rgb(216, 212, 207);
  }

  .shop-para {
    font-family: $asap-font-regular;
    font-style: normal;
    font-size: 16px;
    font-weight: normal;
    color: #2f2b23;
    // padding-right: 31%;
    width: 390px;
  }

  .btn-section {
    display: flex;
  }

  .pixel-text {
    font-family: $asap-font-regular;
    font-style: normal;
    font-size: 16px;
    font-weight: normal;
    color: #757061;
  }

  .logo-text {
    font-family: $asap-font-regular;
    font-style: normal;
    font-size: 16px;
    font-weight: normal;
    color: #2f2b23;
  }

  .mrgbottom {
    padding-bottom: 16px;
    padding-top: 16px;
  }

  .css-1gsv261 {
    border-bottom: none;
  }

  .MuiButtonBase-root.MuiTab-root.Mui-selected {
    color: #5e6657;
    font-family: $asap-font-regular;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 130%;
    opacity: 1;
    padding-right: 0 !important;
    margin-right: 24px !important;
    align-items: flex-start;
    min-width: fit-content;
  }

  .MuiButtonBase-root.MuiTab-root {
    color: #5e6657;
    font-family: $asap-font-regular;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 130%;
    opacity: 0.5;
    padding-right: 0 !important;
    padding-left: 0 !important;
    margin-right: 24px !important;
    align-items: flex-start;
    min-width: fit-content;
  }

  .css-1aquho2-MuiTabs-indicator {
    background-color: #5e6657;
  }

  .update-date {
    text-align: end;
  }

  .MuiTabs-root button.Mui-selected {
    border-bottom: 2px solid #2f2b23;
  }

  .spacegrid {
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
  }
}

@media only screen and (max-width: 600px) {
  .myprofile {

    .file-section,
    #form-file-upload {
      width: 100%;
    }

    .width-container {
      width: 100%;
    }

    .nobanner_image {
      padding-top: 32px;
    }

    .myprofilecontainer {
      // padding-right: 5% !important;
      // padding-left: 8.5% !important;
    }

    .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input,
    .css-1uvydh2 {
      width: 100%;
    }

    .banner-img1 {
      padding: 0;
      margin: 16px 10px 25px 10px;
    }

    .cover-img {
      width: 100%;
    }

    .personal-container:first-child {
      margin-top: 20px;
    }

    .myprofile {
      .myprofilecontainer {
        // padding-right: 5% !important;
        // padding-left: 8.5% !important;
      }

      .personal-container:first-child {
        margin-top: 20px;
      }
    }
  }
}

@media only screen and (max-width: 1091px) {
  .myprofile {
    padding-left: 0%;
    padding-right: 0%;

    .shop-para {
      width: 100%;
    }

    .cover-img {
      width: 100%;
    }

    .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
      width: 100%;
    }

    .myprofilecontainer {
      padding-right: 0%;
    }

    .btn-section {
      display: flex;
      height: 42px;
    }
  }
}

@media (min-width: 2000px) and (max-width: 6000px) {
  .myprofile {
    .personal-container {
      padding: 1.4vw;

      .width-container {
        .btn-section {
          .lightbtn,
          .darkbtn {
            font-size: 0.8vw !important;
          }
        }
      }
    }

    .all-container-27inch {
      max-width: 65% !important;
    }

    .icon-info {
      font-size: 0.9vw;
      line-height: 1.2vw;
      padding: 19px;
      display: flex;
      align-items: center;
      width: auto;
      height: auto;

      .MuiSvgIcon-root {
        width: 1.2vw !important;
        height: 1.3vw !important;
        margin-left: 0.3vw;
      }
    }

    .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input,
    .css-1uvydh2 {
      font-size: 1vw;
      width: 15vw;
      border-radius: 0.5vw;
      padding: 0.5vw;
    }

    .emailidtext {
      font-size: 0.9vw;
      line-height: 1.1vw;
    }

    .store-img-row,
    .upload-img-new {
      width: 5vw;
      height: 5vw;
      border: 0.08vw dashed #ccc;
    }

    .banner-img1 {
      font-size: 1vw;
      line-height: 1.5vw;
    }

    .upload-img {
      width: 1.8vw !important;
      height: 1.8vw !important;
    }

    .nobanner_image {
      padding-top: 2vw;
      font-size: 1vw;
      line-height: 1.5vw;
    }

    .cover-img {
      width: 25vw;
      height: 6vw;
      margin: 1vw 0;
      border: 0.08vw dashed #ccc;
    }

    .file-section {
      width: 25vw;
    }

    .upload-close-icon {
      right: 0.5vw;
      top: 0.5vw;
    }

    .mrgbottom {
      padding-top: 0.88vw;
      padding-bottom: 0.8vw;
    }

    .personal-container {
      margin-bottom: 1vw;
    }

    .main-heading40 {
      font-size: 1.5vw;
      line-height: 1.7;
    }

    .MuiButtonBase-root.MuiTab-root,
    .MuiButtonBase-root.MuiTab-root.Mui-selected {
      font-size: 0.8vw;
      // width: 12vw;
      // max-width: 12vw;
      text-transform: inherit !important;
    }

    .sub-heading {
      font-size: 1vw;
      line-height: 1.5vw;
    }

    .order-number,
    .order-data,
    .shop-para {
      font-size: 1vw;
      line-height: 1.2vw;
    }

    .shop-para {
      width: 100%;
    }

    .logo-text {
      font-size: 1vw;
      line-height: 1.2vw;
    }

    .pixel-text {
      font-size: 0.8vw;
      line-height: 1.2vw;
    }

    .lightbtn,
    .darkbtn {
      border-radius: 0.5vw !important;
      padding: 0.4vw 0.8vw !important;
      font-size: 0.8vw !important;
    }

    .text-section {
      align-items: center;
    }

    .myprofile {
      padding-left: 0%;
      padding-right: 0%;

      .shop-para {
        width: 100%;
      }

      .myprofilecontainer {
        padding-right: 0%;
      }

      .btn-section {
        display: flex;
        height: 42px;
      }
    }
  }
}

@media (min-width: 2000px) and (max-width: 6000px) {
  .myprofile {
    .personal-container {
      padding: 1.4vw;
    }

    .main-heading40 {
      font-size: 1.5vw;
      line-height: 1.7;
    }

    .MuiButtonBase-root.MuiTab-root,
    .MuiButtonBase-root.MuiTab-root.Mui-selected {
      font-size: 0.8vw;
      width: 5vw;
      max-width: 5vw;
    }

    .sub-heading {
      font-size: 1vw;
      line-height: 1.5vw;
    }

    .order-number,
    .order-data,
    .shop-para {
      font-size: 0.9vw;
      line-height: 1.2vw;
    }

    .shop-para {
      width: 75%;
    }

    .logo-text {
      font-size: 0.9vw;
      line-height: 1.2vw;
    }

    .pixel-text {
      font-size: 0.8vw;
      line-height: 1.2vw;
    }

    .lightbtn,
    .darkbtn {
      border-radius: 0.5vw !important;
      padding: 0.4vw 0.8vw !important;
      font-size: 0.8vw !important;
    }

    .text-section {
      align-items: center;
    }
  }

  .css-169iwlq-MuiCalendarPicker-root,
  .css-epd502,
  .css-1brzq0m {
    width: 21vw !important;
    max-height: 20vw !important;
    padding-top: 0.5vw;
    padding-bottom: 0.5vw;
  }

  .css-1dozdou,
  .css-1dozdou {
    margin-bottom: 1vw !important;
    max-height: 2vw !important;
  }

  .css-l0iinn,
  .css-l0iinn {
    max-height: 2vw !important;
  }

  .css-m1gykc {
    font-size: 1vw !important;
    line-height: 1.5vw !important;
    margin: 0.5vw !important;
    height: 2vw !important;
    width: 3vw !important;
    border-radius: 1vw !important;
  }

  .css-ub1r1,
  .css-bkrceb-MuiButtonBase-root-MuiPickersDay-root,
  .css-1w13o7u-MuiTypography-root,
  .css-10qkmc3,
  .css-1f3kfmk {
    font-size: 1vw !important;
    line-height: 1.5vw !important;
    height: 2vw !important;
    width: 2vw !important;
  }

  .css-1v994a0 {
    font-size: 1vw;
    line-height: 1.5vw;
  }
}