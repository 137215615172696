.UserRegistration {
  // background-color: #f5f5f5;
  .dropdown .select-field {
    background: url(../../assets/images/sellerSide/dropdown.webp) 97%/7% no-repeat;
    height: 35px;
    line-height: 23px;
  }

  .entries {
    background-color: #ffff;
    margin: 10px 10px 20px 10px;
  }

  .heading {
    font-size: 20px;
    padding: 15px 20px;
    margin: 0px;
    color: #23282d;
    font-weight: 500;
  }

  .product {
    font-size: 16px;
    color: #17a2b8;
    padding-right: 5px;
    width: auto;
    font-family: $asap-font-regular;
    cursor: pointer;
  }

  .pagination .row-page {
    font-size: 12px;
    font-family: $asap-font-regular;
    line-height: 15px;
    color: rgba(0, 0, 0, 0.54);
  }

  .MuiTablePagination-root:last-child {
    padding: 0;
    color: rgba(0, 0, 0, 0.54);
    font-family: $asap-font-regular;
  }

  .dropdown {
    width: 180px !important;
  }

  .dropdownsection {
    display: flex;
    align-items: center;
  }

  .lightbtn {
    height: 35px !important;
    width: 110px !important;
    color: #0073aa !important;
    background-color: transparent !important;
    border: 1px solid #ddd !important;
    border-radius: 4px !important;
    box-shadow: none;
    font-size: 15px !important;
    letter-spacing: 0;
    line-height: 18px !important;
    margin-left: 10px;
    text-transform: capitalize;
    font-weight: 400 !important;
  }

  .icon-visibility {
    height: 29px;
    width: 29px;
    box-shadow: 0px 1px 0px 0px #ccc !important;
    border-radius: 4px;
    color: #302b2b;
    text-align: center;
    background-color: #fff;
    margin-top: 5px;
    margin-bottom: 4px;
    cursor: pointer;
    padding: 4px;
  }

  .icon-visibility:hover {
    background-color: #17a2b8;
    color: #ffffff;
  }

  .checkbutton {
    padding: 0px;
  }

  .icon-section {
    display: flex;
    align-items: center;
  }

  .register-container {
    margin: 10px 20px 10px 20px;
    align-items: center;
    display: flex;
    padding-bottom: 37px;
    display: flex;
    justify-content: space-between;
  }

  .pagination {
    margin-top: 0;
  }

  .table-row {
    padding: 10px 0 0 10px;
    background-color: #ffff;
    overflow-x: auto;
  }

  table {
    border-collapse: collapse;
    width: 100%;
  }

  td,
  th {
    border-bottom: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }

  td {
    font-family: $asap-font-regular;
  }

  th {
    padding: 25px 8px 15px;
  }

  .table-heading {
    color: #32373c;
    white-space: initial;
    opacity: 0.999;
    font-weight: 100 !important;
    font: -webkit-mini-control;
  }

  .product-name {
    font-size: 14px;
  }

  .view-icon {
    height: 25px !important;
    width: 25px !important;
    box-shadow: 0px 1px 0px 0px #ccc !important;
    border-radius: 4px !important;
    color: #666 !important;
    cursor: pointer !important;
  }

  .star-icon {
    font-size: 16px;
    height: 16px;
    width: 16px;
    margin-left: 4px;
    margin-right: 2px;
    color: #ddd;
  }

  .panorama-icon {
    font-size: 16px;
    height: 16px;
    width: 16px;
    color: #ddd;
  }
}

@media (min-width: 280px) and (max-width: 600px) {
  .UserRegistration {
    .register-container {
      display: block;
    }

    .pagination {
      justify-content: center;
    }

    .table-row {
      overflow: scroll;
    }
  }
}

@media (min-width: 1900px) and (max-width: 6000px) {
  .UserRegistration {
    .table-heading {
      font-size: 0.8vw;
    }

    .product-name {
      font-size: 0.75vw;
    }
    .product {
      font-size: 0.88vw;
      line-height: 1.2vw;
    }
    .heading {
      font-size: 1.04vw;
      line-height: 1.25vw;
    }
    .entries {
      padding: 0.5vw;
    }
    .dropdown {
      width: 9vw !important;
    }
    .lightbtn {
      height: 2vw !important;
      width: 5vw !important;
      font-size: 1vw !important;
      margin-left: 0.5vw;
    }
    .register-container {
      margin: 1vw 20px;
    }
  }
}
