.resetpassword {
  .login-full-container {
    margin: 0 auto;
    width: 60%;
    padding-bottom: 110px;
    text-align: center;
    padding-top: 133px;
  }

  .login-para {
    text-align: center;
    font-size: 16px;
    letter-spacing: 0px;
    color: #2f2b23;
    opacity: 1;
    font-family: $asap-font-regular;
    margin-bottom: 91px;
  }
  .input-field-loginside {
    width: 40%;
  }
}

@media only screen and (min-width: 1921px) {
  .resetpassword {
    .login-para {
      font-size: 1.2vw;
      line-height: 1.4vw;
      margin-bottom: 4.7vw;
    }
    .login-full-container {
      padding-top: 9vw;
    }
  }
}

@media only screen and (max-width: 919px) {
  .resetpassword .login-full-container {
    width: 100%;
  }
}
