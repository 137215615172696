.pagination {
  margin-top: 30px;
  display: flex;
  justify-content: end;
  align-items: center;
  font-size: 12px;
  line-height: 15px;
  .row-page {
    font-size: 12px;
    line-height: 15px;
    .row-page {
      font-size: 12px;
      line-height: 15px;
    }
    .MuiPagination-ul {
      li {
        margin-left: 8px;
        button {
          width: 33px;
          height: 33px;
        }
      }
    }
    .MuiPaginationItem-page.Mui-selected {
      background: #949d8c;
      color: #ffffff;
    }
    .MuiPaginationItem-sizeSmall .MuiPaginationItem-icon {
      font-size: 2.125rem;
      margin: 0;
    }
    li:first-child {
      .MuiPaginationItem-sizeSmall {
        border: none !important;
      }
      .MuiPaginationItem-sizeSmall .MuiPaginationItem-icon {
        font-size: 3rem;
        margin: 0;
      }
    }
    .MuiPaginationItem-sizeSmall {
      border-radius: 2px !important;
      border: 1px solid #d8d4cf !important;
    }
  }

  @media (min-width: 2000px) and (max-width: 6000px) {
    .pagination {
      margin-top: 3vw;
      line-height: 1.2;
      font-size: 1vw;
      .MuiTablePagination-caption {
        font-size: 0.8vw;
        line-height: 1.2vw;
      }
      .MuiSelect-icon {
        top: -0.2vw;
      }
      .row-page {
        font-size: 1vw !important;
        line-height: 1.5vw !important;
      }
      .MuiPaginationItem-sizeSmall {
        height: 1.5vw;
        margin: 0 1px;
        padding: 0 7px;
        min-width: 1.5vw;
        border-radius: 13px;
        font-size: 1vw !important;
      }
    }
  }
}

@media screen and (min-width: 1930px) {
  .pagination{
    .MuiPagination-root{
      .MuiPagination-ul{
        li{
          .MuiButtonBase-root{
            height: 2.3vw;
            width: 2.3vw;
            svg{
              width: 100% !important;
              height: 100% !important;
            }
            font-size: 1.5vw;
            border-radius: 50%;
          }
        }
      }
    }
  }
}
