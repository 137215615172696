.dispatchorderdialogs {
  .sub-heading {
    // font-size: 22px;
    // line-height: 24px;
    margin-bottom: 8px;
  }
  .label-text {
    font-family: $asap-font-regular;
    font-style: normal;
    font-size: 18px;
    line-height: 20px;
    font-weight: normal;
    color: #2f2b23;
    margin-bottom: 4px;
    margin-top: 20px;
  }
  .heading-para {
    font-family: $asap-font-regular;
    font-style: normal;
    font-size: 16px;
    line-height: 130%;
    font-weight: 500;
    color: #2f2b23;
    margin-bottom: 15px;
    padding-right: 2vw;
  }
  .heading-id {
    font-family: $asap-font-regular;
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    color: #2f2b23;
    margin-bottom: 10px;
  }
  .btn-section {
    text-align: end;
    margin-top: 24px;
  }
  .dispatch-dropdown{
    width: 100% !important;
    margin-bottom: 1rem;
    .select-field{
      background-position-y: center;
    }
  }

  .disable-add-btn {
    .lightbtn {
      background-color: #c4c9c0 !important;
      color: #ffffff !important;
    }
  }
}

@media only screen and (min-width: 1921px) {
  .dispatchorderdialogs {
    .sub-heading {
      font-size: 1.2vw;
      line-height: 1.4vw;
    }
    .label-text {
      font-size: 1vw;
      line-height: 1.2vw;
    }
    .heading-id {
      font-size: 0.9vw;
      line-height: 1.5vw;
      margin-bottom: 0.5vw;
    }
    .heading-para {
      font-size: 0.937vw;
      line-height: 1.25vw;
      margin-bottom: 1.25vw;
    }
    // .btn-section {
    //   margin-top: 3.35vw;
    //   margin-bottom: 3.35vw;
    // }
    .lightbtn,
    .darkbtn {
      border-radius: 0.5vw !important;
      padding: 0.4vw 0.8vw !important;
      font-size: 0.8vw !important;
    }
  }
}
