// .progressbar {
//   padding-top: 85px;
// }
.progressbar-progress {
  height: 2px !important;
  background-color: #7f6a86 !important;
}
.progressbar-container {
  background-color: #e8e5e3 !important;
}

// @media only screen and (min-width: 1921px) {
//   .progressbar {
//     padding-top: 5vw;
//   }
// }
