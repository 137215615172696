.dashboardchart {
  .k-card {
    border: none;
    text {
      fill: #99abb4;
    }
    // path{
    //     fill: rgb(148, 157, 140);
    //     stroke: rgb(255, 255, 255);
    // }
  }
}
.firstgraph-color {
  .apexcharts-tooltip-marker {
    background-color: #3399ff !important;
    width: 10px;
    height: 10px;
    margin-right: 8px;
  }
  .apexcharts-series-markers {
    .apexcharts-marker {
      fill: #3399ff !important;
    }
  }
}

@media (min-width: 920px) and (max-width: 1023px) {
  .dashboardchart {
    .k-card text {
      font-size: 10px !important;
    }
  }
}
