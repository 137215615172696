.view-details {
  padding: 15px;
  // background-color: rgb(245, 245, 245);
  .category-heading {
    font-size: 28px;
    font-weight: 600;
    color: #444;
    margin: 0px;
    width: auto;
    // padding: 30px 0px 0px 41px;
  }
  .para {
    margin: 0px 0px 10px 0px;
    font-size: 14px;
    color: #333;
  }
  .details-text {
    margin: 0px 0px 15px 0px;
    font-size: 18px;
    line-height: 22px;
    color: #333;
    font-weight: 700;
    font-family: $asap-font-regular;
  }
  .details-para {
    margin: 0px 0px 15px 0px;
    font-size: 18px;
    line-height: 22px;
    color: #333;
    font-family: 'Poppins';
  }
  .card-container {
    background-color: #ffff;
    margin: 10px 0px 20px 0px;
    height: auto;
    border-top: 3px solid #3c8dbc;
    padding: 10px 0px 20px 20px;
  }
  .image-container {
    height: 150px;
    width: 220px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    img {
      height: 100%;
      width: 100%;
    }
  }
}
@media (min-width: 2000px) and (max-width: 6000px) {
  .view-details {
    .category-heading {
      font-size: 1.4vw;
      line-height: 1.6vw;
      padding: 0.8vw 0;
    }
    .details-text {
      margin: 0px 0px 0.7vw 0px;
      font-size: 0.9vw;
      line-height: 1.2vw;
    }
    .details-para {
      margin: 0px 0px 0.7vw 0px;
      font-size: 0.8vw;
      line-height: 1.2vw;
    }
    .darkbtn {
      border-radius: 0.5vw !important;
      padding: 0.4vw 0.8vw !important;
      font-size: 0.8vw !important;
    }
    .card-container {
      padding: 1vw;
    }
  }
}
