.verifyyouremail {
  max-width: 454px;
  margin: auto;
  width: 50%;
  padding-top: 177px;
  min-height: 1000px;
  text-align: center;

  .ErrorMessage{
    margin-top: 1.6rem;
    color: red;
  }

  .text-heading {
    text-align: center;
    font-size: 26px;
    line-height: 30px;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    font-family: $asap-font-regular;
    font-style: italic;
    margin-bottom: 12px;
    margin-top: 24px;
  }

  .text-para {
    text-align: center;
    font-size: 18px;
    letter-spacing: 0px;
    color: #757061;
    opacity: 1;
    font-family: $asap-font-regular;
    margin-bottom: 32px;
    padding-left: 30px;
    padding-right: 30px;
  }

  .darkbtn,
  .lightbtn,
  .disabledBtn {
    font-size: 16px !important;
  }

  .input-field-otp {
    width: 48px;
    height: 78px;
    border: 1px solid #949d8c;
    border-radius: 8px;
    margin-right: 16px;
    font-family: $asap-font-regular;
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 30px;
    text-align: center;
    color: #2f2b23;
  }

  .input-field-otp:focus-visible {
    outline: none;
  }

  input[type='tel']:focus-visible {
    outline: none;
  }

  .lightbtn {
    min-width: 96.5px !important;
  }

  .resend-text {
    cursor: pointer;
    text-decoration: underline;
  }

  .code-text {
    letter-spacing: 0px;
    opacity: 1;
    font-family: $asap-font-regular;
    margin-bottom: 32px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 130%;
    text-align: center;
    color: #2f2b23;
    margin-top: 24px;
  }

  .btn-section {
    margin-top: 20px;
    justify-content: space-between;
    flex-direction: row;
    box-sizing: border-box;
    display: flex;
  }
}

@media only screen and (max-width: 600px) {
  .verifyyouremail {
    width: 80%;
  }
}

@media only screen and (min-width: 1921px) {
  .verifyyouremail {
    width: 80%;
    max-width: 25.64vw;
    padding-top: 8vw;

    .logo-img {
      width: 12vw;
      height: 9vw;
    }

    .text-heading {
      font-size: 1.5vw;
      line-height: 1.66vw;
      margin-bottom: 0.866vw;
    }

    .text-para {
      font-size: 0.937vw;
      line-height: 1.25vw;
      margin-bottom: 1.25vw;
    }

    .input-field-otp {
      width: 3vw;
      height: 4.5vw;
      border-radius: 0.56vw;
    }

    .code-text {
      margin-bottom: 1.68vw;
      font-size: 0.833vw;
      margin-top: 1.25vw;
    }

    .btn-section {
      margin-top: 3.35vw;
      margin-bottom: 3.35vw;
    }

    .darkbtn {
      min-width: 6.5vw !important;
      min-height: 3.2vw !important;
      text-align: center !important;
      font-size: 0.937vw !important;
      line-height: 1.04vw !important;
      border-radius: 0.56vw !important;
    }

    .lightbtn {
      min-width: 6.5vw !important;
      min-height: 3.2vw !important;
      text-align: center !important;
      font-size: 0.937vw !important;
      line-height: 1.04vw !important;
      border-radius: 0.56vw !important;
    }
    .disabledBtn {
      min-width: 6.5vw !important;
      min-height: 3.2vw !important;
      text-align: center !important;
      font-size: 0.937vw !important;
      line-height: 1.04vw !important;
      border-radius: 0.56vw !important;
    }
  }
}
