.updatebusinessdetails {
  .uploadpara {
    font-family: $asap-font-regular;
    font-weight: normal;
    font-style: normal;
    font-size: 16px;
    line-height: 130%;
    margin: 0px 0px 16px 0px;
    color: #2f2b23;
  }

  .tracking-text {
    font-family: $asap-font-regular;
    font-style: normal;
    font-size: 16px;
    margin-top: 16px;
    font-weight: normal;
    color: #2f2b23;
    margin-bottom: 4px;
  }

  .update-text {
    font-family: 'Asap';
    font-style: normal;
    font-size: 16px;
    margin-bottom: 16px;
    font-weight: normal;
    color: #757061;
  }

  .btn-section {
    text-align: end;
    margin-top: 24px;
  }
}

@media (min-width: 2000px) and (max-width: 6000px) {
  .updatebusinessdetails {
    .sub-heading {
      font-size: 1vw;
      line-height: 1.2vw;
    }

    .update-text {
      font-size: 0.8vw;
      line-height: 1.2vw;
    }

    .tracking-text,
    .uploadpara {
      font-size: 0.8vw;
      margin-top: 0.8vw;
      line-height: 1.2vw;
    }

    .btn-section {

      .lightbtn,
      .darkbtn {
        border-radius: 0.5vw !important;
        padding: 0.4vw 0.8vw !important;
        font-size: 0.8vw !important;
      }
    }
  }

}