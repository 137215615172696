.forgotpassword {
  .login-full-container {
    margin: 0 auto;
    max-width: 34.479166666%;
    padding-bottom: 110px;
    text-align: center;
    padding-top: 113px;
  }

  .login-para {
    text-align: center;
    font-size: 16px;
    letter-spacing: 0px;
    color: #2f2b23;
    opacity: 1;
    font-family: $asap-font-regular;
    margin-bottom: 91px;
  }
  .input-field-loginside {
    width: 531px;
    margin-bottom: 0;
  }
  .blackbtn {
    margin-top: 44px;
  }
  .input-error-message {
    margin-top: 10px;
  }
}

@media only screen and (min-width: 1921px) {
  .forgotpassword {
    padding-top: 3vw;
    .login-para {
      font-size: 1.2vw;
      line-height: 1.4vw;
      margin-bottom: 4.7vw;
    }
    .input-field-loginside {
      width: 25vw;
    }
  }
}

@media only screen and (max-width: 919px) {
  .forgotpassword {
    .login-full-container {
      max-width: 100%;
      padding: 133px 20px 110px;
    }
    .input-field-loginside {
      width: 100%;
    }
  }
}
