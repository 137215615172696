.site-shipping {
    margin-top: 2rem;
    margin-bottom: 2.5rem;

    .welcominnercard {
        margin-bottom: 36px !important;
        padding: 5.278vw 3.889vw;
        min-height: 0;

        .MuiGrid-root {
            .MuiGrid-root {
                padding-top: 0 !important;

                .welcome-new-text {
                    font-family: 'Inter-Medium';
                    font-weight: 500;
                }

                .welcome-para {
                    font-weight: 400 !important;
                    font-family: 'Inter-Regular' !important;
                }
            }
        }
    }

    .flex {
        display: flex;

        .return-btn,
        .collection-btn {
            height: 3.25rem;
            min-width: 8.5rem;
            border-radius: 3.25rem !important;
            font-size: 1.125rem !important;
            margin-bottom: 2.188rem;
            margin-left: 2.5rem;
            font-family: 'Inter-Medium' !important;
            font-style: normal !important;
            font-weight: 500 !important;
        }

        .collection-btn {
            width: fit-content;
            padding-left: 24px !important;
            padding-right: 24px !important;
        }

        .AddTo {
            .create-menu {
                height: 3.25rem;
                min-width: 8.5rem;
                border-radius: 3.25rem !important;
                font-size: 1.125rem !important;
                margin-bottom: 2.188rem;
                margin-left: 2.5rem;
                font-family: 'Inter-Medium' !important;
                font-style: normal !important;
                font-weight: 500 !important;
            }
        }
    }


    .site-table {
        background: #f8fafd;
        padding: 0 48px;
        margin-right: 16px;
        border-radius: 10px;

        .actionbtn {
            .edit {
                justify-content: flex-start;

                .MuiButtonBase-root {
                    padding: 10px 0;
                }
            }
        }
    }

    .buttons {
        width: 100%;
        margin-top: 70px;

        .buttons-position {
            display: flex;
            justify-content: flex-end;
            margin-right: 130px;

            .lightbtn {
                margin-right: 30px !important;
            }
        }
    }
}

.courier-text {
    font-size: 18px;
}


.form-box {
    display: flex;
    flex-direction: column;
    row-gap: 15px;

    label {
        display: flex;
        column-gap: 10px;
        align-items: center;
    }

    .darkbtn {
        margin-right: 10px;
    }
}


@media screen and (min-width: 3000px) and (max-width: 6000px) {
    .site-shipping {
        .flex {

            .return-btn,
            .collection-btn {
                border-radius: 6rem !important;
                font-size: 1.8rem !important;
                margin-bottom: 4rem;
                height: 4rem;
                min-width: 14.5rem;
            }

            .collection-btn {
                min-width: clamp(8.5rem, 16.5vw, 26rem);
            }

            .AddTo {
                .create-menu {
                    border-radius: 6rem !important;
                    font-size: 1.8rem !important;
                    margin-bottom: 4rem;
                    height: 4rem;
                    min-width: 0;
                    width: fit-content;
                    padding-left: 34px !important;
                    padding-right: 34px !important;
                }
            }
        }

        .buttons {
            .buttons-position {

                .lightbtn,
                .bluebtn {
                    font-size: 1.016vw !important;
                    margin-right: 0.781vw !important;
                    padding: 15px 24px !important;
                    border-radius: 18px !important;
                }
            }
        }

        .site-table {
            .main-table {
                table {
                    tbody {
                        tr {
                            td {
                                font-size: 0.829vw;

                                .actionbtn {
                                    .AddTo {
                                        .bluebtn {
                                            font-size: 0.9vw !important;
                                            margin-right: 1rem !important;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .main-table {
                table {
                    thead {
                        tr {
                            th {
                                font-size: 0.829vw;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 1930px) {
    .courier-text {
        font-size: 1.04vw;
    }

    .form-box {
        input[type=checkbox] {
            width: 1vw;
            height: 1vw;
        }
        label{
            font-size: 1.04vw;
        }
        .bluebtn,.lightbtn{
            font-size: 28px !important;
        }
    }
    .input-error-message {
        font-size: 25px;
        line-height: 1.5vw;
    }
}

@media screen and (min-width: 1930px) and (max-width: 3000px) {
    .site-shipping {
        .flex {

            .return-btn,
            .collection-btn {
                height: clamp(3.25rem, 3.5vw, 5rem);
                min-width: clamp(8.5rem, 11.5vw, 18rem);
                border-radius: 3.25rem !important;
                font-size: clamp(1.125rem, 1.2vw, 1.8rem) !important;
            }

            .collection-btn {
                min-width: clamp(8.5rem, 16.5vw, 26rem);
            }

            .AddTo {
                .create-menu {
                    height: clamp(3.25rem, 3.5vw, 5rem);
                    min-width: 0;
                    border-radius: 3.25rem !important;
                    font-size: clamp(1.125rem, 1.2vw, 1.8rem) !important;
                    width: fit-content;
                    padding-left: 34px !important;
                    padding-right: 34px !important;

                }
            }
        }

        .site-table {
            .main-table {
                table {
                    tbody {
                        tr {
                            td {
                                font-size: 0.829vw;

                                .actionbtn {
                                    .AddTo {
                                        .bluebtn {
                                            font-size: 0.9vw !important;
                                            margin-right: 1rem !important;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .main-table {
                table {
                    thead {
                        tr {
                            th {
                                font-size: 0.829vw;
                            }
                        }
                    }
                }
            }
        }

        .buttons {
            .buttons-position {

                .lightbtn,
                .bluebtn {
                    font-size: 1.016vw !important;
                    margin-right: 0.781vw !important;
                    padding: 15px 24px !important;
                    border-radius: 18px !important;
                }
            }
        }

        .circle-btn {
            width: 3rem;
            height: 3rem;
        }
    }
}

@media screen and (max-width: 800px) {
    .site-shipping {
        margin: 0 1.5rem;
        margin-bottom: 8vw;

        .welcominnercard {
            margin-left: 0;
            margin-right: 0;
        }

        .return-btn,
        .collection-btn {
            margin-left: 0;
        }

        .site-table {
            margin-right: 0;
            padding: 0 16px;
        }

        .buttons {
            margin-top: 40px;

            .buttons-position {
                margin-right: 0;
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .site-shipping {
        margin: 0 1.5rem;
        margin-bottom: 8vw;

        .welcominnercard {
            margin-left: 0;
            margin-right: 0;
            padding: 10.6vw 4.8vw 5.6vw;
        }

        .flex {
            flex-direction: column;

            .return-btn,
            .collection-btn {
                margin-left: 0;
                width: 100%;
            }

            .AddTo {
                .create-menu {
                    margin-left: 0;
                    width: 100%;
                }
            }
        }

        .site-table {
            margin-right: 0;
            padding: 0 16px;
        }

        .buttons {
            margin-top: 40px;

            .buttons-position {
                margin-right: 0;
            }
        }
    }
}