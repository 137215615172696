.welcomecard {
  background-color: #e0f8f2;
  min-height: 24vw;
  padding: 7.638vw 3.86vw 4.8vw;
  border-radius: 1.666vw;
  margin-bottom: 3.867rem;
  margin-right: 16px;
  width: auto !important;
  .welcome-new-text {
    font-size: 7vw;
    line-height: 8vw;
    font-weight: 800;
    font-family: 'Inter-Bold';
    color: #1f1f1f;
    margin: 0;
    margin-bottom: 1.86rem;
  }
  .welcome-para {
    margin: 0;
    font-size: 1.38vw;
    line-height: 2vw;
    font-family: 'Inter-Regular';
    color: #1f1f1f;
    padding-right: 5vw;
  }
}
.welcominnercard {
  background-color: #e0f8f2;
  min-height: 17vw;
  padding: 3.6vw 3.8vw 3.6vw;
  border-radius: 1.666vw;
  margin-bottom: 2.569vw;
  margin-right: 16px;
  width: auto !important;
  .welcome-new-text {
    font-size: 4.513vw;
    line-height: 7.7.777vw;
    font-weight: 500;
    font-family: 'Inter-Medium';
    color: #1f1f1f;
    margin: 0;
  }
  .welcome-para {
    margin: 0;
    font-size: 1.11vw;
    line-height: 1.45vw;
    font-family: 'Inter-Regular';
    color: #1f1f1f;
    padding-right: 18vw;
  }
}
@media only screen and (max-width: 600px) {
  .welcomecard {
    margin-left: 16px;
    min-height: 10rem;
    padding: 2rem 1rem 2rem;
    border-radius: 16pxs;

    .welcome-new-text {
      font-size: 9vw;
      line-height: 8vw;
      margin-bottom: 1rem;
    }
    .welcome-para {
      font-size: 4.38vw;
      line-height: 5.2vw;
      padding-left: 0.733vw;
    }
  }
  .welcominnercard {
    min-height: 10rem;
    padding: 5.6vw 4.8vw 5.6vw;
    margin-left: 16px;
    margin-bottom: 3.569vw;
    border-radius: 16px;
    .welcome-new-text {
      font-size: 8.513vw;
      line-height: 9.777vw;
      margin-bottom: 3vw;
    }
    .welcome-para {
      font-size: 4.11vw;
      line-height: 5.45vw;
      padding-right: 0vw;
    }
  }
}

@media (min-width: 600px) and (max-width: 920px){
  .welcominnercard{
    margin-left: 16px;
  }
}

@media (min-width: 601px) and (max-width: 920px) {
  .welcomecard {
    min-height: 10rem;
    // margin-left: 16px;
  }
  .welcominnercard{
    .welcome-para{
      font-size: 1.8vw;
      line-height: 2.2vw;
    }
  }
}
@media (min-width: 1921px) and (max-width: 6000px) {
  .welcominnercard {
    margin-right: 1vw;
  }
}
