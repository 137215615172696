.help-page {
  padding-top: 25px;
  padding-bottom: 60px;
  .order-heading {
    font-family: $asap-font-regular !important;
    font-style: normal;
    font-size: 26px;
    line-height: 30px;
    font-weight: 600;
    color: #2f2b23;
    margin-bottom: 8px;
    margin-left: 1%;
  }
  .para-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 1%;
    margin-left: 1%;
  }
  .help-para {
    font-family: $asap-font-regular !important;
    font-style: normal;
    font-size: 16px;
    font-weight: normal;
    color: #757061;
    width: 60%;
  }
  .video-container {
    display: inline-block;
    min-width: 220px;
    margin: 1%;
    width: 48%;
  }
  .box-container {
    margin: auto;
    margin-top: 10px;
  }
  .video-box {
    width: 100%;
    height: 251px;
    border: 1px solid;
    border-radius: 8px;
  }
  .video-container p {
    font-style: normal;
    font-family: $asap-font-regular;
    font-size: 16px;
    font-weight: 600;
    line-height: 130%;
    align-items: center;
    color: #2f2b23;
    margin-top: 1px;
    margin-bottom: 0px;
  }
}

@media (min-width: 280px) and (max-width: 600px) {
  .ytp-large-play-button.ytp-button.ytp-large-play-button-red-bg {
    left: 52% !important;
    top: 55% !important;
  }
  .help-page {
    .para-section {
      display: block;
      margin-left: 1%;
    }
    .video-container {
      margin: 1%;
      width: 100%;
    }
    .help-para {
      width: 100%;
      margin-bottom: 4vw;
    }
  }
}
@media (min-width: 1500px) and (max-width: 1899px) {
  .help-page .help-para {
    width: 50%;
  }
}
@media (min-width: 1900px) and (max-width: 1920px) {
  .help-page .help-para {
    width: 40%;
  }
}

@media (min-width: 1921px) and (max-width: 6000px) {
  .help-page {
    padding-top: 2vw;
    padding-bottom: 5vw;
    .darkbtn {
      font-size: 1vw !important;
      line-height: 1.5vw !important;
      padding: 0.6vw 1vw !important;
      border-radius: 0.4vw !important;
    }
    .order-heading {
      font-size: 1.5vw;
      line-height: 2vw;
      margin-bottom: 1vw;
      margin-left: 1%;
    }
    .all-container-27inch {
      max-width: 65% !important;
    }
    .help-para {
      font-size: 1vw;
      line-height: 1.3vw;
      width: 70%;
    }
    .video-box {
      height: 10vw;
    }
    .video-container p {
      font-size: 1vw;
      line-height: 1.3vw;
    }
  }
}
