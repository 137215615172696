.tell-your-story {
  margin-top: 2rem;
  margin-bottom: 2.5rem;
  .welcominnercard {
    margin-bottom: 36px !important;
    padding: 5.278vw 3.889vw;
    min-height: 0;
    .MuiGrid-root {
      .MuiGrid-root {
        padding-top: 0 !important;
        .welcome-new-text {
          font-family: 'Inter-Medium';
          font-weight: 500;
        }
        .welcome-para {
          font-weight: 400 !important;
          font-family: 'Inter-Regular' !important;
        }
      }
    }
  }
  .main-section {
    .return-btn {
      margin-left: 2rem;
      height: 3.25rem;
      min-width: 8.5rem;
      border-radius: 3.25rem !important;
      font-size: 1.125rem !important ;
      font-family: 'Inter-Medium' !important;
      font-style: normal !important;
      font-weight: 500 !important;
    }
    .story-top {
      display: flex;
      column-gap: 6.25rem;
      .input-part {
        display: flex;
        align-items: center;
        column-gap: 44px;
        .input-width {
          width: 31.25rem;
        }
        .input-field-outline,
        .input-textarea {
          border: 1px solid #d8d4cf !important;
          margin-bottom: 0;
          margin-top: 0;
          &::placeholder {
            color: #a6a4a0 !important;
          }
        }
        p {
          font-family: $asap-font-regular;
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 21px;
          color: #2f2b23;
        }
      }
    }
    .story-detail {
      margin-top: 3rem;
      .search-engine-section {
        .input-title {
          .meta-info {
            .input-width,
            .input-textarea {
              width: 745px;
            }
          }
        }
      }
    }
  }
  .buttons {
    width: 100%;
    margin-top: 70px;
    .buttons-position {
      display: flex;
      justify-content: flex-end;
      margin-right: 130px;
      .lightbtn {
        margin-right: 30px !important;
      }
    }
  }
}

@media screen and (min-width: 3000px) and (max-width: 6000px) {
  .tell-your-story {
    .main-section {
      .return-btn {
        border-radius: 6rem !important;
        font-size: 1.8rem !important;
        height: 4rem;
        min-width: 14.5rem;
      }
      .story-top {
        .input-part {
          p {
            font-size: clamp(1.125rem, 1.2vw, 1.8rem);
          }
          .input-width {
            width: clamp(31.25rem, 32vw, 55rem);
            .input-field-outline {
              margin-top: 0;
              height: 85px;
            }
          }
        }
      }
    }
    .buttons {
      .buttons-position {
        .lightbtn,
        .bluebtn {
          font-size: 1.016vw !important;
          margin-right: 0.781vw !important;
          padding: 15px 24px !important;
          border-radius: 18px !important;
        }
      }
    }
  }
}

@media screen and (min-width: 1930px) and (max-width: 3000px) {
  .tell-your-story {
    .main-section {
      .return-btn {
        border-radius: 6rem !important;
        font-size: 1.8rem !important;
        height: 4rem;
        min-width: 14.5rem;
      }
      .story-top {
        .input-part {
          p {
            font-size: clamp(1.125rem, 1.2vw, 1.8rem);
          }
          .input-width {
            width: clamp(31.25rem, 32vw, 55rem);
            .input-field-outline {
              margin-top: 0;
              height: 85px;
            }
          }
        }
      }
    }
    .buttons {
      .buttons-position {
        .lightbtn,
        .bluebtn {
          font-size: 1.016vw !important;
          margin-right: 0.781vw !important;
          padding: 15px 24px !important;
          border-radius: 18px !important;
        }
      }
    }
  }
}

@media screen and (max-width: 1300px) {
  .tell-your-story {
    .main-section {
      .story-top {
        column-gap: 2rem;
        .input-part {
          column-gap: 20px;
        }
      }
    }
  }
}

@media screen and (min-width: 800px) and (max-width: 1100px) {
  .tell-your-story {
    .main-section {
      .story-top {
        .input-part {
          .input-width {
            width: 24rem;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .tell-your-story {
    padding: 0 1.5rem;
    .welcominnercard {
      margin-left: 0;
      margin-right: 0;
    }
    .main-section {
      .return-btn {
        margin-left: 0;
        margin-right: 0 !important;
      }
      .story-top {
        display: flex;
        flex-direction: column;
        row-gap: 1.9rem;
        .input-part {
          flex-direction: column;
          align-items: flex-start;
          column-gap: 1rem;
          .input-width {
            width: 100%;
          }
          p {
            font-family: $asap-font-regular;
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 21px;
            color: #2f2b23;
          }
        }
      }
      .story-detail {
        margin-top: 3rem;
        .search-engine-section {
          .input-title {
            .meta-info {
              .input-width,
              .input-textarea {
                width: 100%;
              }
            }
          }
        }
      }
    }
    .buttons {
      margin-top: 40px;
      .buttons-position {
        margin-right: 0;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .tell-your-story {
    margin: 0 1.5rem;
    margin-bottom: 8vw;
    .welcominnercard {
      margin-left: 0;
      margin-right: 0;
      padding: 10.6vw 4.8vw 5.6vw;
    }
    .main-section {
      .return-btn {
        margin-left: 0;
        margin-right: 0 !important;
      }
      .story-top {
        display: flex;
        flex-direction: column;
        row-gap: 1.9rem;
        .input-part {
          flex-direction: column;
          align-items: flex-start;
          column-gap: 1rem;
          .input-width {
            width: 100%;
          }
          p {
            font-family: $asap-font-regular;
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 21px;
            color: #2f2b23;
          }
        }
      }
    }
    .buttons {
      margin-top: 40px;
      .buttons-position {
        margin-right: 0;
      }
    }
  }
}
