.PendingOrders {
  padding: 15px;
  // background-color: rgb(245, 245, 245);

  .product {
    font-size: 22px;
    font-weight: 600;
    color: #17a2b8;
    margin-top: 6px;
    margin-bottom: 15px;
    width: auto;
    padding: 15px 10px 0px 15px;
    font-style: italic;
  }

  .addproduct {
    background-color: #ffff;
    height: 60px;
    margin: 0px;
    box-shadow: 0px 2px 1px -3px #ccc;
  }

  .table-container {
    background-color: #ffff;
    margin: 20px 0px 20px 0px;
    height: auto;
    box-shadow: 0px 2px 1px -3px #ccc;
  }

  table {
    width: 100%;
    margin: 20px 20px 10px 20px;
    padding-bottom: 20px;
  }

  .pagination {
    justify-content: start;
  }

  .hedding-name {
    color: #060707;
    font-size: 15px;

    th {
      font-size: 21px;
      line-height: 25px;
      text-align: left;
      font-weight: 500;
      font-family: $asap-font-regular;
    }
  }

  // .table{
  //     padding-left: 24px;
  // }
  .product-name {
    color: #666;
    font-size: 17px;
    margin-right: 20px;
    width: 100%;
    white-space: initial;
    font-weight: 500;
  }
  a {
    color: #17a2b8 !important;
    text-decoration: none !important;
  }
  .icon-visibility {
    height: 29px;
    width: 29px;
    box-shadow: 0px 1px 0px 0px #ccc !important;
    border-radius: 4px;
    color: #302b2b;
    text-align: center;
    background-color: #ccc;
    margin-top: 5px;
    margin-bottom: 4px;
    cursor: pointer;
    padding: 4px;
  }
  .icon-visibility:hover {
    background-color: #17a2b8;
    color: #ffffff;
  }
}
@media (min-width: 280px) and (max-width: 600px) {
  .PendingOrders {
    padding: 15px 0;
    .product-name td,
    .hedding-name th {
      padding: 2px 5px;
    }
    .product-name {
      color: #666;
      font-size: 13px;
    }
    .hedding-name th {
      font-size: 16px;
      line-height: 20px;
    }
    .table-container {
      overflow: auto;
    }
  }
}
@media (min-width: 2000px) and (max-width: 6000px) {
  .PendingOrders {
    .addproduct {
      height: auto;
    }
    .product {
      font-size: 1vw;
      padding: 0.6vw;
    }
    .hedding-name th {
      font-size: 1vw;
      line-height: 1.2vw;
    }
    .product-name {
      font-size: 0.8vw;
      line-height: 2vw;
      width: 100%;
      padding: 0.5vw;
    }
    .table-container {
      padding: 0.5vw;
    }
  }
}
