.popularcategory {
  .category-heading {
    font-size: 24px;
    font-weight: 500;
    color: #444;
    margin: 0px;
    width: auto;
    padding: 0px;
  }
  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }

  td,
  th {
    border-bottom: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }

  tr:nth-child(even) {
    // background-color: #dddddd;
  }
  .main-heading {
    font-size: 24px;
    font-weight: 500;
    color: #444;
    margin: 0px;
    width: auto;
    padding: 0px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    text-align: left;
    padding: 15px 0;
  }
  .cat-section {
    background-color: #ffff;
    margin: 10px 0px 20px 0px;
    height: auto;
    border-top: 3px solid #3c8dbc;
    .MuiPaper-elevation.MuiPaper-rounded {
      padding: 20px 0;
    }
    .MuiOutlinedInput-root {
      margin: 12px 0;
    }
  }
  table thead th {
    font-weight: bold;
    color: #000;
  }
  .MuiTypography-h6 {
    font-weight: bold;
  }
  .drop-title {
    width: 95%;
    margin: 0 auto;
  }
  .hide {
    display: none;
  }
}

@media (min-width: 2000px) and (max-width: 6000px) {
  .popularcategory {
    .main-heading {
      font-size: 1.2vw;
      line-height: 1.3vw;
      margin: 0.5vw 0;
    }
    table th {
      font-size: 0.8vw;
      line-height: 1.4vw;
      padding: 0.5vw;
    }
    .MuiTypography-h6 {
      font-size: 1vw;
      line-height: 1.2vw;
    }
    .droptitl {
      margin-top: 0.5vw;
      margin-bottom: 0.5vw;
    }
  }
}
