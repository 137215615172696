.site-link-page {
  margin-top: 2rem;
  margin-bottom: 2.5rem;

  .welcominnercard {
    margin-bottom: 36px !important;
    padding: 5.278vw 3.889vw;
    min-height: 0;

    .MuiGrid-root {
      .MuiGrid-root {
        padding-top: 0 !important;

        .welcome-new-text {
          font-family: 'Inter-Medium';
          font-weight: 500;
        }

        .welcome-para {
          font-weight: 400 !important;
          font-family: 'Inter-Regular' !important;
        }
      }
    }
  }

  .main-section {
    .section-editor {
      display: flex;
      margin-bottom: 3rem;
      align-items: end;
      column-gap: 4.688rem;

      .return-btn {
        height: 3.25rem;
        min-width: 8.5rem;
        border-radius: 3.25rem !important;
        font-size: 1.125rem !important;
        font-family: 'Inter-Medium' !important;
        font-style: normal !important;
        font-weight: 500 !important;
        margin-bottom: 0;
      }

      .input-part {
        p {
          font-family: $asap-font-regular;
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 21px;
          margin-bottom: 7px;
          color: #2f2b23;
        }

        .input-width {
          width: 31.5rem;

          .input-field-outline {
            height: 2.375rem;

            &::placeholder {
              color: #a6a0a0 !important;
            }
          }
        }
      }
    }

    .link-toggle-btn {
      margin-bottom: 2rem;
      width: 100%;
      text-align: center;

      .logs-toggle {
        box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.2);
        border-radius: 25px;

        button {
          border: none;
          padding: 20px 24px;
          font-size: 1.125rem !important;
          font-family: 'Inter-Medium' !important;
          font-style: normal !important;
          font-weight: 500 !important;
          text-transform: capitalize !important;
        }

        .left-btn {
          border-radius: 25px;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }

        .right-btn {
          border-radius: 25px;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }

        .Mui-selected {
          color: rgba(255, 255, 255, 1);
          background-color: #1565c0;
        }
      }
    }

    .search-engine-section {
      padding: 1.75rem;

      .input-title {
        margin-top: 0;

        .meta-info {
          .meta-title {
            // flex-direction: column;
            margin-bottom: 0;
          }
        }
      }
    }

    .add-product {
      p {
        font-family: $asap-font-regular;
        font-style: normal;
        font-weight: 600;
        font-size: 1.125rem;
        line-height: 21px;
        color: #2f2b23;
        margin-bottom: 1rem;
      }

      .search-input {
        margin: 0;
        width: 31.5rem !important;

        .MuiInputBase-root {
          width: 100%;
        }
      }
    }

    .link-filter {
      .search-engine-section {
        .input-title {
          .MuiPaper-root {

            .css-o4b71y-MuiAccordionSummary-content.Mui-expanded,
            .css-17o5nyn.Mui-expanded {
              margin: 0;
            }

            .MuiCollapse-root {
              .ethical-dropdown {
                width: 840px;
              }

              .meta-info {
                .category-dropdown {
                  flex-direction: column;
                  gap: 20px;
                }

                .meta-title {
                  margin-top: 0;

                  .input-width {
                    margin-bottom: 1.6rem;
                  }

                  .categories-drop-down {
                    width: 840px;

                    .selectbox {
                      width: 100%;

                      .MuiInputBase-root {
                        width: 100% !important;
                        background-position-y: center;
                        background-position-x: 98%;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      .input-title {
        .MuiAccordionSummary-content {
          display: flex;
          align-items: center;
        }

        .MuiCollapse-root {
          .meta-info {
            .meta-title {
              margin-top: 1.6rem;
            }

          }
        }
      }

      .meta-title {
        .external-link {
          margin-bottom: 1rem;
        }
      }

      //product-search css on all need to be include all css file
      .product-list {
        .product-gallery {
          .MuiAutocomplete-root {
            .MuiFormControl-root {
              .MuiInputBase-root {
                .MuiAutocomplete-endAdornment {
                  top: 50%;
                  transform: translateY(-50%);

                  .MuiButtonBase-root {
                    .MuiIconButton-label {
                      .MuiSvgIcon-root {
                        width: 20px !important;
                        height: 20px !important;
                      }
                    }
                  }
                }
              }
            }
          }

          .img-container {
            .MuiSvgIcon-root {
              position: absolute;
              top: 10px;
              left: 10px;
              background: #2F2B23;
              border-radius: 50%;
              width: 30px;
              height: 30px;
              padding: 5px;
              color: #fff;
              cursor: pointer;

            }
          }

        }
      }

      .external-link-container {
        margin-right: 16px;
      }
    }
  }

  .buttons {
    width: 100%;
    margin-top: 70px;

    .buttons-position {
      display: flex;
      justify-content: flex-end;
      margin-right: 130px;

      .lightbtn {
        margin-right: 30px !important;
      }
    }
  }
}

.search-input {
  .icon-wrapper {
    padding: 0 10px;
  }

  .MuiInputBase-root {
    .MuiInputBase-input {
      padding-left: calc(1em + 24px);
    }
  }
}

@media screen and (min-width: 3000px) and (max-width: 6000px) {
  .site-link-page {
    padding-left: 60px;

    .main-section {
      .section-editor {
        .return-btn {
          border-radius: 6rem !important;
          font-size: 1.8rem !important;
          height: 4rem;
          min-width: 14.5rem;
        }

        .input-part {
          p {
            font-size: 2rem;
          }

          .input-width {
            width: 1000px;

            .input-field-outline {
              height: 70px;
            }
          }
        }
      }

      .add-product {
        p {
          font-size: 2rem;
        }

        .search-input {
          width: 900px !important;
          height: 85px;
        }
      }

      .link-filter {
        .external-link-container {
          .meta-title {
            .external-link {
              font-size: 2.1rem;
            }

            .input-width {

              .input-field-outline,
              .input-textarea {
                font-size: 2.1rem;
              }
            }
          }
        }
      }
    }

    .buttons {
      .buttons-position {

        .lightbtn,
        .bluebtn {
          font-size: 1.016vw !important;
          margin-right: 0.781vw !important;
          padding: 15px 24px !important;
          border-radius: 18px !important;
        }
      }
    }
  }
}

@media screen and (min-width: 1930px) and (max-width: 3000px) {
  .site-link-page {
    .main-section {
      .section-editor {
        .return-btn {
          height: clamp(3.25rem, 3.5vw, 5rem);
          min-width: clamp(8.5rem, 11.5vw, 18rem);
          border-radius: 3.25rem !important;
          font-size: clamp(1.125rem, 1.2vw, 1.8rem) !important;
        }

        .input-part {
          p {
            font-size: clamp(1.125rem, 1.2vw, 1.8rem);
          }

          .input-width {
            width: clamp(504px, 38vw, 950px);

            .input-field-outline {
              height: clamp(38px, 3vw, 60px);
            }
          }
        }
      }

      .add-product {
        p {
          font-size: clamp(1.125rem, 1.2vw, 1.8rem);
        }

        .search-input {
          width: 900px !important;
          height: 85px;
        }
      }
    }

    .link-filter {
      .external-link-container {
        .meta-title {
          .external-link {
            font-size: 1.6rem;
          }

          .input-width {

            .input-field-outline,
            .input-textarea {
              font-size: 1.6rem;
            }
          }
        }
      }
    }

    .buttons {
      .buttons-position {

        .lightbtn,
        .bluebtn {
          font-size: 1.016vw !important;
          margin-right: 0.781vw !important;
          padding: 15px 24px !important;
          border-radius: 18px !important;
        }
      }
    }
  }
}

@media screen and (min-width: 1930px) {
  .site-link-page {
    .main-section {
      .link-filter {
        .search-engine-section {
          .input-title {
            .MuiPaper-root {
              .MuiCollapse-root {
                .meta-info {
                  .meta-title {
                    .categories-drop-down {
                      width: 100%;
                    }
                  }
                }

                .ethical-dropdown {
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }
  }

}

@media screen and (min-width: 200px) and (max-width: 1100px) {
  .site-link-page {
    .main-section {
      .link-filter {
        .search-engine-section {
          .input-title {
            .MuiPaper-root {
              .MuiCollapse-root {
                .meta-info {
                  .meta-title {
                    .categories-drop-down {
                      width: 100%;
                    }
                  }
                }

                .ethical-dropdown {
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 800px) and (max-width: 920px) {
  .site-link-page {
    .container-27inch {
      padding-left: 16px;
    }
  }
}

@media screen and (max-width: 800px) {
  .site-link-page {
    margin: 0 1.5rem;
    margin-bottom: 8vw;

    .welcominnercard {
      margin-left: 0;
      margin-right: 0;
      padding: 5.6vw 4.8vw 5.6vw;
    }

    .main-section {
      padding-left: 0;

      .add-product {
        .search-input {
          width: 100% !important;
        }
      }

      .link-filter {
        .product-list {
          .product-gallery {
            .img-container {
              width: 33.333%;
            }

          }
        }
        .external-link-container{
          margin-right: 0;
        }
      }

      .section-editor {
        flex-direction: column;
        align-items: inherit;
        row-gap: 2rem;
        margin-left: 0;

        .input-part {
          .input-width {
            width: 100%;

            .input-field-outline {
              height: 2.375rem;
            }
          }
        }
      }
    }

    .buttons {
      width: 100%;
      margin-top: 40px;

      .buttons-position {
        margin-right: 0;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .site-link-page {
    margin: 0 1.5rem;
    margin-bottom: 8vw;

    .welcominnercard {
      margin-left: 0;
      margin-right: 0;
      padding: 9.347vw 4.889vw;
    }

    .main-section {
      padding-left: 0;

      .link-filter {
        .product-list {
          .product-gallery {
            .img-container {
              width: 50%;
            }

          }
        }
      }

      .add-product {
        .search-input {
          width: 100% !important;
        }
      }

      .section-editor {
        flex-direction: column;
        align-items: inherit;
        row-gap: 2rem;
        margin-left: 0;

        .input-part {
          .input-width {
            width: 100%;

            .input-field-outline {
              height: 2.375rem;
            }
          }
        }
      }
    }

    .buttons {
      width: 100%;
      margin-top: 40px;

      .buttons-position {
        margin-right: 0;
      }
    }
  }
}