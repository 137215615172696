.BuyerIdDetails {
  padding: 15px;
  // background-color: rgb(245, 245, 245);

  .user-heading {
    font-size: 24px;
    color: #444;
    margin: 0px;
    width: auto;
    padding: 0px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    text-align: left;
  }

  .email {
    margin: 0px 0px 15px 0px;
    font-size: 18px;
    line-height: 22px;
    color: #333;
    font-weight: 700;
    font-family: $asap-font-regular;
  }

  .mail-text {
    margin: 0px 0px 15px 0px;
    font-size: 18px;
    line-height: 22px;
    color: #333;
    font-family: 'Poppins';

    li {
      font-size: 16px;
      line-height: 18px;
    }
  }

  .img-card {
    height: 200px;
    width: 320px;
    margin-bottom: 20px;
  }

  .profile-img {
    width: 100%;
    height: 100%;
  }

  .active-text {
    margin: 0px 0px 10px 0px;
    font-size: 17px;
    color: #3c8dbc;
  }

  .profile-detail {
    padding-left: 32px;
    background-color: #ffff;
    margin: 10px 0px 20px 0px;
    height: auto;
    border-top: 3px solid #3c8dbc;
    padding: 15px 25px 25px;
  }

  .darkbtn {
    background-color: #ddd !important;
    border-radius: 3px !important;
    box-shadow: none !important;
    border: 1px solid #ccc !important;
    color: #333 !important;
  }
}
@media (min-width: 2000px) and (max-width: 6000px) {
  .BuyerIdDetails {
    .user-heading {
      font-size: 1.4vw;
      padding: 0.5vw 0;
      line-height: 1.6vw;
    }
    .email {
      margin: 0px 0px 0.5vw 0px;
      font-size: 1vw;
      line-height: 1.4vw;
    }
    .mail-text {
      margin: 0px 0px 0.5vw 0px;
      font-size: 0.8vw;
      line-height: 1.2vw;
    }
    .mail-text li {
      font-size: 0.8vw;
      line-height: 1.2vw;
    }
    .active-text {
      margin: 0px 0px 0.4vw 0px;
      font-size: 0.8vw;
      line-height: 1.2vw;
    }
    .profile-detail {
      padding-left: 32px;
      margin: 0.6vw 0px 0.9vw 0px;
      padding: 1.2vw;
    }
    .lightbtn,
    .darkbtn {
      border-radius: 0.5vw !important;
      padding: 0.4vw 0.8vw !important;
      font-size: 0.8vw !important;
    }
  }
}
