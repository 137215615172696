.feedback,
.testimonials,
.instagram {
  margin-top: 2rem;
  margin-bottom: 2.5rem;

  .insta-search {
    display: flex;
    // justify-content: space-between;
    align-items: center;
  }

  .welcominnercard {
    margin-bottom: 36px !important;
    padding: 5.278vw 3.889vw;
    min-height: 0;
  }

  .feedback-btn {
    display: flex;
    margin-bottom: 1.6rem;
    align-items: center;

    .bluebtn {
      margin-bottom: 0;
    }

    .search-input {
      margin-bottom: 0;
    }
  }

  .return-btn,
  .collection-btn,
  .bluebtn {
    height: 3.25rem;
    min-width: 8.5rem;
    border-radius: 3.25rem !important;
    font-size: 1.125rem !important;
    margin-bottom: 2.188rem;
    margin-left: 2.5rem;
    font-family: 'Inter-Medium' !important;
    font-style: normal !important;
    font-weight: 500 !important;
  }

  .search-input {
    margin-bottom: 1.6vw;
  }

  .site-table {
    background: #f8fafd;
    padding: 0 48px;
    margin-right: 16px;
    border-radius: 10px;
  }
}

.testimonials {
  .testimonial-btn-left {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .btn-button {
      .bluebtn {
        margin-bottom: 0;
      }
    }

    .search-box {
      .search-input {
        margin-bottom: 0;
      }
    }

    margin-bottom: 1.6rem;
  }
}

.instagram {
  .insta-search {
    margin-bottom: 1.6rem;
    justify-content: space-between;

    .insta-btn {
      .bluebtn {
        margin-bottom: 0;
      }
    }
  }

  .search-box {
    .search-input {
      margin-bottom: 0;
    }
  }
}

.feedback {
  .feedback-btn {
    justify-content: space-between;
  }
}

@media screen and (min-width: 1930px) and (max-width: 6000px) {

  .feedback,
  .testimonials,
  .instagram {

    .return-btn,
    .bluebtn,
    .collection-btn {
      height: clamp(3.25rem, 3.5vw, 5rem);
      min-width: clamp(8.5rem, 11.5vw, 18rem);
      border-radius: 3.25rem !important;
      font-size: 2.125rem !important;
    }

    .collection-btn {
      min-width: 0;
      width: fit-content;
      padding-left: 34px !important;
      padding-right: 34px !important;
    }
  }
}

@media screen and (min-width: 1930px) {

  .instagram,
  .feedback {
    .insta-search {
      .bluebtn {
        border-radius: 6rem !important;
        font-size: 1.8rem !important;
        height: 4rem;
        min-width: 14.5rem;
      }

      div {
        .search-input {
          .MuiInputBase-root {
            height: 100%;
            width: 100%;
          }
        }
      }
    }

    .feedback-btn {
      margin-bottom: 2rem;

      .bluebtn {
        border-radius: 6rem !important;
        font-size: 1.8rem !important;
        height: 4rem;
        min-width: 14.5rem;
      }
    }
  }

  .instagram .search-input {
    margin-bottom: 0;
  }
}

@media screen and (min-width: 800px) and (max-width: 921px) {

  .feedback,
  .testimonials,
  .instagram {
    .container-27inch {
      .site-table {
        margin-left: 16px;
      }
    }
  }
}

@media screen and (max-width: 800px) {

  .feedback,
  .testimonials {
    margin: 0 1.5rem;
    margin-bottom: 8vw;

    .welcominnercard {
      margin-left: 0;
      margin-right: 0;
      padding: 10.6vw 4.8vw 5.6vw;
      margin-bottom: 36px !important;
      min-height: 0;
    }

    .return-btn,
    .bluebtn {
      margin-left: 0;
      width: 100%;
    }

    .site-table {
      margin-right: 0;
      padding: 0 16px;
      background: #f8fafd;
      border-radius: 10px;
    }
  }

  .testimonials .testimonial-btn-left {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 15px;

    .btn-button {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 15px;
    }

    .search-box {
      width: 100%;

      .search-input {
        margin: 0;
        width: 100% !important;

        .MuiInputBase-root {
          width: 100%;
        }
      }
    }
  }

  .instagram {

    .welcominnercard {
      margin-left: 16px;
    }

    .container-27inch {
      margin-left: 16px;
      margin-right: 16px;

      .site-table {
        margin-right: 0;
      }
    }

    .insta-search {
      flex-direction: column;
      gap: 15px;
      width: 100%;

      .search-box {
        width: 100%;

        .search-input {
          width: 100% !important;
          margin-left: 0;

          .MuiInputBase-root {
            width: 100%;
          }
        }
      }

      .insta-btn {
        display: flex;
        flex-direction: column;
        gap: 15px;
        width: 100%;

        .bluebtn {
          margin-left: 0;
          margin-right: 0 !important;
        }
      }
    }
  }

  .feedback {
    .return-btn {
      width: fit-content;
    }
  }
}



@media screen and (max-width: 600px) {
  .feedback {
    margin: 0 1.5rem;
    margin-bottom: 8vw;

    .welcominnercard {
      margin-left: 0;
      margin-right: 0;
      padding: 10.6vw 4.8vw 5.6vw;
      margin-bottom: 36px !important;
      min-height: 0;
    }

    .feedback-btn {
      flex-direction: column;

      .bluebtn {
        width: 100%;
        margin-bottom: 1.6rem;
      }

      .search-box {
        width: 100%;

        .search-input {
          width: 100% !important;

          .MuiInputBase-root {
            width: 100%;
          }
        }
      }
    }

    .return-btn {
      margin-left: 0;
      width: 100%;
    }

    .site-table {
      margin-right: 0;
      padding: 0 16px;
      background: #f8fafd;
      border-radius: 10px;
    }
  }

  .instagram {

    .welcominnercard {
      margin-left: 16px;
    }

    .container-27inch {
      margin-left: 16px;
      margin-right: 16px;

      .site-table {
        margin-right: 0;
      }
    }

    .insta-search {
      flex-direction: column;
      gap: 15px;
      width: 100%;

      .search-box {
        width: 100%;

        .search-input {
          width: 100% !important;
          margin-left: 0;

          .MuiInputBase-root {
            width: 100%;
          }
        }
      }

      .insta-btn {
        display: flex;
        flex-direction: column;
        gap: 15px;
        width: 100%;

        .bluebtn {
          margin-left: 0;
          margin-right: 0 !important;
        }
      }
    }
  }
}