.top-banner {
  margin-top: 2rem;
  margin-bottom: 2.5rem;

  .dropdown {
    width: 700px;

    .MuiBox-root {
      width: 100% !important;
    }
  }

  .welcominnercard {
    margin-bottom: 36px !important;
    padding: 5.278vw 3.889vw;
    min-height: 0;

    .MuiGrid-root {
      .MuiGrid-root {
        padding-top: 0 !important;

        .welcome-new-text {
          font-family: 'Inter-Medium';
          font-weight: 500;
        }

        .welcome-para {
          font-weight: 400 !important;
          font-family: 'Inter-Regular' !important;
        }
      }
    }
  }

  .main-section {
    .return-btn {
      height: 3.25rem;
      min-width: 8.5rem;
      border-radius: 3.25rem !important;
      font-size: 1.125rem !important;
      margin-bottom: 2.188rem;
      margin-left: 2.5rem;
      font-family: 'Inter-Medium' !important;
      font-style: normal !important;
      font-weight: 500 !important;
    }

    .trending-detail {
      .search-engine-section {
        .input-title {
          margin-top: 0;

          .meta-des {
            display: flex;

            p {
              width: 143px;
              font-family: "Asap";
              font-style: normal;
              font-weight: 500;
              font-size: 15.757px;
              line-height: 18px;
              color: #44423d;
            }

            .dropdown {
              .MuiBox-root {
                width: 700px;

                .select-field {
                  background-color: #fff;
                  background-position-y: center;

                  .MuiSelect-select {
                    min-height: 0;
                  }
                }
              }
            }
          }

        }
      }
    }

    .buttons {
      width: 100%;
      margin-top: 70px;

      .buttons-position {
        display: flex;
        justify-content: flex-end;
        margin-right: 130px;

        .lightbtn {
          margin-right: 30px !important;
        }
      }
    }
  }
}

@media screen and (min-width: 3000px) and (max-width: 6000px) {
  .top-banner {
    .main-section {
      .return-btn {
        border-radius: 6rem !important;
        font-size: 1.8rem !important;
        margin-bottom: 4rem;
        height: 4rem;
        min-width: 14.5rem;
      }

      .buttons {
        .buttons-position {

          .lightbtn,
          .bluebtn {
            font-size: 1.016vw !important;
            margin-right: 0.781vw !important;
            padding: 15px 24px !important;
            border-radius: 18px !important;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1930px) {
  .top-banner .dropdown {
    width: 55vw;
  }

  .top-banner.search-engine-section h4 {
    font-size: 30px;
  }

  .top-banner {
    .main-section {
      .trending-detail {
        .search-engine-section {
          .input-title {

            .meta-title,
            .meta-des {
              p {
                font-size: clamp(1.125rem, 1.2vw, 1.8rem);
                width: clamp(170px, 13vw, 315px);
              }
            }
          }

          .circle-btn button svg {
            width: 40px !important;
            height: 100% !important;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1930px) and (max-width: 3000px) {
  .top-banner {
    .main-section {
      .return-btn {
        height: clamp(3.25rem, 3.5vw, 5rem);
        min-width: clamp(8.5rem, 11.5vw, 18rem);
        border-radius: 3.25rem !important;
        font-size: clamp(1.125rem, 1.2vw, 1.8rem) !important;
      }

      .buttons {
        .buttons-position {

          .lightbtn,
          .bluebtn {
            font-size: 1.016vw !important;
            margin-right: 0.781vw !important;
            padding: 15px 24px !important;
            border-radius: 18px !important;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 600px) and (max-width: 1024px) {
  .top-banner {
    .dropdown {
      width: 50vw;

      .MuiBox-root {
        width: 100% !important;
      }
    }

    .main-section {
      .trending-detail {
        .search-engine-section {
          .input-title {
            .meta-des {
              .dropdown {
                .MuiBox-root {
                  width: 50vw;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .top-banner {
    .dropdown {
      width: 100%;

      .MuiBox-root {
        width: 100% !important;
      }
    }

    .main-section {
      .trending-detail {
        .search-engine-section {
          .input-title {
            .meta-des {
              flex-direction: column;
              row-gap: 1.5rem;

              .dropdown {
                .select-field {
                  background-position: 95%;
                }

                .MuiBox-root {
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }
  }

  .top-banner {
    margin: 0 1.5rem;
    margin-bottom: 8vw;

    .welcominnercard {
      margin-left: 0;
      margin-right: 0;
    }

    .main-section {
      .return-btn {
        margin-left: 0;
        width: 100%;
      }

      .buttons {
        width: 100%;
        margin-top: 40px;

        .buttons-position {
          margin-right: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .top-banner {
    margin: 0 1.5rem;
    margin-bottom: 8vw;

    .welcominnercard {
      margin-left: 0;
      margin-right: 0;
      padding: 10.6vw 4.8vw 5.6vw;
    }

    .main-section {
      .trending-detail {
        .search-engine-section {
          .input-title {
            .meta-des {
              flex-direction: column;
              row-gap: 1.5rem;
            }
          }
        }
      }

      .return-btn {
        margin-left: 0;
        width: 100%;
      }

      .buttons {
        width: 100%;
        margin-top: 40px;

        .buttons-position {
          margin-right: 0;
        }
      }
    }
  }
}