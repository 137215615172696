.drag-container {
  display: flex;
  column-gap: 15px;
  margin-right: 16px;
  margin-bottom: 15px;
  .draggable-update-card {
    background-color: #f8fafd;
    width: 100%;
    display: flex;
    align-items: center;
    border-radius: 25px;
    .update-image-card {
      border-radius: 25px;
      overflow: hidden;
      width: 500px;
      height: 18.75rem;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #d9d9d9;
      flex-direction: column;
      cursor: pointer;
      flex: 1;
      .upload-icon {
        margin-bottom: 1.125rem;
        width: 35px;
        height: 35px;
      }
      .upload-img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      p {
        text-align: center;
        font-size: 1rem;
        line-height: 1.25rem;
        color: #2f2b23;
        font-family: 'Asap';
        font-style: normal;
        font-weight: 400;
        a {
          color: #2f2b23;
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
    .upload-description-card {
      padding: 1.5rem;
      // cursor: grab;
      flex: 1;
      h3 {
        font-size: 23px;
        line-height: 32px;
        margin-bottom: 12px;
        font-family: 'Inter-Medium';
        font-style: normal;
        font-weight: 500;
        color: #1f1f1f;
      }
      p {
        font-size: 15px;
        line-height: 24px;
        font-family: 'Inter-Regular';
        font-style: normal;
        font-weight: 500;
        color: #1f1f1f;
        margin-bottom: 59px;
      }
      .dropdown {
        width: fit-content;
        .select-field {
          border: 1px solid #d8d4cf;
          background-color: #fff;
          background: url('../../../assets/images/siteEditor/dropdown-arrow.png') 99%/16px no-repeat;
        }
      }
    }
  }
  .upload-arrow-card {
    background-color: #f8fafd;
    width: 121px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 25px;
  }
}

@media screen and (min-width: 3000px) and (max-width: 6000px) {
  .drag-container {
    column-gap: 40px;
    margin-bottom: 40px;
    .draggable-update-card {
      border-radius: 35px;
      .update-image-card {
        border-radius: 35px;
        height: 28rem;
        .upload-icon {
          margin-bottom: 2rem;
          width: 65px;
          height: 65px;
        }
        p {
          font-size: 2rem;
          line-height: 3rem;
        }
      }
      .upload-description-card {
        padding: 2.5rem;
        h3 {
          font-size: 40px;
          line-height: 50px;
          margin-bottom: 20px;
        }
        p {
          font-size: 32px;
          line-height: 40px;
          margin-bottom: 59px;
        }
      }
    }
    .upload-arrow-card {
      width: 121px;
      border-radius: 25px;
    }
  }
}

@media screen and (min-width: 1930px) and (max-width: 3000px) {
  .drag-container {
    .draggable-update-card {
      .update-image-card {
        width: calc(80vw - 50%);
        height: clamp(18rem, 16vw, 25rem);
        .upload-icon {
          width: 3.5rem;
          height: auto;
        }
        p {
          font-size: clamp(16px, 2.5vw, 1.25rem);
          line-height: clamp(1.5rem, 2.5vw, 2rem);
        }
      }
      .upload-description-card {
        h3 {
          font-size: clamp(1.5rem, 2.5vw, 3rem);
          line-height: clamp(2.5rem, 2.5vw, 4.5rem);
          margin-bottom: 1.25rem;
        }
        p {
          font-size: clamp(16px, 2.5vw, 1.25rem);
          line-height: clamp(1.5rem, 2.5vw, 2rem);
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .drag-container {
    flex-direction: column;
    row-gap: 1rem;
    margin-right: 0;
    .draggable-update-card {
      display: block;
      .update-image-card {
        width: 100%;
        height: 20rem;
      }
      .upload-description-card {
        h3 {
          font-size: 23px;
          line-height: 32px;
          margin-bottom: 12px;
          text-align: center;
        }
        p {
          font-size: 15px;
          line-height: 24px;
          text-align: center;
        }
        .dropdown {
          width: 100%;
        }
      }
    }
    .upload-arrow-card {
      width: 100%;
      flex-direction: row;
      padding: 1rem 0;
    }
  }
}

@media screen and (max-width: 600px) {
  .drag-container {
    flex-direction: column;
    row-gap: 1rem;
    margin-right: 0;
    .draggable-update-card {
      display: block;
      .update-image-card {
        width: 100%;
        height: 200px;
        padding: 40px 0;
      }
      .no-padding{
        padding: 0;
      }
      .upload-description-card {
        h3 {
          font-size: 23px;
          line-height: 32px;
          margin-bottom: 12px;
          text-align: center;
        }
        p {
          font-size: 15px;
          line-height: 24px;
          text-align: center;
          margin-bottom: 0;
        }
        .dropdown {
          width: 100%;
        }
      }
    }
    .upload-arrow-card {
      width: 100%;
      flex-direction: row;
      padding: 1rem 0;
    }
  }
}
