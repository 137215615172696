.site-size {
    margin-top: 2rem;
    margin-bottom: 2.5rem;

    .welcominnercard {
        margin-bottom: 36px !important;
        padding: 5.278vw 3.889vw;
        min-height: 0;

        .MuiGrid-root {
            .MuiGrid-root {
                padding-top: 0 !important;

                .welcome-new-text {
                    font-family: 'Inter-Medium';
                    font-weight: 500;
                }

                .welcome-para {
                    font-weight: 400 !important;
                    font-family: 'Inter-Regular' !important;
                }
            }
        }
    }

    .size-btn {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 2.188rem;
    }

    .return-btn,
    .collection-btn {
        height: 3.25rem;
        min-width: 8.5rem;
        border-radius: 3.25rem !important;
        font-size: 1.125rem !important;
        margin-left: 2.5rem;
        font-family: 'Inter-Medium' !important;
        font-style: normal !important;
        font-weight: 500 !important;
    }

    .collection-btn {
        width: fit-content;
        padding-left: 24px !important;
        padding-right: 24px !important;
    }

    .site-table {
        background: #f8fafd;
        padding: 0 48px;
        margin-right: 16px;
        border-radius: 10px;

        .actionbtn {
            .edit {
                justify-content: flex-start;

                .MuiButtonBase-root {
                    padding: 10px 0;
                }
            }
        }
    }

    .buttons {
        width: 100%;
        margin-top: 70px;

        .buttons-position {
            display: flex;
            justify-content: flex-end;
            margin-right: 130px;

            .lightbtn {
                margin-right: 30px !important;
            }
        }
    }
}

@media screen and (min-width: 3000px) and (max-width: 6000px) {
    .site-size {

        .size-btn {
            .site-size-btn {

                .return-btn,
                .collection-btn {
                    height: clamp(3.25rem, 3.5vw, 5rem);
                    min-width: clamp(8.5rem, 11.5vw, 18rem);
                    border-radius: 3.25rem !important;
                    font-size: clamp(1.125rem, 1.2vw, 1.8rem) !important;
                }

                .collection-btn {
                    min-width: clamp(8.5rem, 16.5vw, 26rem);
                }
            }
        }

        .buttons {
            .buttons-position {

                .lightbtn,
                .bluebtn {
                    font-size: 1.016vw !important;
                    margin-right: 0.781vw !important;
                    padding: 15px 24px !important;
                    border-radius: 18px !important;
                }
            }
        }

        .site-table {
            .main-table {
                table {
                    tbody {
                        tr {
                            td {
                                font-size: 0.829vw;
                            }
                        }
                    }
                }
            }

            .main-table {
                table {
                    thead {
                        tr {
                            th {
                                font-size: 0.829vw;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 1930px) and (max-width: 3000px) {
    .site-size {

        .size-btn {
            .site-size-btn {

                .return-btn,
                .collection-btn {
                    height: clamp(3.25rem, 3.5vw, 5rem);
                    min-width: clamp(8.5rem, 11.5vw, 18rem);
                    border-radius: 3.25rem !important;
                    font-size: clamp(1.125rem, 1.2vw, 1.8rem) !important;
                }

                .collection-btn {
                    min-width: clamp(8.5rem, 16.5vw, 26rem);
                }
            }
        }

        .buttons {
            .buttons-position {

                .lightbtn,
                .bluebtn {
                    font-size: 1.016vw !important;
                    margin-right: 0.781vw !important;
                    padding: 15px 24px !important;
                    border-radius: 18px !important;
                }
            }
        }
    }
}

@media screen and (min-width: 1930px) {
    .site-table {
        .actionbtn {
            .circle-btn {
                width: 1.8vw;
                height: 1.8vw;
            }
        }
    }
}

@media screen and (min-width: 800px) and (max-width: 921px) {
    .site-size {
        .site-table {
            margin-left: 16px;
        }
    }
}

@media screen and (max-width: 800px) {
    .site-size {
        margin: 0 1.5rem;
        margin-bottom: 8vw;

        .welcominnercard {
            margin-left: 0;
            margin-right: 0;
        }

        .size-btn {
            .search-box {
                .search-input {
                    margin-right: 0;
                }
            }
        }

        .return-btn,
        .collection-btn {
            margin-left: 0;
        }

        .site-table {
            margin-right: 0;
            padding: 0 16px;
        }

        .buttons {
            margin-top: 40px;

            .buttons-position {
                margin-right: 0;
            }
        }
    }
}

@media screen and (max-width: 700px) {
    .site-size {
        .size-btn {
            flex-direction: column;
            gap: 15px;

            .site-size-btn {
                display: flex;
                flex-direction: column;
                gap: 15px;
                width: 100%;
            }

            .return-btn,
            .collection-btn {
                width: 100%;
                margin-right: 0 !important;
            }

            .search-box {
                width: 100%;

                .search-input {
                    width: 100% !important;
                    margin-left: 0;

                    .MuiInputBase-root {
                        width: 100%;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .site-size {
        margin: 0 1.5rem;
        margin-bottom: 8vw;

        .welcominnercard {
            margin-left: 0;
            margin-right: 0;
            padding: 10.6vw 4.8vw 5.6vw;
        }

        .size-btn {
            flex-direction: column;
            gap: 15px;

            .site-size-btn {
                display: flex;
                flex-direction: column;
                gap: 15px;
                width: 100%;
            }

            .return-btn,
            .collection-btn {
                width: 100%;
                margin-right: 0 !important;
            }

            .search-box {
                width: 100%;

                .search-input {
                    width: 100% !important;
                    margin-left: 0;

                    .MuiInputBase-root {
                        width: 100%;
                    }
                }
            }
        }

        .site-table {
            margin-right: 0;
            padding: 0 16px;
        }

        .buttons {
            margin-top: 40px;

            .buttons-position {
                margin-right: 0;
            }
        }
    }
}