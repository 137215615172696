@import '../abstracts/variables';

// .sellerheader {
//     margin-top: 0px;
//     // margin-bottom: 5px;
//     position: fixed;
//     z-index: 3;
//     background-color: #ffffff;
//     display: initial;
//     padding-left: 17px;
//     padding-right: 40px;
//     width: 100%;
//     padding-top: 10px;
//     margin-bottom: 19px;
//     .MuiSvgIcon-root {
//         margin-top: 6px;
//         margin-left: 9px;
//         width: 31px;
//         height: 31px;
//         color: #FFFFFF !important;
//       }
//     .header-seller {
//         display: flex;
//         // padding-left: 25px;
//         align-items: center;
//     }

//     .logo-img {
//         // width: 270px;
//         width: 250px;
//         image-rendering: -moz-crisp-edges;
//         image-rendering: -o-crisp-edges;
//         image-rendering: -webkit-optimize-contrast;
//         image-rendering: crisp-edges;
//         -ms-interpolation-mode: nearest-neighbor;
//         cursor: pointer;
//     }

//     .login-btn {
//         background-color: #949d8c;
//         width: 128px;
//         border-radius: 28px;
//         color: #ffff;
//         height: 47px;
//         font-family: "Asap";
//         outline: none;
//         border: none;
//         margin-left: 20px;
//         border-radius: 40px;
//         font-size: 16px;
//         cursor: pointer;
//     }

//     .category-section {
//         display: flex !important;
//         align-items: center;
//         justify-content: space-around;
//         position: absolute;
//         right: 30px;
//     }

//     .category-name {
//         text-align: left;
//         font-size: 19px;
//         line-height: 23px;
//         letter-spacing: 0px;
//         color: #2f2b23;
//         opacity: 1;
//         font-family: $asap-font-regular;
//         padding-left: 25px;
//         cursor: pointer;

//     }
// }

// new header

.policydetails {
  .bussiness-tool-tip {
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    gap: 5px;

    .label-text {
      margin-bottom: 0;
    }
  }
}

.bussiness-country-dropdown {
  width: 100%;

  .MuiBox-root {
    .MuiFormControl-root {
      .MuiInputBase-root {
        background-position-y: center;
      }
    }
  }
}

.sellerheader_new {
  background-color: #e0f8f2;
  padding: 0.8vw 5vw 0.933vw 1vw;

  .category-section {
    display: flex;
    // justify-content: space-between;
    align-items: center;
    justify-content: flex-end;
  }

  .login-btn-new {
    margin-right: 3.8vw;
    background: #ffffff;
    border: 2px solid #2e2f35;
    box-shadow: 3px 3px 0px #2e2f35;
    border-radius: 12px;
    cursor: pointer;
    font-size: 0.9722vw;
    line-height: 1.25vw;
    width: 5.8vw;
    height: 3.264vw;
    color: #2e2f35;
    font-family: 'Inter-Medium';
  }

  .create-btn-new {
    background: #ff6d2c;
    border: 2px solid #2e2f35;
    box-shadow: 3px 3px 0px #2e2f35;
    border-radius: 12px;
    cursor: pointer;
    font-size: 0.9722vw;
    line-height: 1.25vw;
    width: 12.777vw;
    height: 3.264vw;
    color: #ffffff;
    font-family: 'Inter-Medium';
  }

  .category-name {
    font-size: 0.9722vw;
    line-height: 1.25vw;
    color: #222222;
    cursor: pointer;
    font-family: 'Inter-Medium';
    margin-right: 2.754vw;
  }

  .subnav {
    float: left;
    overflow: hidden;
  }

  .subnav .subnavbtn {
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    background-color: inherit;
    font-family: 'Inter-Medium';
    margin: 0;
  }

  //   .navbar a:hover, .subnav:hover .subnavbtn {
  //     background-color: red;
  //   }

  .subnav-content {
    display: none;
    position: absolute;
    left: 0;
    width: 100%;
    z-index: 1;
  }

  .subnav-content a {
    float: left;
    color: #2f2b23;
    text-decoration: none;
  }

  .subnav-content a:hover {
    background-color: #949d8c;
    color: black;
  }

  .subnav:hover .subnav-content {
    display: block;
    display: grid;
    padding: 2vw 5vw;
    margin-top: 1vw;
    z-index: 99999;
    background-color: #e0f8f2;
  }

  .subnav-content a {
    margin-bottom: 1vw;
  }

  .subnavbtn {
    svg {
      margin-left: 0.5vw;
    }
  }
}

@media (min-width: 600px) and (max-width: 800px) {
  .sellerheader_new {
    .login-btn-new {
      margin-right: 1rem;
      font-size: 3.3vw;
      line-height: 4vw;
      width: 20.25vw;
      height: 10vw;
    }

    .create-btn-new {
      font-size: 3.3vw;
      line-height: 4vw;
      width: 44vw;
      height: 10vw;
    }
  }
}

@media (min-width: 280px) and (max-width: 600px) {
  .sellerheader_new {
    background-color: #e0f8f2;
    padding: 0.8rem 1rem 0.933rem 1rem;

    .create-btn-new {
      font-size: 3.3vw;
      line-height: 4vw;
      width: 44vw;
      height: 10vw;
    }

    .login-btn-new {
      margin-right: 1rem;
      font-size: 3.3vw;
      line-height: 4vw;
      width: 20.25vw;
      height: 10vw;
    }

    // .mobilemenu {
    //     position: absolute;
    //     right: 0;
    //     top: 12px;
    //     float: right;
    //     width: 77px;
    //     height: 54px;
    //     background-color: #949d8c;
    //     border-radius: 39px;
    //     opacity: 1;
    //     right: 22px;

    //     img {
    //         -webkit-filter: invert(1);
    //         filter: invert(1);
    //     }
    // }

    // .menu-btn {
    //     float: right;
    //     width: 77px;
    //     height: 54px;
    //     background-color: #949d8c;
    //     border-radius: 39px;
    //     opacity: 1;
    //     color: #ffffff;
    //     right: 22px;
    // }

    .mobilemenu {
      svg {
        color: #2e2f35;
      }

      button {
        justify-content: flex-start;
      }
    }

    img.icon-img {
      filter: invert(1);
    }

    .desktopshow {
      display: none !important;
    }

    .MuiPaper-rounded {
      border-radius: none !important;
    }

    .category-name {
      margin-bottom: 15px;
    }

    .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-2 {
      align-items: center;
    }
  }
}

@media (min-width: 601px) and (max-width: 919px) {
  .sellerheader_new {
    padding: 1.8vw 5vw 1.933vw 1vw;

    .category-section {
      justify-content: flex-end;
    }

    .desktopshow {
      display: none !important;
    }

    .login-btn-new {
      margin-right: 1rem;
    }

    .mobilemenu {
      svg {
        color: #2e2f35;
      }

      button {
        justify-content: flex-start;
      }
    }
  }
}

@media (min-width: 920px) and (max-width: 1100px) {
  .sellerheader_new .login-btn-new {
    width: 6.8vw;
    height: 3.55vw;
  }

  .sellerheader_new .create-btn-new {
    width: 13.777vw;
    height: 3.55vw;
  }
}

@media (min-width: 920px) and (max-width: 1280px) {
  .sellerheader_new {
    .category-name {
      font-size: 1.299vw;
      line-height: 1.25vw;
      margin-right: 2vw;
    }

    .logo-img {
      width: 180px;
    }

    .login-btn {
      width: 101px;
      padding: 0;
      height: 45px;
    }
  }
}

@media screen and (min-width: 800px) and (max-width: 1024px) {
  .policydetails {
    .mrgbottom {
      .bussiness-country-dropdown {
        width: 100% !important;
      }
    }
  }
}

@media (min-width: 1921px) and (max-width: 6000px) {
  // .sellerheader {
  //     padding-top: 1.04vw;
  //     margin-bottom: 1.04vw;
  //     .login-btn {
  //         min-width: 8.5vw;
  //         min-height: 3.2vw;
  //         text-align: center;
  //         font-size: 0.937vw;
  //         line-height: 1.04vw;
  //         border-radius: 2.5vw;
  //     }

  //     .headersize {
  //         width: 70%;
  //         display: block;
  //         position: relative;
  //         margin: 0 auto;
  //     }

  //     .category-name {
  //         font-size: 1.04vw;
  //         line-height: 1.25vw;
  //         padding-left: 1.25vw;
  //     }

  //     .logo-img {
  //         width: 12vw;
  //     }
  // }
  .policydetails {
    .mrgbottom {
      .bussiness-country-dropdown {
        width: 100% !important;
      }
    }
  }

  .sellerheader_new {

    .login-btn-new,
    .create-btn-new {
      box-shadow: 0.208vw 0.208vw 0px #2e2f35;
    }

    .create-btn-new,
    .login-btn-new {
      border-radius: 0.833vw !important;
    }
  }
}

@media screen and (min-width: 1440px) {
  .sellerheader_new {

    .login-btn-new,
    .create-btn-new {
      box-shadow: 0.3vw 0.3vw 0px #2e2f35;
    }
  }
}