.text-editor {
    width: 100%;
    margin-bottom: 1.6rem;
    #toolbar,
    .ql-toolbar {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .ql-header {
            .ql-picker-label {
                &:hover {
                    color: #444;

                    svg {
                        .ql-stroke {
                            stroke: #444;
                        }
                    }
                }
            }
        }

        .ql-formats {
            button {
                &:hover {
                    svg {
                        .ql-stroke {
                            stroke: #444;
                        }
                    }
                }
            }
        }
    }

    .quill {
        .ql-container {
            .ql-editor {
                p {
                    width: fit-content;
                }
            }
        }
    }
}

@media screen and (min-width: 1930px) {
    .text-editor .quill .ql-container .ql-editor p{
        font-size: 2rem;
        line-height: 2rem;
    }
}