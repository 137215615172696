.subscription {
    border-top: 2px solid #e3dddd;

    .login-full-container {
        margin: 0 auto 1.8rem;
        padding-bottom: 3.8rem;
        text-align: center;
        background-color: #E0F8F2;
        padding-top: 4.933rem;
    }

    .input-field-outline {
        border-radius: 5px;
        height: 43px;
        font-family: 'Inter-Regular';
        color: #000000;
    }

    input:-webkit-autofill {
        -webkit-text-fill-color: #000000 !important;
    }

    .StripeElement {
        background-color: #fffcfc;
        border: 1px solid #e6ebf1;
        border-radius: 15px;
        box-shadow: none;
        font-family: 'Inter-Medium';
        letter-spacing: .025em;
        margin-bottom: 15px;
        padding: 16px 12px;
        text-transform: uppercase;
        transition: box-shadow .15s ease;
        width: 100%;

        input {
            font-size: 0.5vw !important;
        }
    }

    // .InputElement.is-complete.Input, .InputElement.is-empty.Input.Input--empty, .InputElement.is-invalid.Input, .InputElement.Input{
    //     font-size: 0.5vw !important;
    // }
    input .InputElements {
        font-size: 10px !important;
    }

    .input-error-message {
        margin-bottom: 10px;
    }

    ::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        font-size: 13px;
        line-height: 18px;
        opacity: 1;
        /* Firefox */
        color: #9296AB;
        font-family: 'Inter-medium';
    }

    :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        font-size: 13px;
        line-height: 18px;
        color: #9296AB;
        font-family: 'Inter-medium';
    }

    ::-ms-input-placeholder {
        /* Microsoft Edge */
        font-size: 13px;
        line-height: 18px;
        color: #9296AB;
        font-family: 'Inter-medium';
    }

    .left-arrow-new {
        display: flex;
        align-items: center;
        justify-content: space-between;
        // margin-top: 6.111vw;

        // :-ms-input-placeholder {
        //   /* Internet Explorer 10-11 */
        //   font-size: 12px;
        //   line-height: 18px;
        // }

        // ::-ms-input-placeholder {
        //   /* Microsoft Edge */
        //   font-size: 12px;
        //   line-height: 18px;
        // }
        // .left-arrow-new {
        //   display: flex;
        //   align-items: center;
        //   justify-content: space-between;
        //   margin-top: 6.111vw;
        .MuiSvgIcon-root {
            margin-left: 4.166vw;
            color: #231F20;
            cursor: pointer;
        }
    }

    .main-heading {
        font-family: 'Inter-Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 1.733rem;
        line-height: 1.867rem;
        opacity: 1;
        margin-bottom: 0;
        //   margin-left: -5.5vw;
    }

  .input-lable-text {
      text-align: center;
      margin-bottom: 1rem;
      font-family: $asap-font-regular;
      font-weight: 600;
      color: #2F2B23;
  }

    .form-box {
        margin: 0 auto;
        margin-bottom: 14rem;

        width: 60%;
        margin-top: -6.5rem;
        padding-top: 1.8rem;
        padding-bottom: 1.23rem;

    }

    .login-para {
        text-align: center;
        font-size: 1rem;
        line-height: 1.2rem;
        letter-spacing: 0px;
        color: #000000;
        opacity: 1;
        font-family: "Inter-Regular";
        margin-bottom: 2.8rem !important;

        margin: 1rem auto;
    }

    .inner-box {
        background: #FFFFFF;
        border-radius: 5px;
        // border: 1px solid #D8D4CF;
        margin-bottom: 43px;
        box-shadow: 0px 0px 4px 0px #00000040;

    }

    .second-inner {
        margin-left: 1.5vw;
        padding: 24px;
    }

    .payment-text-new {
        font-size: 14px;
        line-height: 18px;
        font-family: 'Inter-Bold';
        color: #000000;
        margin-bottom: 1.25vw;
        margin-top: 0.5vw;
    }

    .card-img1 {
        width: 27.99px;
        height: 23.37px;
    }

    .card-img2 {
        width: 40.26px;
        height: 13.48px;
    }

    .card-img3 {
        width: 24px;
        height: 24px;
    }

    .card-img4 {
        width: 30.16px;
        height: 19.04px;
    }

    .card-img1,
    .card-img2,
    .card-img3,
    .card-img4 {
        margin-right: 1.04vw;
    }

    .pym-card-sec {
        display: flex;
        align-items: center;
    }

    .MuiTypography-root {
        font-size: 14px;
        line-height: 19px;
        font-family: 'Inter-medium';
        color: #231F20;
    }

    .form-field-sec {
        width: 90%;
        margin: 0 auto;
        margin-top: 5px;
    }

    .form-field-sec2 {
        padding-right: 16px;
    }

    .input-width {
        margin-bottom: 24px;
    }

    .expiration-section {
        display: flex;

        .right-side {
            width: 48%;
            margin-right: 2%;
        }

        .left-side {
            width: 48%;
            margin-left: 2%;
        }
    }

    // payment card section

    .textflex {
        margin-bottom: 1.5vw;

        .MuiButtonBase-root {
            background-color: #306EF6 !important;
            border-radius: 2.2vw !important;
            padding: 8px 24px !important;
            font-size: 13px !important;
            line-height: 25px !important;
            color: #FFFFFF !important;
            text-transform: capitalize !important;
            font-family: "Inter-Medium" !important;
            cursor: pointer;
        }
    }

    .card-container {
        border: 3px solid #3c8dbc;
        margin-right: 16px;
        margin-bottom: 2vw !important;
    }

    .address-details {
        .address-text {
            display: flex;
            align-items: center;
        }

        .mastercard {
            width: fit-content;
            height: 22px;
            margin-right: 1.5rem;
            object-fit: contain;
        }

        .amex-img {
            width: 50px;
            height: 30px;
        }
    }

    .summery-text {
        font-size: 15px;
        line-height: 18px;
        font-family: 'Inter-Bold';
        margin-bottom: 15px;
        color: #000000;
    }
    .margin-bottom{
        margin-bottom: 32px;
    }

    .summery-text-flex {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .trial-btn {
        background-color: #306EF6 !important;
        border-radius: 2.2vw !important;
        padding: 8px 24px !important;
        font-size: 13px !important;
        line-height: 25px !important;
        color: #FFFFFF !important;
        text-transform: capitalize !important;
        font-family: 'Inter-Medium';
        cursor: pointer;

        .MuiSvgIcon-root {
            margin-right: 5px;
            height: 1em;
            width: 1.2rem;

        }
    }

    .text-center {
        text-align: center;
        margin-top: 21px;
        margin-bottom: 15px;


    }



}

.Add-Address-dialogs {
    .MuiPaper-root.MuiPaper-elevation {
        min-height: 250px;
    }

    .voucher-text {
        font-size: 20px;
        line-height: 28px;
        font-family: 'Inter-Bold';
        color: #000000;
        margin-bottom: 1.25vw;
        margin-top: 0.5vw;
    }

    .StripeElement {
        background-color: #fffcfc;
        border: 1px solid #e6ebf1;
        border-radius: 15px;
        box-shadow: none;
        font-family: 'Inter-Medium';
        letter-spacing: .025em;
        margin-bottom: 15px;
        padding: 16px 12px;
        text-transform: uppercase;
        transition: box-shadow .15s ease;
        width: 100%;
    }

    .trial-btn {
        background-color: #306EF6 !important;
        border-radius: 2.2vw !important;
        padding: 8px 24px !important;
        font-size: 15px !important;
        line-height: 25px !important;
        color: #FFFFFF !important;
        text-transform: capitalize !important;
        font-family: 'Inter-Medium';
        margin-top: 1vw;
        cursor: pointer;

        .MuiSvgIcon-root {
            margin-right: 5px;
            height: 1em;
            width: 1.2rem;

        }
    }
}

@media only screen and (max-width: 600px) {
    .Add-Address-dialogs {
        .btn-trial-center {
            text-align: center;
            width: 90%;
            margin: auto;

        }

        .trial-btn {
            width: 100%;
            border-radius: 4.5vw !important;
            margin-top: 3.5vw;
        }

        .voucher-text {
            text-align: center;
            margin-bottom: 4vw;
            margin-top: 01.5vw;
        }
    }

    .subscription {
        .inner-box {
            padding-bottom: 24px;
        }

        .card-container {
            padding: 10px 8px !important;
            margin-bottom: 24px !important;
        }

        .address-details {
            .MuiFormControlLabel-root {
                margin-right: 0;
            }

            .mastercard {
                width: 50px;
                height: 17px;
            }
        }

        .textflex {
            text-align: center;
            margin-bottom: 24px;

            .MuiButtonBase-root {
                border-radius: 4.5vw !important;
            }
        }

        .trial-btn {
            border-radius: 4.5vw !important;
        }

        .payment-text-new {
            margin-bottom: 5.25vw;
            margin-top: 1.5vw;
        }

        .login-full-container {
            padding-top: 0.5vw;
        }

        .btn-trial-center {
            text-align: center;
        }

        .form-box {
            width: 85%;
            margin-left: auto;
            margin-right: 5%;
            display: block;

        }

        .form-field-sec {
            width: 100%;
            padding-right: 16px;
        }

        .card-img1,
        .card-img2,
        .card-img3,
        .card-img4 {
            margin-right: 10px;
        }

        .pym-card-sec {
            padding-top: 0 !important;
            margin-bottom: 10px;
        }

        .second-inner {
            margin-left: 0;
        }

        .main-heading {
            margin-top: 5vw;
            margin-left: 0vw;
        }

        .left-arrow-new {
            align-items: inherit;
            flex-direction: column;

            .MuiSvgIcon-root {
                margin-left: 4vw;
                margin-bottom: 5vw;
            }
        }

    }


}

@media (min-width:601px) and (max-width:919px) {
    .subscription {
        .left-arrow-new {
            margin-top: 2vw;
        }

        .form-box {
            width: 70%;
        }
    }
}

@media (min-width:921px) and (max-width:1281px) {
    .subscription .form-box {
        width: 75%;
    }
}

@media only screen and (min-width: 1921px) {
    .subscription {
        .left-arrow-new {
            padding-top: 2.5vw;
        }

        .input-field-loginside {
            height: 3vw;
        }

        .textflex {
            .MuiButtonBase-root {
                padding: 12px 34px !important;
                font-size: 20px !important;
            }
        }

        .login-full-container {
            .left-arrow-new {
                .main-heading {
                    font-size: 2.9rem;
                    line-height: 3rem;
                }
            }

            .login-para {
                font-size: 1.6rem;
                line-height: 2rem;
            }
        }

        .form-box {
            .payment-text-new {
                font-size: 1.6rem;
                line-height: 2rem;
            }

            .summery-text {
                font-size: 1.6rem;
                line-height: 2rem;
            }

            .text-center{
                .disabledBtn{
                    font-size: 1.6rem !important;
                    .MuiSvgIcon-root {
                        width: 2rem !important;
                        height: 2rem !important;
                    }
                }
            }
        }
        .StripeElement{
            margin-bottom: 1.5rem;
        }
        .input-error-message{
            font-size: 1rem;
            line-height: 1rem;
        }
        .btn-trial-center {
            .trial-btn {
                font-size: 1.6rem !important;
                padding: 16px 28px !important;
                .MuiSvgIcon-root {
                    width: 2rem !important;
                    height: 2rem !important;
                }
            }
        }
    }
}

@media only screen and (min-width: 2000px) {
    .subscription .form-box {
        width: 45%;
    }
}