.prosellerregistration {
  padding: 106px 0px 0px 0px;
  margin-bottom: 113px;
  background-color: #fff;

  .icon-password {
    margin-bottom: 40px;
  }
  .register-img {
    width: 100%;
  }
  .gridcenter {
    align-items: center;
  }
  .dropdown .select-field {
    border: none;
  }
  .dropdown .MuiFormControl-fullWidth {
    margin-bottom: 15px;
  }
  .all-container-27inch {
    max-width: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }
  .register-heading {
    text-align: left;
    letter-spacing: 0px;
    color: #2f2b23;
    font-family: 'GT-Super-Text-Regular';
    margin-bottom: 18px;
    font-size: 44px;
    padding-left: 8.2%;
    padding-right: 24.5%;
    line-height: 50px;
  }
  .ethical-heading {
    font-weight: bold;
  }
  .strepper-row {
    padding-left: 7.2%;
    padding-right: 7.2%;
    margin-bottom: 113px;
    background-color: #fff;
  }
}

@media only screen and (max-width: 600px) {
  .prosellerregistration .register-heading {
    padding-right: 2%;
  }
  .prosellerregistration .register-text-heading {
    font-size: 40px !important;
    line-height: 45px;
  }
}
@media only screen and (max-width: 920px) {
  .prosellerregistration .register-text-heading {
    font-size: 40px !important;
    line-height: 45px;
  }
}

@media only screen and (min-width: 1921px) {
  .prosellerregistration .register-heading {
    font-size: 2.5vw;
    line-height: 2.8vw;
  }
}
