.news-Letter-section,
.Signup-Letter-section,
.footer-input,
.menu-submenu-section {
  padding: 3.25rem 3rem 1.75rem;
  background: #f8fafd;
  border-radius: 24.6203px;
  margin-bottom: 3.563rem;
  margin-right: 16px;

  h4 {
    font-family: 'Inter-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 22.6507px;
    line-height: 32px;
    color: #1f1f1f;
    margin-bottom: 2rem;
  }

  .description {
    width: 393.18px;
    font-family: $asap-font-regular;
    font-style: normal;
    font-weight: 400;
    font-size: 15.757px;
    line-height: 24px;
    color: #44423d;
    margin: 0;
  }

  .input-title {
    margin-top: 3.125rem;

    .input-info {

      .input-section,
      .call-action,
      .button-label,
      .des-section,
      .social-media {
        display: flex;
        margin-bottom: 2.125rem;

        p {
          width: 170px;
          font-family: $asap-font-regular;
          font-style: normal;
          font-weight: 500;
          font-size: 15.757px;
          line-height: 18px;
          color: #44423d;
        }
      }

      .input-width,
      .input-textarea {
        width: 700px;
      }

      .input-textarea {
        margin-top: 0 !important;
      }

      .input-width {
        .input-field-outline {
          margin-top: 0 !important;
        }
      }

      .input-field-outline,
      .input-textarea {
        border: 1px solid #d8d4cf !important;
        margin-bottom: 0;
        margin-top: 0;

        &::placeholder {
          color: #a6a4a0 !important;
        }
      }
    }
  }

  .main-menu,
  .main-sub-menu {
    margin-top: 3.125rem;
    display: flex;
    column-gap: 109px;
    margin-bottom: 2.125rem;

    .flex-row {
      display: flex;

      p {
        width: 138px;
        font-family: $asap-font-regular;
        font-style: normal;
        font-weight: 500;
        font-size: 15.757px;
        line-height: 18px;
        color: #44423d;
      }

      .input-width,
      .input-textarea {
        width: 353px;
      }

      .input-field-outline,
      .input-textarea {
        border: 1px solid #d8d4cf !important;
        margin-bottom: 0;
        margin-top: 0;

        &::placeholder {
          color: #a6a4a0 !important;
        }
      }

      .flex-input {
        display: flex;
        align-items: center;
        column-gap: 4.75rem;

        .circle-btn {
          width: 37px;
          height: 37px;
        }
      }
    }
  }

  .centerbtn {
    text-align: center;
    margin-top: 56px;

    .Orangebtn {
      text-transform: inherit;
    }
  }
}

.footer-input{
  margin-bottom: 24px;
}

.menu-submenu-section{
  .menu-btn{
    margin-left: 0 !important;
  }
}

@media screen and (min-width: 3000px) and (max-width: 6000px) {

  .news-Letter-section,
  .Signup-Letter-section,
  .footer-input,
  .menu-submenu-section {
    .input-title {
      .input-info {

        .input-section,
        .call-action,
        .button-label,
        .des-section,
        .social-media {
          p {
            width: 320px;
            font-size: 35px;
            line-height: 28px;
          }
        }

        .input-width,
        .input-textarea {
          width: 55vw;
        }
      }
    }

    .main-menu,
    .main-sub-menu {
      .flex-row {
        p {
          width: 320px;
          font-size: 35px;
          line-height: 28px;
        }

        .input-width,
        .input-textarea {
          width: 520px;
        }

        .input-width {
          .input-field-outline {
            margin-top: 0;
            height: 85px;
          }
        }

        .flex-input {
          display: flex;
          align-items: center;
          column-gap: 4.75rem;

          .circle-btn {
            width: 60px;
            height: 60px;
          }
        }
      }
    }

    .centerbtn {
      .Orangebtn {
        border-radius: 1.658vw !important;
        font-size: 0.933vw !important;
        width: fit-content !important;
        padding: 0.829vw 1.244vw;
        height: fit-content !important;
      }
    }
  }
}

@media screen and (min-width: 1930px) and (max-width: 3000px) {

  .news-Letter-section,
  .Signup-Letter-section,
  .footer-input,
  .menu-submenu-section {
    h4 {
      font-size: clamp(22px, 2.5vw, 35px);
    }

    .description {
      font-size: clamp(1.125rem, 1.2vw, 1.8rem);
      line-height: clamp(1.5rem, 1.5vw, 2.3rem);
      width: clamp(394px, 21vw, 534px);
    }

    .input-title {
      .input-info {

        .input-section,
        .call-action,
        .button-label,
        .des-section,
        .social-media {
          p {
            font-size: clamp(1.125rem, 1.2vw, 1.8rem);
            width: clamp(170px, 13vw, 315px);
          }

          .input-width,
          textarea {
            // width: calc(80vw - 35%);
            width: 55vw;
          }

          .input-width {
            .input-field-outline {
              margin-top: 0px;
              height: clamp(54px, 3vw, 70px);
            }
          }

          textarea {
            margin-top: 0px;
            height: clamp(100px, 9vw, 220px);
          }
        }
      }
    }

    .main-menu,
    .main-sub-menu {
      column-gap: 109px;
      margin-bottom: 2.125rem;

      .flex-row {
        display: flex;

        p {
          width: 280px;
          font-size: clamp(1.125rem, 1.2vw, 1.8rem);
        }

        .input-width,
        .input-textarea {
          width: clamp(353px, 100vw, 520px);
        }

        .input-width {
          .input-field-outline {
            margin-top: 0;
            height: clamp(54px, 3vw, 70px);
          }
        }

        .flex-input {
          display: flex;
          align-items: center;
          column-gap: 4.75rem;

          .circle-btn {
            width: 37px;
            height: 37px;

            img {
              width: 15px;
              height: auto;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1930px) {

  .website-footer .menu-btn{
    width: fit-content !important;
    min-width: fit-content !important;
    padding-left: 24px !important;
    padding-right: 24px !important;
  }

  .news-Letter-section,
  .Signup-Letter-section,
  .footer-input,
  .menu-submenu-section {
    .input-title {
      .input-info {

        .input-section,
        .call-action,
        .button-label,
        .des-section,
        .social-media {

          .input-width,
          textarea {
            width: 55vw;
          }

        }
      }
    }
  }
  .menu-submenu-section {
    .main-menu,
    .main-sub-menu {
      .flex-row {
        .input-width {
          width: 22vw;
        }
      }
    }
  }
}

@media screen and (min-width: 1200px) and (max-width: 1400px) {
  .menu-submenu-section {

    .main-menu,
    .main-sub-menu {
      column-gap: 50px;

      .flex-row {
        .input-width {
          width: 250px;
        }
      }
    }
  }
}

@media screen and (min-width: 1000px) and (max-width: 1200px) {

  .news-Letter-section,
  .Signup-Letter-section,
  .footer-input,
  .menu-submenu-section {

    // margin-right: 0;
    .description {
      width: 100%;
    }

    .input-title {
      .input-info {

        .input-section,
        .call-action,
        .button-label,
        .des-section,
        .social-media {
          display: flex;
          flex-direction: column;
          row-gap: 1.5rem;

          p {
            width: fit-content;
          }
        }

        .input-width,
        .input-textarea {
          width: 100%;
        }

        .input-textarea {
          margin-bottom: 0;
        }
      }
    }

    .main-menu,
    .main-sub-menu {
      flex-direction: column;
      row-gap: 1.25rem;

      .flex-row {
        flex-direction: column;

        p {
          width: 100%;
        }

        .input-width,
        .input-textarea {
          width: 85%;
        }

        .flex-input {
          display: flex;
          align-items: center;
          justify-content: space-between;
          column-gap: 0;

          .circle-btn {
            width: 37px;
            height: 37px;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 800px) and (max-width: 1100px) {

  .news-Letter-section,
  .Signup-Letter-section,
  .footer-input,
  .menu-submenu-section {
    margin-right: 0;

    .description {
      width: 100%;
    }

    .input-title {
      .input-info {

        .input-section,
        .call-action,
        .button-label,
        .des-section,
        .social-media {
          display: flex;
          flex-direction: column;
          row-gap: 1.5rem;

          p {
            width: fit-content;
          }
        }

        .input-width,
        .input-textarea {
          width: 100%;
        }

        .input-textarea {
          margin-bottom: 0;
        }
      }
    }

    .main-menu,
    .main-sub-menu {
      flex-direction: column;
      row-gap: 1.25rem;

      .flex-row {
        flex-direction: column;

        p {
          width: 100%;
        }

        .input-width,
        .input-textarea {
          width: 85%;
        }

        .flex-input {
          display: flex;
          align-items: center;
          justify-content: space-between;
          column-gap: 0;

          .circle-btn {
            width: 37px;
            height: 37px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {

  .news-Letter-section,
  .Signup-Letter-section,
  .footer-input,
  .menu-submenu-section {
    margin-right: 0;

    .description {
      width: 100%;
    }

    .input-title {
      .input-info {

        .input-section,
        .call-action,
        .button-label,
        .des-section,
        .social-media {
          display: flex;
          flex-direction: column;
          row-gap: 1.5rem;

          p {
            width: fit-content;
          }
        }

        .input-width,
        .input-textarea {
          width: 100%;
        }

        .input-textarea {
          margin-bottom: 0;
        }
      }
    }

    .main-menu,
    .main-sub-menu {
      flex-direction: column;
      row-gap: 1.25rem;

      .flex-row {
        flex-direction: column;

        p {
          width: 100%;
        }

        .input-width,
        .input-textarea {
          width: 85%;
        }

        .flex-input {
          display: flex;
          align-items: center;
          justify-content: space-between;
          column-gap: 0;

          .circle-btn {
            width: 37px;
            height: 37px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {

  .news-Letter-section,
  .Signup-Letter-section,
  .footer-input,
  .menu-submenu-section {
    margin-right: 0;

    .description {
      width: 100%;
    }

    .input-title {
      .input-info {

        .input-section,
        .call-action,
        .button-label,
        .des-section,
        .social-media {
          display: flex;
          flex-direction: column;
          row-gap: 1.5rem;

          p {
            width: fit-content;
          }
        }

        .input-width,
        .input-textarea {
          width: 100%;
        }

        .input-textarea {
          margin-bottom: 0;
        }
      }
    }

    .main-menu,
    .main-sub-menu {
      flex-direction: column;
      row-gap: 1.25rem;

      .flex-row {
        flex-direction: column;

        p {
          width: 100%;
        }

        .input-width,
        .input-textarea {
          width: 100%;
        }

        .flex-input {
          display: flex;
          align-items: center;
          justify-content: space-between;
          column-gap: 0;
          flex-wrap: wrap;
          row-gap: 16px;

          .circle-btn {
            width: 37px;
            height: 37px;
          }
        }
      }
    }

    .main-sub-menu {
      .flex-row {
        .input-width {
          width: fit-content;
        }
      }
    }
  }
}

@media screen and (max-width: 380px) {

  .news-Letter-section,
  .Signup-Letter-section,
  .footer-input,
  .menu-submenu-section {
    padding: 3.25rem 2rem 1.75rem;
  }
}