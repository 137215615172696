.theme-banner {
  margin-top: 2rem;
  margin-bottom: 2.5rem;
  .welcominnercard {
    margin-bottom: 36px !important;
    padding: 5.278vw 3.889vw;
    min-height: 0;
    .MuiGrid-root {
      .MuiGrid-root {
        padding-top: 0 !important;
        .welcome-new-text {
          font-family: 'Inter-Medium';
          font-weight: 500;
        }
        .welcome-para {
          font-weight: 400 !important;
          font-family: 'Inter-Regular' !important;
        }
      }
    }
  }
  .main-section {
    .return-btn {
      height: 3.25rem;
      min-width: 8.5rem;
      border-radius: 3.25rem !important;
      font-size: 1.125rem !important ;
      font-family: 'Inter-Medium' !important;
      font-style: normal !important;
      font-weight: 500 !important;
      margin-bottom: 40px;
    }
  }
  .buttons {
    width: 100%;
    margin-top: 70px;
    .buttons-position {
      display: flex;
      justify-content: flex-end;
      margin-right: 130px;
      .lightbtn {
        margin-right: 30px !important;
      }
    }
  }
  .drag-container {
    .upload-arrow-card {
      gap: 91px;
      .up-down-btn {
        display: flex;
        flex-direction: column;
        gap: 15px;
      }
    }
  }
}

@media screen and (min-width: 3000px) and (max-width: 6000px) {
  .theme-banner {
    .main-section {
      .return-btn {
        border-radius: 6rem !important;
        font-size: 1.8rem !important;
        margin-bottom: 4rem;
        height: 4rem;
        min-width: 14.5rem;
      }
      .buttons {
        .buttons-position {
          .lightbtn,
          .bluebtn {
            font-size: 1.016vw !important;
            margin-right: 0.781vw !important;
            padding: 15px 24px !important;
            border-radius: 18px !important;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1930px) and (max-width: 3000px) {
  .theme-banner {
    // padding-left: clamp(20px, 3vw, 60px);
    .buttons {
      .buttons-position {
        .lightbtn,
        .bluebtn {
          font-size: 1.016vw !important;
          margin-right: 0.781vw !important;
          padding: 15px 24px !important;
          border-radius: 18px !important;
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .theme-banner {
    .welcominnercard {
      margin: 0 24px;
      margin-bottom: 3.867rem;
    }
    .main-section {
      padding: 0 24px;
      .return-btn {
        width: 100%;
      }
    }
    .buttons {
      margin-top: 40px;
      .buttons-position {
        margin-right: 0;
      }
    }
    .drag-container {
      .upload-arrow-card {
        gap: 15px;
        .up-down-btn {
          display: flex;
          flex-direction: row;
          gap: 15px;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .theme-banner {
    margin: 0 1.5rem;
    margin-bottom: 8vw;
    .welcominnercard {
      margin: 0 24px;
      margin-bottom: 3.867rem;
      padding: 10.6vw 4.8vw 5.6vw;
    }
    .main-section {
      padding: 0 24px;
      .return-btn {
        width: 100%;
      }
    }
    .buttons {
      margin-top: 40px;
      .buttons-position {
        margin-right: 0;
      }
    }
    .drag-container {
      .upload-arrow-card {
        gap: 15px;
        .up-down-btn {
          display: flex;
          flex-direction: row;
          gap: 15px;
        }
      }
    }
  }
}
