.trendingproducts {
  padding-top: 25px;
  padding-bottom: 60px;
  margin-left: 9%;
  margin-right: 9%;

  .main-heading40 {
    font-weight: 500;
    font-size: 24px;
    margin-bottom: 27px;
  }
  .lightbtn {
    color: #585348 !important;
    font-weight: 500 !important;
    box-shadow: none !important;
    padding: 2px 16px !important;
  }
  .dropdown .select-field {
    background: url(../../assets/images/sellerSide/dropdown.webp) 96%/8% no-repeat;
    width: 170px;
    height: 38px;
    background-color: #fff;
  }
  .css-6hp17o-MuiList-root-MuiMenu-list {
    padding-left: 8px;
  }
  .search-input {
    width: 210px !important;
    background-color: #fff;
  }
  .MuiOutlinedInput-input {
    padding: 12.5px 14px 8.5px 0px;
  }
  .MuiPaginationItem-sizeSmall {
    color: rgba(0, 0, 0, 0.54);
  }
  .pagination {
    color: rgba(0, 0, 0, 0.54);
  }
  .searchbarsection {
    display: flex;
    margin-bottom: 10px;
  }

  .css-96uuyl {
    margin-left: 0;
  }

  .img-container {
    width: 25%;
  }

  .img-row {
    width: 100%;
    height: 10.91vw;
    min-height: 150px;
    // min-width: 150px;
    margin-bottom: 9px;
    border-radius: 8px;
    object-fit: cover;
  }
  .product-row {
  }
  .product-section {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-family: $asap-font-regular;
    font-weight: 600;
    font-style: normal;
    font-size: 14px;
    line-height: 130%;
    color: #2f2b23;
  }
  .product-name {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-family: $asap-font-regular;
    font-weight: 600;
    font-style: normal;
    font-size: 14px;
    line-height: 130%;
    color: #2f2b23;
  }
  .price {
    font-family: $asap-font-regular;
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    line-height: 130%;
    color: #2f2b23;
  }
  .discount-price {
    text-decoration: line-through;
    opacity: 0.5;
  }
  .lightbtn {
    margin-top: 10px !important;
  }
  .outofstock {
    position: absolute;
    right: 5%;
    top: 14px;
  }
}
@media (min-width: 280px) and (max-width: 600px) {
  .trendingproducts {
    margin: 0;
    padding-right: 14px;

    .dropdown .select-field {
      background: url(../../assets/images/sellerSide/dropdown.webp) 96%/10% no-repeat;
      width: 100%;
      min-width: 140px;
    }
    // .searchbarsection {
    //   display: block;
    // }
    .search-input {
      width: 90% !important;
      margin-bottom: 14px;
      .MuiInputBase-colorPrimary {
        width: 100%;
      }
    }
    .img-container {
      width: 50%;
    }
    .product-section {
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 5px;
    }
    .price {
      font-size: 15px;
      line-height: 18px;
      margin-bottom: 5px;
      font-weight: 500;
    }
  }
}
@media (min-width: 930px) and (max-width: 1240px) {
  .trendingproducts {
    .img-container {
      width: 33.33%;
    }
  }
}
@media (min-width: 601px) and (max-width: 929px) {
  .trendingproducts {
    .img-container {
      width: 50%;
    }
  }
}
@media (min-width: 2000px) and (max-width: 6000px) {
  .trendingproducts {
    .dropdown .select-field {
      background: url(../../assets/images/sellerSide/dropdown.webp) 92%/9% no-repeat;
      width: 9vw;
      height: 2.1vw;
    }
    .search-input {
      width: 10vw !important;
    }
    .main-heading40 {
      font-size: 1.5vw;
      line-height: 1.7;
    }
    .product-section {
      font-size: 0.8vw;
      line-height: 1.2vw;
    }
    .price {
      font-size: 1vw;
      line-height: 1.2vw;
    }
    .lightbtn,
    .darkbtn {
      border-radius: 0.5vw !important;
      padding: 0.4vw 0.8vw !important;
      font-size: 0.8vw !important;
    }
  }
}
