.editicon {
  background: #e7e7e7 !important;
  border-radius: 50% !important;
  padding: 5px !important;
  width: 30px !important;
  height: 30px !important;
}

.copyicon {
}

.deleteicon {
}

.showhideicon {
}
