@charset "UTF-8";
// @import url('https://fonts.googleapis.com/css2?family=Asap:ital,wght@0,400;0,500;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Asap:ital,wght@0,400;0,500;1,400&display=swap');
@import '../scss/base/fonts';
// 1. Configuration and helpers
@import 'abstracts/variables', 'abstracts/functions', 'abstracts/mixins';

// 2. Vendors
@import 'vendors/normalize';

// 3. Base stuff
@import 'base/base', 'base/fonts', 'base/typography', 'base/helpers';

// 4. Layout-related sections
@import 'layout/publicHeader';
@import 'layout/publicFooter';
// 5. Components
@import 'components/button', 'components/pagination', 'components/adminbrandwithcause', 'components/addsubsection',
  'components/addto', 'components/removefrom', 'components/adminsidebar', 'components/table';

// 6. Page-specific styles
@import 'pages/home';
@import 'pages/loginPage', 'pages/home', 'pages/categories', 'pages/viewdetails', 'pages/popularcategory',
  'pages/selleradmin', 'pages/featuredsection', 'pages/pendingorders', 'pages/buyeriddetails', 'pages/selleriddetails',
  'pages/adminviewdetails.scss', 'pages/userregistration', 'pages/thanksforregistering.scss', 'pages/brandwithcause';

// 7. Themes
@import 'themes/default', 'universal/inputType', 'universal/exclusiveBlog.scss', 'universal/searchbar',
  'universal/allicons', 'universal/onoffswitch', 'universal/progressBar', 'components/welcomeAdminDashboard',
  'pages/proSellerRegistration', 'components/sellerSideBar', 'pages/seller', 'components/registrationStepper',
  'universal/productCards', 'pages/productImages', 'universal/heading', 'pages/products', 'pages/addSingleProduct',
  'components/mediaLibrary', 'pages/seller-dashboard', 'universal/dashboardchart', 'components/uploadImage',
  'pages/_orders', 'pages/sellerOrderDetails', 'pages/myProfile', 'pages/financialreports', 'components/updatePassword',
  'components/updateBusinessDetails', 'components/updateBankdetails', 'universal/dropDown.scss',
  'components/dispatchOrder', 'pages/orderInvoice', 'pages/trendingProducts', 'pages/orderItemReport',
  'universal/fileUpload', 'pages/contactUs', 'pages/giftVoucher', 'components/refund', 'components/publishUpdate',
  'universal/emptyImages', 'pages/notFound', 'pages/noInternet', './components/skeleton/dashboardskeleton',
  './components/skeleton/productimageskeleton', './components/skeleton/productskeleton',
  './components/skeleton/orderskeleton', './components/skeleton/financalreportskeleton', './components/sweetalert',
  './components/editImage', './components/skeleton/sellerSkeleton', './components/newsLetter/newsLetter.scss',
  './components/SiteEditorTable/siteEditortabel.scss', './components/SiteEditorTable/actionBtn.scss',
  './components/categoryCard', './components/productImageGallery', './components/addProductSearchInput',
  './components/siteEditor/draggableComponent.scss', './components/siteEditor/circleBtn.scss',
  './components/searchEngine', './components/productImages', './components/accordion';

@import 'universal/welcomeCard', './pages/bulkUpload', './pages/siteEditor', './pages/topbanner',
  './pages/productSection', '../scss/components/universal/draggableUpdateCard.scss', './pages/trendingProduct',
  './pages/tellYourStory', './pages/newsLetter', './pages/websiteFooter', './pages/productCollection',
  './pages/categoriesHeader', '../scss/components/universal/draggableUpdateCard.scss';

@import './components/skeleton/dashboardskeleton', './components/skeleton/productimageskeleton',
  './components/skeleton/productskeleton', './components/skeleton/orderskeleton',
  './components/siteEditor/circleBtn.scss', './components/skeleton/financalreportskeleton', './components/sweetalert',
  './components/editImage', './components/skeleton/sellerSkeleton', './components/newsLetter/newsLetter.scss',
  './components/SiteEditorTable/siteEditortabel.scss', './components/SiteEditorTable/actionBtn.scss',
  './components/categoryCard', './components/productImageGallery', './components/addProductSearchInput',
  './components/siteEditor/draggableComponent.scss', './components/searchEngine', './components/normalCard',
  './components/universal/shop-product-badges.scss', 'components/refund', 'components/publishUpdate',
  'universal/emptyImages', 'universal/welcomeCard', './components/skeleton/dashboardskeleton',
  './components/skeleton/productimageskeleton', './components/skeleton/productskeleton',
  './components/skeleton/orderskeleton', './components/skeleton/financalreportskeleton', './components/sweetalert',
  './components/editImage', './components/skeleton/sellerSkeleton';

@import 'universal/welcomeCard', './pages/bulkUpload', './pages/siteEditor', 'pages/createAccount',
  'pages/verifyYourEmail', 'pages/storeDetails', 'pages/welcome', 'pages/policyDetails', './pages/topbanner',
  './pages/productSection', 'pages/forgotPassword', 'pages/resetPassword', './pages/trendingProduct',
  './pages/tellYourStory', './pages/newsLetter', './pages/websiteFooter', './pages/productCollection',
  './pages/categoriesHeader', 'pages/businessProductDetails', 'pages/Subscription', './pages/themeBanner',
  './components/deletePopup', './pages/feedback', './pages/link', './pages/countryList', './pages/processingtime',
  './pages/shippingPage', './pages/linkPage', './pages/addTestimonial', './pages/bannerPage',
  './pages/rightImgLeftContent', './pages/shopByValue', './pages/shopCollection', './pages/themeContent',
  './pages/site-product-section', './pages/siteShipping', './pages/testimonials', './pages/siteProductBadges',
  './pages/addProductBadge', './pages/customerProducts', './pages/siteBlogs', './pages/site-latest-arrival',
  './pages/siteSize', './pages/siteColor', './pages/themeBasedContentBanner', './pages/siteFeatureProduct',
  './pages/siteAddPopularProducts', './pages/site-add-dynamic-block-banner', './pages/siteAddFeatureProductBanners',
  './pages/themeBasedContentBanner', './pages/siteTrendingBanners', './pages/siteSellerBanners',
  './pages/siteAddLatestArrivalBanner', './pages/siteProductBanners', './pages/siteTrendingBannerList',
  './pages/siteFeatureProductBannerList', './pages/siteDynamicBlockBannerList', './pages/siteBlogList',
  './pages/siteBannerList', './pages/siteProductBannerList', './pages/siteSellerBannerList',
  './pages/siteProductCollection', './components/quillEditorUniversal', './pages/siteLatestArrivalBannerList',
  './pages/siteAddInstagramSection', 'pages/notFound', 'pages/noInternet', 'pages/businessProductDetails',
  'pages/Subscription', 'pages/help', './pages/bulkUpload', './pages/siteAddThemBaseBannner', './pages/siteColorList';

.goethical_text {
  cursor: pointer;
  color: #757061;
}

.text-logo {
  font-family: 'GT-Super-Text-Regular';
  font-size: 1.823vw;
  cursor: pointer;
}

.wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;

  .badge {
    width: 250px;
    height: auto;
    position: relative;

    img {
      width: 100%;
      height: 150px;
      object-fit: cover;
    }

    .MuiButton-text {
      width: 30px;
      height: 30px;
      min-width: 30px;
      background: #2f2b23;
      border-radius: 50%;
      position: absolute;
      top: 5px;
      left: 5px;

      img {
        width: 100%;
        height: 100%;
        padding: 0;
        object-fit: contain;
      }
    }

    .MuiTypography-root {
      width: fit-content !important;
      font-size: 1rem !important;
    }
  }
}

.weight-600 {
  font-weight: 600 !important;
}

.badges-container {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;

  .badge {
    width: 250px;
    height: fit-content;
    position: relative;
    background: #fff;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    .MuiButtonBase-root {
      padding: 0;
      width: 26px;
      min-width: 26px;
    }
  }
}

.radiobtn,
.radiobtn-section {
  .MuiFormGroup-root {
    .MuiFormControlLabel-root {
      .MuiButtonBase-root {
        color: #949d8c;
      }
    }
  }
}

.margin-top {
  margin-top: 1.6rem;
}

.disabled-btn {
  background-color: #bbbbbb !important;
}

.product-list {
  margin-top: 16px;
}

.product-gallery {
  .MuiAutocomplete-root {
    width: 504px;
  }
}

// .img-container {
//   .MuiSvgIcon-root {
//     position: absolute;
//     top: 10px;
//     left: 10px;
//     background: #2F2B23;
//     border-radius: 50%;
//     width: 30px;
//     height: 30px;
//     padding: 5px;
//     color: #fff;
//     cursor: pointer;
//   }
// }

.qul-editor-testimonial {
  width: 700px;
}

.multiselect-container {
  .search-wrapper {
    .chip {
      background: #7f6a86;
      margin-top: 0.5rem;
    }
  }
}

.ql-container {
  min-height: 10em;
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
  background: #fefcfc;
}

.ql-toolbar {
  background: #eaecec;
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
  border-bottom: none;
}

.minus-margin {
  margin-top: -5px;
}

@media (min-width: 2000px) {
  .all-container-27inch {
    max-width: 65% !important;
  }

  .wrapper {
    gap: 20px;

    .badge {
      width: 300px;
      height: auto;

      img {
        width: 100%;
        height: 200px;
      }

      .MuiButton-text {
        width: 40px;
        height: 40px;
        min-width: 40px;
        min-height: 40px;
        max-width: 40px;
        max-height: 40px;
      }

      .MuiTypography-root {
        font-size: 1.8rem !important;
      }
    }
  }

  // .return-btn {
  //   margin-left: 0 !important;
  // }

  .container-27inch {
    margin: 0 auto;
    max-width: 65% !important;
  }

  .container-27-admin {
    max-width: 65% !important;
  }
}

@media screen and (max-width: 1200px) {
  .text-logo {
    font-size: 2.2vw;
  }
}

@media (min-width: 1400px) and (max-width: 1930px) {
  .container-27-admin {
    max-width: 100% !important;
  }
}

@media (min-width: 1180px) and (max-width: 1400px) {
  .all-container-27inch {
    max-width: 96% !important;
  }

  .container-27-admin {
    max-width: 100% !important;
  }
}

@media (min-width: 1180px) and (max-width: 1400px) {
  .all-container-27inch {
    max-width: 94% !important;
  }

  .container-27-admin {
    max-width: 100% !important;
  }
}

@media (min-width: 1500px) and (max-width: 1920px) {
  .all-container-27inch {
    max-width: 94% !important;
  }
}

@media screen and (min-width: 1930px) {
  .qul-editor-testimonial {
    width: 55vw;
  }
  .product-gallery {
    .MuiAutocomplete-root {
      .MuiFormControl-root {
        .MuiInputBase-root {
          .MuiAutocomplete-endAdornment {
            .MuiIconButton-label {
              .MuiSvgIcon-root {
                width: 20px !important;
                height: 20px !important;
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: 921px) and (max-width: 1150px) {
  .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-sm-12.MuiGrid-grid-md-11.MuiGrid-grid-lg-11.MuiGrid-grid-xl-11 {
    flex-basis: 87%;
    max-width: 87%;
  }

  .fixed-profile {
    flex-basis: 12.5% !important;
    max-width: 12.5% !important;
  }
}

@media (min-width: 800px) and (max-width: 1100px) {
  .qul-editor-testimonial {
    width: 50vw;
  }
}

@media screen and (max-width: 800px) {
  .badges-container {
    gap: 15px;

    .badge {
      width: 100%;
    }
  }

  .qul-editor-testimonial {
    width: 100%;
  }

  .product-gallery {
    .MuiAutocomplete-root {
      width: 100%;
    }
  }

  .text-logo {
    font-size: 3.889vw;
  }
}

.dataside {
  padding-left: 0 !important;
}

.css-honmcm-MuiGrid-root > .MuiGrid-item {
  padding-top: 0px !important;
  margin-top: 48px;
  padding-left: 0px !important;
}

.css-1fttrmn-MuiGrid-root {
  background: #faf9f9;
}

.css-1j1gpuc {
  background: #faf9f9;
}

.sellerorder .MuiButtonBase-root {
  display: block !important;
  padding: 4px 10px !important;
}

li.MuiButtonBase-root {
  display: block !important;
  padding: 8px 16px !important;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
  display: block !important;
}

// .all-container-27inch {
//   max-width: 90% !important;
// }

.swal2-popup {
  border-radius: 8px;
  min-height: 189px;
}

.input-error-message {
  font-size: 12px;
  color: #fc4b6c !important;
  display: block;
}

.mobilehide {
  display: block;
}

.mobileshow {
  display: none;
}

.tabhide {
  display: block;
}

.tabshow {
  display: none;
}

.main-heading {
  text-align: center;
  font-size: 72px;
  letter-spacing: 0px;
  color: #2f2b23;
  opacity: 1;
  font-family: 'GT-Super-Text-Regular';
  margin-bottom: 49px;
  opacity: 0.9;
}

.register-text-heading {
  font-size: 2.29vw;
  line-height: 2.6vw;
  text-align: center;
  letter-spacing: 0px;
  color: #2f2b23;
  opacity: 1;
  font-family: 'GT-Super-Text-Regular';
  margin-bottom: 65px;
}

.seller-para {
  font-family: $asap-font-regular;
  font-weight: normal;
  font-style: normal;
  font-size: 16px;
  line-height: 130%;
  color: #757061;
  margin-bottom: 32px;
  padding-right: 21vw;
}

.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  min-width: 360px;
  width: 600px !important;
  border-radius: 8px !important;
}

.css-uhb5lp {
  min-width: 360px;
  width: 600px !important;
  border-radius: 8px !important;
}

.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  min-width: 360px;
  width: 500px !important;
  border-radius: 8px !important;
}

.css-uhb5lp {
  min-width: 360px;
  width: 500px !important;
  border-radius: 8px !important;
}

.sellerdashboard {
  background-color: rgb(250, 249, 249);
}

@media only screen and (max-width: 600px) {
  .product-list {
    .product-gallery {
      .MuiAutocomplete-root {
        width: 100%;
      }
    }
  }

  .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    min-width: auto !important;
    width: 500px !important;
  }

  .css-honmcm-MuiGrid-root > .MuiGrid-item {
    padding-top: 0px !important;
    margin-top: 0px;
    padding-left: 0px !important;
  }

  .css-uhb5lp {
    min-width: auto !important;
    width: 500px !important;
  }

  .mobilehide {
    display: none;
  }

  .mobileshow {
    display: block;
  }

  .register-text-heading {
    font-size: 18px;
    line-height: 20px;
  }
}

@media only screen and (max-width: 919px) {
  .tabhide {
    display: none;
  }

  .tabshow {
    display: block;
  }
}

@media screen and (min-width: 1930px) {
  .multi-select-input {
    width: 55vw;

    div {
      .multiselect-container {
        width: inherit;

        .search-wrapper {
          padding: 10px 8px;

          .chip {
            font-size: 1.04vw;
            line-height: 1.22vw;
            width: fit-content;
            padding: 8px 15px;
          }

          input {
            font-size: 1.04vw;
          }
        }
      }
    }
  }
  .dropdown {
    width: 55vw !important;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1280px) {
  .multi-select-input {
    width: 700px;

    div {
      .multiselect-container {
        width: inherit;

        .search-wrapper {
          width: 100%;
        }
      }
    }
  }
}

@media screen and (min-width: 800px) and (max-width: 1024px) {
  .multi-select-input {
    width: 50vw;

    div {
      .multiselect-container {
        width: inherit;

        .search-wrapper {
          width: 100%;
        }
      }
    }
  }
  .dropdown {
    width: 50vw !important;
  }
}

@media screen and (max-width: 800px) {
  .multi-select-input {
    width: 100%;

    div {
      .multiselect-container {
        width: inherit;

        .search-wrapper {
          width: 100%;
        }
      }
    }
  }
  .dropdown {
    width: 100% !important;
  }
}

@media only screen and (min-width: 1921px) {
  .main-heading {
    font-size: 3.5vw;
    line-height: 3.8vw;
    margin-bottom: 2.5vw;
  }

  .sellerorder .MuiButtonBase-root {
    padding: 0.2vw 0.5vw !important;
  }

  .swal2-popup {
    width: 28vw !important;
    padding: 1vw !important;
  }

  .swal2-title {
    font-size: 1.2vw !important;
    line-height: 1.5vw !important;
    margin-bottom: 1.5vw;
  }

  .swal2-html-container {
    line-height: 1.2vw;
    font-size: 1vw !important;
    margin-bottom: 0.88vw !important;
  }

  .swal2-cancel,
  .swal2-confirm {
    border-radius: 0.5vw !important;
    padding: 0.4vw 0.8vw !important;
    font-size: 0.8vw !important;
    line-height: 1.4vw !important;
    height: auto;
    margin-top: 1vw;
  }

  input:-webkit-autofill {
    -webkit-text-fill-color: #949d8c !important;
  }

  .input-error-message {
    font-size: 0.88vw;
    line-height: 1.5vw;
  }

  .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    min-width: 360px;
    width: 35vw !important;
    max-width: 35vw !important;
  }

  .css-uhb5lp {
    min-width: 360px;
    width: 35vw !important;
    max-width: 35vw !important;
  }

  .MuiSvgIcon-root {
    width: 1.3vw !important;
    height: 1.3vw !important;
  }

  .lock-img {
    width: 1.3vw !important;
    height: 1.3vw !important;
  }
}

::-webkit-scrollbar {
  width: 5px;
}

// #root{
//   background: rgb(250, 249, 249);
// }
.bgcoloradmin {
  background: rgb(250, 249, 249);

  .all-container-27inch {
    margin-top: 16px;
    padding-right: 0px;
  }
}

button {
  letter-spacing: 0 !important;
}
