.policydetails {
  max-width: 454px;
  margin: auto;
  width: 50%;
  padding-bottom: 255px;

  .main-textarea{
    .input-textarea {
      margin-bottom: 0;
    }
  }

  .darkbtn,
  .lightbtn,
  .disabledBtn {
    font-size: 16px !important;
  }

  .text-heading {
    text-align: center;
    font-size: 26.66px;
    line-height: 30px;
    letter-spacing: 0px;
    color: #000000 !important;
    opacity: 1;
    font-family: $asap-font-regular;
    margin-bottom: 10px;
    margin-top: 62px;
    font-weight: normal;
    font-style: italic;
  }

  .input-textarea {
    border-radius: 8px;
  }

  .lightbtn {
    min-width: 96.5px !important;
  }

  .text-para {
    text-align: center;
    font-size: 18px;
    letter-spacing: 0px;
    color: #757061;
    opacity: 1;
    font-family: $asap-font-regular;
    margin-bottom: 24px;
    font-weight: normal;
  }

  .mrgbottom {
    margin-bottom: 20px;
  }

  .label-text {
    color: #2f2b23;
    font-size: 16px;
    letter-spacing: 0px;
    opacity: 1;
    font-family: $asap-font-regular;
    margin: 0px;
    font-weight: normal;
    // margin-bottom: 4px ;
  }

  .btn-section {
    margin-top: 32px;
    justify-content: space-between;
    flex-direction: row;
    box-sizing: border-box;
    display: flex;
  }
}

@media only screen and (max-width: 600px) {
  .policydetails {
    width: 80%;
  }
}

@media only screen and (min-width: 1921px) {
  .policydetails {
    width: 80%;
    max-width: 25.64vw;
    padding-top: 1vw;

    .mrgbottom {
      margin-bottom: 1.55vw;
    }

    .text-heading {
      font-size: 1.65vw;
      line-height: 1.66vw;
      margin-bottom: 0.866vw;
    }

    .text-para {
      font-size: 1.1vw;
      line-height: 1.25vw;
      margin-bottom: 1.25vw;
    }

    .label-text {
      font-size: 1vw;
      line-height: 1.5vw;
      margin-bottom: 0.5vw;
    }

    .btn-section {
      margin-top: 3.35vw;
      margin-bottom: 3.35vw;
    }

    .darkbtn {
      min-width: 6.5vw !important;
      min-height: 3.2vw !important;
      text-align: center !important;
      font-size: 0.937vw !important;
      line-height: 1.04vw !important;
      border-radius: 0.56vw !important;
    }
    .disabledBtn {
      min-width: 6.5vw !important;
      min-height: 3.2vw !important;
      text-align: center !important;
      font-size: 0.937vw !important;
      line-height: 1.04vw !important;
      border-radius: 0.56vw !important;
    }
    .lightbtn {
      min-width: 6.5vw !important;
      min-height: 3.2vw !important;
      text-align: center !important;
      font-size: 0.937vw !important;
      line-height: 1.04vw !important;
      border-radius: 0.56vw !important;
    }
  }
}
