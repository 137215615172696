.brand-With-Cause {
  background-color: rgb(245, 245, 245);

  .input-field-outline {
    width: 250px;
    font-size: 13px;
  }
  .dropdown .select-field {
    background: url(../../assets/images/sellerSide/dropdown.webp) 96%/5% no-repeat;
  }
  .detail-heading {
    h3 {
      font-family: 'Poppins', sans-serif;
      font-size: 21px;
      font-weight: 300;
      margin: 0 0 20px;
    }
  }

  .input-field {
    margin-bottom: 20px;
  }

  .alignitem {
    display: flex;
    justify-content: unset;
  }

  .label-text {
    color: rgba(0, 0, 0, 0.87);
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    line-height: 20px;
    font-weight: 100;
    opacity: 0.99;
  }

  .add-img {
    padding-bottom: 20px;
    text-align: center;
    position: relative;
    padding-left: 146px;

    img {
      height: 100px;
      width: 200px;
    }

    .MuiSvgIcon-root {
      position: absolute;
      top: 4px;
      right: 1%;
    }

    .fileuploadsection .attach-btn {
      background-color: #367fa9;
      color: #fff;
      min-width: auto;
      padding: 8px 15px;
      cursor: pointer;
      height: 38px;
      font-size: 14px;
      font-weight: normal !important;
      line-height: 18px;
      letter-spacing: 0px !important;
    }

    .fileuploadsection .plusicon {
      display: none;
    }
  }

  .input-textarea {
    width: 260px;
    display: block;
    overflow: auto;
    height: 100px;
    font-size: 13px;
    line-height: 18px;
    padding: 8px;
    color: #666;
  }

  .pendingbtn {
    background: #367fa9 !important;
    height: 32px;
    border-radius: 4px;
    padding: 10px !important;
    color: #fff;
    font-weight: 500 !important;
    text-transform: capitalize;
  }

  .MuiPaginationItem-sizeSmall {
    color: rgba(0, 0, 0, 0.54);
  }

  .pagination {
    color: rgba(0, 0, 0, 0.54);

    p {
      color: rgba(0, 0, 0, 0.54);
    }
  }
}

@media (min-width: 280px) and (max-width: 600px) {
  .brand-With-Cause {
    .dropdown .select-field {
      background: url(../../assets/images/sellerSide/dropdown.webp) 96%/6% no-repeat;
    }
    .input-field-outline,
    .input-textarea {
      width: 100%;
    }

    .alignitem {
      display: block;
    }

    .add-img {
      padding-left: 0px;

      img {
        height: 150px;
        width: 100%;
      }
    }

    .table-section {
      .add-row {
        display: block;
        text-align: right;
      }

      .pagination {
        margin-bottom: 14px;
      }
    }
  }
}

@media (min-width: 2000px) and (max-width: 6000px) {
  .brand-With-Cause {
    padding: 1vw;

    .input-field-outline,
    .input-textarea {
      // width: 50%;
      width: 20vw;
      font-size: 1vw;
      line-height: 1.5vw;
    }

    .input-textarea {
      height: 7vw;
    }

    .pendingbtn,
    .attach-btn {
      border-radius: 0.5vw !important;
      padding: 0.4vw 0.8vw !important;
      font-size: 0.8vw !important;
      line-height: 1.4vw !important;
      height: auto !important;
    }
    .plusicon {
      padding: 1px 14px 8px;
    }
    .detail-heading h3 {
      font-size: 1.2vw;
    }
    .select-field {
      height: 2.2vw;
    }
    .label-text {
      font-size: 1vw;
      line-height: 1.4vw;
    }

    .table-section {
      margin-top: 2vw;
      padding: 1vw;

      th,
      td {
        padding: 0.4vw;
      }

      .heading-name {
        font-size: 1vw;
        line-height: 1.2vw;
        padding-bottom: 0.6vw;
      }

      .darkbtn {
        border-radius: 0.5vw !important;
        padding: 0.4vw 0.8vw !important;
        font-size: 0.8vw !important;
      }

      .input-field-outline,
      .input-textarea {
        width: 85% !important;
        height: 2.5vw;
      }

      .product-img {
        height: auto;
        width: 5vw;
        margin: 0.4vw 0px;
      }

      .fileuploadsection .plusicon {
        font-size: 1vw;
        padding: 1px 14px 8px;
      }
      .dropdown .select-field {
        background: url(http://localhost:3001/static/media/dropdown.2d2f404….webp) 96%/7% no-repeat;
        height: 2.4vw;
        font-size: 1vw;
      }
    }
  }
}
