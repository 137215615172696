.loginpage {
  border-top: 2px solid #e3dddd;
  // padding-top: 120px;

  .login-full-container {
    margin: 0 auto 1.8rem;
    padding-bottom: 3.8rem;
    text-align: center;
    background-color: #e0f8f2;
    padding-top: 4.933rem;
  }

  //new css
  .main-heading {
    font-family: 'Inter-Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 2.667rem;
    line-height: 3rem;
    opacity: 1;
    margin-bottom: 0.933rem;
  }

  .form-box {
    margin: 0 auto;
    margin-bottom: 14rem;
    background: #ffffff;
    border-radius: 5px;
    border: 1px solid #d8d4cf;
    width: 39%;
    margin-top: -5.5rem;
    padding-top: 1.8rem;
    padding-bottom: 1.23rem;
  }
  .box-border {
    border: none;
    filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));
  }
  .input-lable-text {
    font-family: $asap-font-regular;
    font-style: normal;
    font-weight: 400;
    font-size: 1.06rem;
    line-height: 130%;
    margin-bottom: 0.267rem;
    color: #2f2b23;
  }

  .icon-password {
    position: relative;
    margin-bottom: 1.467rem;
    .input-error-message{
      position: absolute;
    }

  }
  .blackbtn {
    margin-bottom: 0.933rem !important;
    margin-top: 1rem !important;
    background-color: #ec8c61 !important;
    color: #ffffff !important;
    font-family: 'Inter-Medium' !important;
    font-size: 1.231rem !important;
    line-height: 1.733rem !important;
    border-radius: 2.141rem !important;
    height: 3.533rem !important;
    width: 8.2rem !important;
    text-transform: capitalize !important;
  }
  .visibility-icon {
    position: absolute;
    right: 4%;
    // top: 1vw;
    top: 25%;
  }

  .inner-box {
    width: 80%;
    margin: auto;
  }

  .input-field-loginside {
    margin-bottom: 0;
    border: 1px solid #d8d4cf;
    border-radius: 8px;
    width: 100%;
    padding-bottom: 0;
    height: 2.8rem;
    font-size: 1.2rem;
    line-height: 1.5rem;
    padding-left: 1rem;
    padding-right: 5vw;
  }

  input:-internal-autofill-selected {
    background-color: transparent;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 9999s ease-in-out 0s;
  }

  a:hover,
  a:active,
  a:focus {
    text-decoration: none;
  }

  .login-para {
    text-align: center;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    font-family: 'Inter-Medium';
    margin-bottom: 2.8rem !important;
    margin: auto;
  }

  .forgot-password {
    text-align: center;
    font-size: 1rem;
    line-height: 1.2rem;
    font-family: 'Inter-Medium';
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    cursor: pointer;
  }
}
.cancel-subscription {
  .form-box{
    .margin-15{
      margin-top: 15px;
    }
    .MuiFormLabel-root{
      justify-content: center;
    }
  }
  .left-arrow-new {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 3.8rem;
    img {
      margin-left: 4.166vw;
      color: #231f20;
      cursor: pointer;
    }
  }
  .main-heading {
    font-family: 'Inter-Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 1.733rem;
    line-height: 1.867rem;
    opacity: 1;
    margin-bottom: 0rem;
    margin-left: -5vw;
  }
  .input-lable-text {
    text-align: center;
    margin-bottom: 1rem;
    font-family: $asap-font-regular;
    font-weight: 600;
    color: #2f2b23;
  }
  .cancel-sub-dropdown{
    width: 100%;
  }
  .dropdown {
    margin-top: 1.5rem;
    margin-bottom: 2rem;
    .select-field {
      background: url(../../assets/images/sellerSide/dropdown.webp) 97%/16px no-repeat;
      background-position-y: center;
    }
  }
  .blackbtn {
    background-color: #d75d4c !important;
    width: 12.533rem !important;
    font-size: 0.867rem !important;
    text-transform: capitalize;
    height: 3rem !important;
  }
}
@media only screen and (max-width: 600px) {
  .loginpage .visibility-icon {
    position: absolute;
    right: 7%;
    top: 3vw;
  }
  .loginpage {
    .form-box {
      width: 90%;
    }
    .loginpage .inner-box {
      width: 90%;
    }
    .input-field-loginside {
      min-width: auto;
    }
    #password {
      padding-right: 15vw;
    }
  }
  .cancel-subscription {
    .login-full-container {
      padding-top: 1vw;
    }
    .main-heading {
      margin-left: 0;
    }
    .left-arrow-new {
      align-items: inherit;
      flex-direction: column;

      img {
        margin-left: 1.166vw;
        margin-bottom: 5vw;
        width: 31px;
        height: 31px;
      }
    }
  }
  // .loginpage .input-field-loginside {
  //   width: 90%;
  // }

  // .loginpage .input-error-message {
  //   width: 90%;
  // }
}

@media (min-width: 601px) and (max-width: 919px) {
  .loginpage {
    .form-box {
      width: 70%;
    }

    .input-field-loginside {
      min-width: auto;
    }
  }
}
@media only screen and (min-width: 1921px) {
  .loginpage {
    // padding-top: 9vw;

    .input-field-loginside {
      height: 2.5vw;
      border-radius: 0.551vw;
    }

    .login-para {
      font-size: 1vw;
      line-height: 1.4vw;
      margin-bottom: 4.7vw !important;
    }
    .input-lable-text {
      font-size: 1.06vw;
      margin-bottom: 0.267vw;
    }
    .blackbtn {
      margin-bottom: 0.933vw !important;
      margin-top: 1vw !important;
      font-size: 1.231vw !important;
      line-height: 1.733vw !important;
      border-radius: 2.141vw !important;
      height: 3.533vw !important;
      width: 8.2vw !important;
    }
    .forgot-password {
      font-size: 1.04vw;
      line-height: 1.25vw;
    }
    .form-box {
      padding-bottom: 1.23vw;
    }
  }
  .cancel-subscription {
    .left-arrow-new {
      padding-bottom: 3.8vw;
    }
    .cancel-sub-dropdown{
      width: 100% !important;
    }
    .main-heading {
      font-size: 1.933vw;
      line-height: 2.1vw;
    }
    .input-lable-text {
      margin-bottom: 1vw;
    }
    .blackbtn {
      width: 15.2vw !important;
    }
    .dropdown .select-field {
      // loginpage
      background-position-y: center;
      height: 3vw !important;
      font-size: 1vw;

    }
  }
}

@media (min-width: 800px) and (max-width: 1024px) {
  .cancel-subscription .cancel-sub-dropdown{
    width: 100% !important;
  }
}
