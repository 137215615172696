.bulkuploadscreen {
  padding-top: 25px;
  padding-bottom: 60px;

  .code-side-section {
    padding-right: 20px;
  }

  .bulk-upload-heading {
    font-family: $asap-font-regular;
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 30px;
    color: #2f2b23;
    // margin-bottom: 20px;
  }

  .lightbtn,
  .darkbtn {
    height: 38px !important;
    text-transform: inherit !important;
  }

  .bulk-para {
    font-family: $asap-font-regular;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 130%;
    color: #2f2b23;

    span {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .box-container {
    overflow: hidden;
    padding: 32px 24px 24px 24px;
    background-color: #ffffff;
  }

  .btn-section {
    display: flex;
  }

  .bulkbody-container {
    margin-top: 32px;
    background-color: #e8e5e3;
    padding: 24px 16px;
    border-radius: 8px;

    .dropdown {
      .MuiBox-root {
        .MuiFormControl-root {
          .select-field {
            background-size: 18px;
            background-position: center;
            background-position-x: 98%;
          }
        }
      }
    }
  }

  .product-heading {
    font-family: $asap-font-regular;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 21px;
    color: #2f2b23;
    margin-bottom: 8px;
  }

  .bulk-para {
    p {
      font-family: $asap-font-regular;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 130%;
      color: #2f2b23;
    }
  }

  .dropdown .select-field {
    font-family: $asap-font-regular !important;
    font-size: 14px !important;
    padding-left: 0px;
  }

  .form-heading {
    font-family: $asap-font-regular;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 130%;
    color: #2f2b23;
    margin-top: 24px;
    margin-bottom: 8px;
  }

  .search {
    font-family: $asap-font-regular;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 130%;
    color: #2f2b23;
    margin-bottom: 5px;
  }

  .dropdown-section {
    display: flex;

    .dropdown {
      width: 100%;

      // margin-right: 15px;
      .select-field {
        background: url(../../assets/images/sellerSide/dropdown.webp) 96%/5% no-repeat;
        text-align: center;
        background-size: 18px;
        background-position: center;
        background-position-x: 98%;
      }
    }
  }

  .dropdown .select-field {
    background-color: #ffffff !important;
    margin-right: 15px;
    background: url(../../assets/images/sellerSide/dropdown.webp) 97%/2.5% no-repeat;
  }

  .dropdown-section-color {
    .dropdown {
      width: 100%;

      .select-field {
        background: url(../../assets/images/sellerSide/dropdown.webp) 96%/5% no-repeat;
        background-size: 18px;
        background-position: center;
        background-position-x: 98%;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .bulkuploadscreen {

    .darkbtn,
    .lightbtn {
      font-size: 12px !important;
      line-height: 15px;
      padding: 8px 5px !important;
      display: block;
      width: 100%;
      height: 40px !important;
    }

    .dropdown {
      .select-field {
        background: url(../../assets/images/sellerSide/dropdown.webp) 96%/5% no-repeat;
      }
    }

    .dropdown-section {
      display: block;
      padding-right: 16px;

      .dropdown {
        .select-field {
          margin: 10px 0px;
        }
      }
    }

    .dropdown-section-color {
      .dropdown {
        width: 100%;
      }
    }
  }
}

@media (min-width: 2000px) and (max-width: 6000px) {
  .bulkuploadscreen {
    .bulk-upload-heading {
      font-size: 1.5vw;
      line-height: 1.7;
    }

    .bulk-para p {
      font-size: 0.9vw;
      line-height: 1.2vw;
    }

    .darkbtn,
    .lightbtn {
      height: 2.5vw !important;
      border-radius: 0.5vw !important;
      padding: 0.4vw 0.8vw !important;
      font-size: 0.8vw !important;
      margin-right: 1vw !important;
    }

    .bulkbody-container {
      padding: 1.25vw 0.833vw;
    }

    .box-container {
      padding: 1.8vw;
    }

    .product-heading {
      font-size: 1.1vw;
      line-height: 1.5vw;
    }

    .form-heading {
      font-size: 0.99vw;
      line-height: 1.2vw;
    }

    .search {
      font-size: 0.833vw;
      line-height: 1.04vw;
      margin-top: 0.5vw;
    }

    .dropdown {
      .select-field {
        font-size: 1vw !important;
        line-height: 1.5vw;
        height: 2.8vw !important;
        border-radius: 0.5vw !important;
      }
    }

    .codeinputbox .code-input {
      font-size: 1vw !important;
      line-height: 1.5vw;
      height: 2.8vw !important;
    }

    .codeinputbox .icon-row {
      height: 2.8vw;
      border-radius: 0px 0.5vw 0.5vw 0px;
      margin-top: 4px;
      width: 2.8vw;
    }
  }
}


@media screen and (min-width: 1930px) {
  .bulkuploadscreen {
    .dropdown-section-color {
      .dropdown {
        width: 100% !important;

        .select-field {
          background: url(../../assets/images/sellerSide/dropdown.webp) 96%/5% no-repeat;
          background-size: 18px;
          background-position: center;
          background-position-x: 98%;
        }
      }
    }
  }

  .bulkbody-container {
    .dropdown {
      width: 100% !important;

      .select-field {
        background: url(../../assets/images/sellerSide/dropdown.webp) 96%/5% no-repeat;
        background-size: 18px;
        background-position: center;
        background-position-x: 98%;
      }
    }
  }
}