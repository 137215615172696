.right-img-left-content {
  margin-top: 2rem;
  margin-bottom: 2.5rem;

  .welcominnercard {
    margin-bottom: 36px !important;
    padding: 5.278vw 3.889vw;
    min-height: 0;

    .MuiGrid-root {
      .MuiGrid-root {
        padding-top: 0 !important;

        .welcome-new-text {
          font-family: 'Inter-Medium';
          font-weight: 500;
        }

        .welcome-para {
          font-weight: 400 !important;
          font-family: 'Inter-Regular' !important;
        }
      }
    }
  }

  .main-section {
    margin-top: 6px;
    padding-left: 29px;

    .drag-container {
      .draggable-update-card {
        .upload-description-card {
          cursor: auto;
        }
      }
    }

    .section-editor {
      display: flex;
      margin-bottom: 3.9375rem;
      align-items: end;
      column-gap: 4.688rem;

      .return-btn {
        height: 3.25rem;
        min-width: 8.5rem;
        border-radius: 3.25rem !important;
        font-size: 1.125rem !important;
        font-family: 'Inter-Medium' !important;
        font-style: normal !important;
        font-weight: 500 !important;
      }
    }

    .dropdown {
      .MuiBox-root {
        .select-field {
          background-color: #fff;
          background-position-y: center;

          .MuiSelect-select {
            min-height: 0;
          }
        }
      }
    }
   .btn-section-new{
    .dropdown {
      width: 43.3vw !important;
    }
   }
    .search-engine-section {
      padding-bottom: 52px;

      .input-title {
        margin-top: 0;
      }
    }

    .heading-meta {
      margin-bottom: 1.6rem;
    }
  }

  .buttons {
    width: 100%;
    margin-top: 70px;

    .buttons-position {
      display: flex;
      justify-content: flex-end;
      margin-right: 130px;

      .lightbtn {
        margin-right: 30px !important;
      }
    }
  }
}

.right-img-left-content {
  .main-section {
    .search-engine-section {
      .input-title {
        .meta-info {
          .meta-des {
            .dropdown {
              width: 700px;

              .MuiBox-root {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: 1921px) and (max-width: 6000px) {
  .right-img-left-content {
    .main-section {

      .search-engine-section {
        h4 {
          font-size: 32px;
          padding-bottom: 2rem;
        }

        .input-title {
          .meta-des {
            .dropdown {
              width: 55vw;

              .MuiBox-root {
                width: 100%;
              }
            }
          }
        }
      }

      .heading-meta {
        font-size: 45px;
      }

      .drag-container {
        margin-bottom: 0;
      }
    }
  }
}

@media screen and (min-width: 3000px) and (max-width: 6000px) {
  .right-img-left-content {
    padding-left: 60px;

    .main-section {
      .section-editor {
        .return-btn {
          border-radius: 6rem !important;
          font-size: 1.8rem !important;
          height: 4rem;
          min-width: 14.5rem;
        }
      }
    }

    .buttons {
      .buttons-position {

        .lightbtn,
        .bluebtn {
          font-size: 1.016vw !important;
          margin-right: 0.781vw !important;
          padding: 15px 24px !important;
          border-radius: 18px !important;
        }
      }
    }
  }
}

@media screen and (min-width: 1930px) and (max-width: 3000px) {
  .right-img-left-content {
    .main-section {
      .section-editor {
        .return-btn {
          height: clamp(3.25rem, 3.5vw, 5rem);
          min-width: clamp(8.5rem, 11.5vw, 18rem);
          border-radius: 3.25rem !important;
          font-size: clamp(1.125rem, 1.2vw, 1.8rem) !important;
        }
      }
    }

    .buttons {
      .buttons-position {

        .lightbtn,
        .bluebtn {
          font-size: 1.016vw !important;
          margin-right: 0.781vw !important;
          padding: 15px 24px !important;
          border-radius: 18px !important;
        }
      }
    }
  }
}

@media screen and (min-width: 800px) and (max-width: 1024px) {
  .right-img-left-content {
    .main-section {
      .search-engine-section {
          .input-title {
            .meta-info {
              .meta-des {
                .dropdown {
                  width: 50vw;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .right-img-left-content {
    .main-section {
      .search-engine-section {
          .input-title {
            .meta-info {
              .meta-des {
                .dropdown {
                  width: 100%;
              }
            }
          }
        }
      }
    }
  }

  .right-img-left-content {
    margin: 0 1.5rem;
    margin-bottom: 8vw;

    .welcominnercard {
      margin-left: 0;
      margin-right: 0;
      padding: 5.6vw 4.8vw 5.6vw;
    }

    .main-section {
      padding-left: 0;

      .dropdown .MuiBox-root {
        width: 100%;

        .select-field {
          background-position-x: calc(100% - 20px);
        }
      }

      .add-product {
        .search-input {
          width: 100% !important;
        }
      }

      .section-editor {
        flex-direction: column;
        align-items: inherit;
        row-gap: 2rem;
        margin-left: 0;
      }
    }

    .buttons {
      width: 100%;
      margin-top: 40px;

      .buttons-position {
        margin-right: 0;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .right-img-left-content {
    margin: 0 1.5rem;
    margin-bottom: 8vw;

    .welcominnercard {
      margin-left: 0;
      margin-right: 0;
      padding: 9.347vw 4.889vw;
    }

    .main-section {
      padding-left: 0;

      .dropdown .MuiBox-root {
        width: 100%;

        .select-field {
          background-position-x: calc(100% - 20px);
        }
      }

      .add-product {
        .search-input {
          width: 100% !important;
        }
      }

      .section-editor {
        flex-direction: column;
        align-items: inherit;
        row-gap: 2rem;
        margin-left: 0;
      }
    }

    .buttons {
      width: 100%;
      margin-top: 40px;

      .buttons-position {
        margin-right: 0;
      }
    }
  }
}