.TermAndCondition {
  padding-left: 20px;
  padding-right: 10px;
  padding-top: 94px;
  padding-bottom: 50px;
  background: #fff;

  #root {
    background: #fff;
  }

  .term-condition {
    background-color: #fff;
    padding: 26px;
    color: #333;
  }

  .heading1 {
    font-size: 20px;
    color: #333;
    line-height: 30px;
    font-family: univia-pro, sans-serif !important;
  }

  .para-heading {
    color: #666;
    padding-left: 10px;
    font-family: univia-pro, sans-serif !important;
    margin-top: 30px;
  }
}

@media (min-width: 2000px) and (max-width: 6000px) {
  .TermAndCondition {
    padding-top: 8vw;
    padding-bottom: 4vw;
    .heading1 {
      font-size: 1vw;
      line-height: 1.2vw;
      margin-bottom: 1vw;
    }
    ol li {
      font-size: 1vw;
      line-height: 1.2vw;
    }
    ol {
      padding: 1vw;
    }
  }
}
